import React, { FunctionComponent } from 'react'

import './NoRunners.scss'

const NoRunners: FunctionComponent<{ message: string }> = ({ message }) => (
  <div className="no-runners--container">
    {message}
  </div>
)

export default NoRunners
