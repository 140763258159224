import React, { Fragment, FunctionComponent } from 'react'
import AllColumns from '@horses/AllColumns/AllColumns'
import InfoBar from '@horses/InfoBar/InfoBar'

import './RenderScreen.scss'

const RenderScreen: FunctionComponent = () => (
  <Fragment>
    <InfoBar />
    <div className="render-screen__container">
      <AllColumns />
    </div>
  </Fragment>
)

export default RenderScreen
