import merge from 'lodash/merge'
import reduce from 'lodash/reduce'
import toString from 'lodash/toString'
import { parse } from 'query-string'

interface IURLReturn {
  [key: string]: string
}

function getUrlParams<T>(defaultParams: T): IURLReturn {
  const parsedUrlParams = parse(window.location.search)
  const allParams = merge(defaultParams, parsedUrlParams)

  const sortedParams = reduce(allParams, (
    paramAcc: IURLReturn,
    value: string | string[] | undefined,
    key: string | string[] | undefined
  ) => {
    const stringKey = toString(key)
    const stringValue = toString(value)
    paramAcc[stringKey] = stringValue
    return paramAcc
  }, {})

  return sortedParams
}

export default getUrlParams
