import React, { FunctionComponent } from 'react'
import CustomText from '@components/HorseComponents/CustomText/CustomText'
import Socket from '@classes/Socket'

interface IOddsBoxProps {
  favourite: boolean
  favCount: number
  colour: string
  nonRunner: boolean | null
  price: string | null
}

const OddsBox: FunctionComponent<IOddsBoxProps> = ({
  favourite,
  favCount,
  colour,
  nonRunner,
  price,
}) => {
  const setPrice = (priceStr: string): string => {
    if (!Socket.networkConnectionActive || !Socket.socketConnectionActive) {
      return '-'
    }

    if (nonRunner) {
      return 'NR'
    }
    return priceStr === '1/1' ? 'Evs' : priceStr
  }
  const getFavCountLetter = () => {
    if (!favCount) {
      return ''
    } else if (favCount === 1) {
      return 'F'
    }
    return favCount > 2 ? `CF${favCount}` : `JF`
  }

  return (
    <div className="race-card__price-container">
      {favourite && (
        <div className="race-card__price-container-favourite">
          {getFavCountLetter()}
        </div>
      )}
      <div
        className="race-card__price-odd"
        style={{
          border: `1px solid ${colour}`,
        }}
      >
        <CustomText
          text={`${setPrice(price || '-')}`}
          colour="black"
          noSpace={true}
          size="smedium"
          bold={true}
          proximaCondBold={true}
        />
      </div>
    </div>
  )
}

export default OddsBox
