import accurateInterval from 'accurate-interval'

class TimeoutLoop {
  private loopIsActive: boolean = false
  private callback: () => void
  private interval: number;
  private accurate: any // tslint:disable-line: no-any
  constructor(callback: () => void, interval = 1000, autostart: boolean = true) {
    this.callback = callback
    this.interval = interval
    if (autostart) {
      this.init()
    }
  }

  public unmount() {
    if (this.accurate) {
      this.accurate.clear()
    }
  }

  public init() {
    if (!this.loopIsActive) {
      this.loopIsActive = true
      this.accurate = accurateInterval(this.callback, this.interval, { aligned: true, immediate: true })
    }
  }

}

export default TimeoutLoop
