import dayjs from 'dayjs'

export const getTime = (dateTime: string): string => {
  if (dateTime) {
    return dayjs(dateTime).format('h.mm')
  }
  return ''
}

export const getCountryCode = (countryCode: string) => {
  const isIRE = countryCode === 'IRE'
  const type = isIRE ? 'EUR' : 'GBP'
  return type
}

export const showWinnings = (prize: number, countryCode: string) => {
  const currency = getCountryCode(countryCode)
  return prize && prize.toLocaleString
    ? `Winner ${prize.toLocaleString('en-UK', {
        minimumFractionDigits: 0,
        currency,
        style: 'currency',
      })}`
    : ''
}
