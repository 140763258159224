import {
  API_URL,
  DEFAULT_BOOKMAKER_ID,
  DEFAULT_SCREEN_AMOUNT,
  HORSE_ODDS_TABLE_TARGET,
  KON_CODE,
  HEIGHT_4K,
  WIDTH_4K,
} from '@config'
import meetingInfoPriority from './meetingInfoPriority'
import raceComponentPriority from './raceComponentPriority'
import Column from '@classes/Column'
import OpenDevTools from '@classes/OpenDevTools'
import HorseBsd from '@horses/RenderScreen/RenderScreen'
import { Provider } from 'mobx-react'
import {
  detectScreenSize,
  fetchRaces,
  timeToDayjs,
  FIVE_SECONDS,
} from '@utils/utils'
import getUrlParams from '@utils/getUrlParams'
import InitialMeasure from '@horses/InitialMeasure/InitialMeasure'
import ScreenStore from '@stores/ScreenStore'
import React, { Component, ReactNode } from 'react'
import * as Sentry from '@sentry/browser'
import Loading from '@components/Loading/Loading'
import TimeoutLoop from '@classes/TimeoutLoop'
import DebugStore from '@stores/DebugStore'
import { IHorseMeeting } from '@models/HorseMeeting'
import DebugDevTools from '@horses/DebugDevTools/DebugDevTools'
import MeetingQueue from '@classes/MeetingQueue'
import LoadingStore from '@stores/LoadingStore'
import Jobs from '@classes/Jobs'
import { ThemeProvider } from 'styled-components'
import theme from '@styles/theme'
import WeatherStore from '@stores/WeatherStore'
import PopupStore from '@stores/PopupStore'
import PopupContainer from '@components/Popup/PopupContainer'
import dayjs, { Dayjs } from 'dayjs'
import findIndex from 'lodash/findIndex'
import AbandonedRaceStore, { IAbandonedRaces } from '@stores/AbandonedRaceStore'

interface IHorsesState {
  ready: boolean
  error: string
  gotData: boolean
  measuring: boolean
  ignoreOverrides: boolean
  triggerGetRaces: boolean
}

const TEN_MINS = 1000 * 60 * 10
// RELOAD IF GOT NO SCREENS AFTER 20MINS
const RETRY_INTERVAL = TEN_MINS * 2
let gotScreens = false
class Horses extends Component<{}, IHorsesState> {
  public jobs = new Jobs()
  private ofScreen: number
  private enableTipster: boolean = false
  private bookmakerId: string
  private showPriceHistory: boolean
  private showSpotlightsEnabled: boolean
  private showPrices: boolean
  private screenStore: ScreenStore
  private debugStore: DebugStore
  private popupStore: PopupStore
  private loadingStore: LoadingStore = new LoadingStore()
  private meetingOverride: string | null = null
  private meetingOverrideTime: string | null = null
  private priorityMeeting: string | null = null
  private meetingsQueue: MeetingQueue | null = null
  private devToolsListener: OpenDevTools
  private getRacesPoller?: TimeoutLoop
  private inlineSpotlights: boolean = false
  private diomedSpotlights: boolean = false
  private verdictAtBottom: boolean = false
  private showMostTipped: boolean = true
  private inlineTip: boolean = false
  private disableScreenSizeCheck: boolean = false
  private countries: string
  private secondCourseMap: boolean = true

  constructor(props: never) {
    super(props)

    const {
      ofScreen,
      debug,
      bookmakerId,
      meetingOverride,
      meetingOverrideTime,
      tipster,
      inlineSpotlights,
      diomedSpotlights,
      showSpotlights,
      showPriceHistory,
      showPrices,
      priorityMeeting,
      verdictAtBottom,
      showMostTipped,
      inlineTip,
      countries,
      disableScreenSizeCheck,
    } = getUrlParams({
      bookmakerId: DEFAULT_BOOKMAKER_ID,
      ofScreen: DEFAULT_SCREEN_AMOUNT,
      showPriceHistory: true,
      showPrices: true,
      debug: false,
      tipster: false,
      inlineSpotlights: false,
      diomedSpotlights: false,
      verdictAtBottom: false,
      showMostTipped: true,
      showSpotlights: true,
      inlineTip: false,
      countries: 'GB,IRE',
    })

    const debugEnabled = debug === 'true'
    const tipsterEnabled = tipster === 'true'
    const inlineSpotlightsEnabled = inlineSpotlights === 'true'
    const showSpotlightsEnabled = showSpotlights === 'true'
    const diomedSpotlightsEnabled = diomedSpotlights === 'true'
    const verdictAtBottomEnabled = verdictAtBottom === 'true'
    const ofScreenNumber = Number(ofScreen)
    const priceHistory = showPriceHistory === 'true'
    const prices = showPrices === 'true'
    const showMostTippedEnabled = showMostTipped === 'true'
    const inlineTipEnabled = inlineTip === 'true'
    const disableScreenSizeCheckEnabled = disableScreenSizeCheck === 'true'
    const secondCourseMapEnabled = true
    this.meetingOverride = meetingOverride
    this.meetingOverrideTime = meetingOverrideTime
    this.priorityMeeting = priorityMeeting
    this.ofScreen = ofScreenNumber
    this.enableTipster = tipsterEnabled
    this.inlineSpotlights = inlineSpotlightsEnabled
    this.diomedSpotlights = diomedSpotlightsEnabled
    this.verdictAtBottom = verdictAtBottomEnabled
    this.showPriceHistory = priceHistory
    this.showSpotlightsEnabled = showSpotlightsEnabled
    this.showPrices = prices
    this.showMostTipped = showMostTippedEnabled
    this.inlineTip = inlineTipEnabled
    this.countries = countries
    this.disableScreenSizeCheck = disableScreenSizeCheckEnabled
    this.secondCourseMap = secondCourseMapEnabled
    this.screenStore = new ScreenStore({
      bookmakerId,
    })
    this.bookmakerId = bookmakerId

    this.devToolsListener = new OpenDevTools(KON_CODE, () => {
      this.screenStore.setDevTools(true)
    })

    this.debugStore = new DebugStore(debugEnabled)

    this.popupStore = new PopupStore()

    this.state = {
      gotData: false,
      error: '',
      ready: false,
      measuring: false,
      ignoreOverrides: false,
      triggerGetRaces: false,
    }

    setTimeout(() => {
      if (!gotScreens) {
        window.location.reload()
      }
    }, RETRY_INTERVAL)
  }

  public componentDidUpdate() {
    if (this.state.triggerGetRaces) {
      this.getRaces()
      this.setState({ triggerGetRaces: false })
    }
  }

  public componentDidMount() {
    this.disableScreenSizeCheck
      ? this.getRaces()
      : detectScreenSize(
          { maxHeight: HEIGHT_4K, maxWidth: WIDTH_4K },
          this.getRaces,
          this.onWrongScreenSize,
        )

    this.devToolsListener.startListener()
    this.getRacesPoller = new TimeoutLoop(
      () => {
        this.getRaces(true)
      },
      TEN_MINS,
      false,
    )
  }
  // tslint:disable-next-line: no-any
  public componentDidCatch(error: Error, errorInfo: any) {
    // tslint:disable-next-line: no-any
    Sentry.withScope((scope: any) => {
      Object.keys(errorInfo).forEach(key => {
        scope.setExtra(key, errorInfo[key])
      })
      Sentry.captureException(error)
    })
  }

  public componentWillUnmount() {
    this.devToolsListener.removeListener()

    if (this.getRacesPoller) {
      this.getRacesPoller.unmount()
    }
  }

  public render() {
    return (
      <Provider
        screenStore={this.screenStore}
        debugStore={this.debugStore}
        popupStore={this.popupStore}
      >
        <ThemeProvider theme={theme}>
          <>
            <DebugDevTools />
            {this.initMeasureContainer()}
            {!this.state.ready ? (
              <>
                {!this.debugStore.enabled && (
                  <Loading
                    loadingStore={this.loadingStore}
                    hasGotData={this.state.gotData}
                    error={this.state.error}
                  />
                )}
              </>
            ) : (
              <>
                <PopupContainer />
                <HorseBsd />
              </>
            )}
          </>
        </ThemeProvider>
      </Provider>
    )
  }

  private onWrongScreenSize = (width: number, height: number) => {
    this.setState({
      gotData: false,
      error: `Screen is not correct resolution. Current is ${width}x${height} - needs to be ${WIDTH_4K}x${HEIGHT_4K}. Please change and refresh.`,
      ready: false,
    })
  }

  private getRaces = async (fromPoller: boolean = false) => {
    try {
      let meetings = await fetchRaces(
        this.countries ? `${API_URL}?countries=${this.countries}` : API_URL,
      )
      if (!meetings || !meetings.length) {
        this.setState({
          error: 'Waiting for race data',
        })
      }

      if (meetings && meetings.length) {
        if (this.priorityMeeting) {
          const priorityList = this.priorityMeeting.split(',')
          const isPriorityMeetingTodaysMeeting = Boolean(
            meetings.find(meeting =>
              priorityList.includes(meeting.diffusion_course_name || ''),
            ),
          )
          if (!isPriorityMeetingTodaysMeeting) {
            throw new Error(`Meeting priority not possible`)
          }
        }
        if (fromPoller) {
          this.updateWeather(meetings)
          this.updateAbandonedMeetings(meetings)
          return
        }
        if (
          !this.state.ignoreOverrides &&
          (this.meetingOverride || this.meetingOverrideTime)
        ) {
          let meetingOverridesByPriority = meetings

          interface IOverrideTimeCompArgs {
            firstRaceTime: Dayjs
            lastRaceTime: Dayjs
          }

          const overrideMeetingsByTime = (
            firstComparisonFunction: (times: IOverrideTimeCompArgs) => boolean,
            secondComparisonFunction:
              | ((times: IOverrideTimeCompArgs) => boolean)
              | undefined,
          ) =>
            meetingOverridesByPriority.filter(({ races }: IHorseMeeting) => {
              const times = {
                firstRaceTime: dayjs(races[0].race_datetime),
                lastRaceTime: dayjs(races[races.length - 1].race_datetime),
              }

              if (secondComparisonFunction) {
                return (
                  firstComparisonFunction(times) &&
                  secondComparisonFunction(times)
                )
              }

              return firstComparisonFunction(times)
            })

          const overrideMeetingsByIndex = (indexes: number[]) =>
            indexes.map((index: number) => meetingOverridesByPriority[index])

          if (this.meetingOverride && this.meetingOverride.length) {
            const overrides = this.meetingOverride
              .split(',')
              .map(override =>
                Number.isNaN(parseInt(override, 10))
                  ? findIndex(
                      meetingOverridesByPriority,
                      ({ diffusion_course_name }: IHorseMeeting) =>
                        diffusion_course_name === override.toUpperCase(),
                    )
                  : parseInt(override, 10) - 1,
              )

            meetingOverridesByPriority = overrideMeetingsByIndex(overrides)
          }

          if (this.meetingOverrideTime && this.meetingOverrideTime.length) {
            // generate an array of comparison functions
            const overrides = this.meetingOverrideTime
              .split(',')
              // only use the first two values
              .splice(0, 2)
              .map((val, index) => {
                // map time strings to real time
                switch (val) {
                  case 'afternoon':
                    // up until 3 - not including 3
                    return (raceTimes: IOverrideTimeCompArgs) =>
                      raceTimes.firstRaceTime.isBefore(timeToDayjs('15:00'))
                  case 'twilight':
                    // starts from 3 (including 3) to 5 (not including 5)
                    return (raceTimes: IOverrideTimeCompArgs) =>
                      raceTimes.firstRaceTime.isAfter(
                        timeToDayjs('15:00').subtract(1, 'second'),
                      ) &&
                      raceTimes.firstRaceTime.isBefore(timeToDayjs('17:00'))
                  case 'evening':
                    // starts from 5 including 5
                    return (raceTimes: IOverrideTimeCompArgs) =>
                      raceTimes.firstRaceTime.isAfter(
                        timeToDayjs('17:00').subtract(1, 'second'),
                      )
                  default:
                    return index === 0
                      ? (raceTimes: IOverrideTimeCompArgs) =>
                          raceTimes.firstRaceTime.isAfter(
                            timeToDayjs(val).subtract(1, 'second'),
                          )
                      : (raceTimes: IOverrideTimeCompArgs) =>
                          raceTimes.lastRaceTime.isBefore(
                            timeToDayjs(val).add(1, 'second'),
                          )
                }
              })

            meetingOverridesByPriority = overrideMeetingsByTime(
              overrides[0],
              overrides[1],
            )
          }

          if (meetingOverridesByPriority.length) {
            meetings = meetingOverridesByPriority
          } else {
            setTimeout(
              () =>
                this.setState({
                  ignoreOverrides: true,
                  triggerGetRaces: true,
                }),
              FIVE_SECONDS,
            )
            throw new Error(
              'Meeting override not possible, reverting to show all meetings',
            )
          }
        }

        this.debugStore.log('Finished get races call to api')

        if (this.meetingsQueue) {
          this.meetingsQueue.setMeetings(meetings)
          this.setState({
            gotData: Boolean(meetings && meetings.length),
            error: meetings && meetings.length ? '' : 'No Meetings Found',
            measuring: true,
          })
        } else {
          this.meetingsQueue = new MeetingQueue({
            meetings,
            meetingOverride: this.meetingOverride,
            priorityMeeting: this.priorityMeeting,
            target: HORSE_ODDS_TABLE_TARGET,
            bookmakerId: this.bookmakerId,
            inlineSpotlights: this.inlineSpotlights,
            diomedSpotlights: this.diomedSpotlights,
            verdictAtBottom: this.verdictAtBottom,
            showPriceHistory: this.showPriceHistory,
            showSpotlights: this.showSpotlightsEnabled,
            showPrices: this.showPrices,
            jobs: this.jobs,
            showMostTipped: this.showMostTipped,
            inlineTip: this.inlineTip,
            secondCourseMap: this.secondCourseMap,
          })

          this.setState({
            gotData: Boolean(meetings && meetings.length),
            error: meetings && meetings.length ? '' : 'No Meetings Found',
            measuring: true,
            ready: false,
          })
        }

        this.updateWeather(meetings)
        this.updateAbandonedMeetings(meetings)
      }
    } catch (error) {
      this.setState({
        error: error.toString(),
      })
    }

    if (this.getRacesPoller) {
      this.getRacesPoller.init()
    }
  }

  private updateWeather = (meetings: IHorseMeeting[]) => {
    meetings.forEach(
      meeting =>
        meeting.id &&
        WeatherStore.setWeatherForMeeting(meeting.id, meeting.weather),
    )
  }

  private updateAbandonedMeetings = (meetings: IHorseMeeting[]) => {
    const allRaces = meetings.flatMap(meeting => meeting.races)

    const abandonedRaceIds = allRaces.reduce(
      (abandonedRaces: IAbandonedRaces, race) => {
        if (race.race_status_code === 'A') {
          abandonedRaces[race.race_instance_uid] = true
        }
        return abandonedRaces
      },
      {},
    )

    AbandonedRaceStore.addAbandonedRaces(abandonedRaceIds)
  }

  private gotMeasurements = (screens: Column[][]) => {
    if (this.state.measuring) {
      gotScreens = true
      this.screenStore.init(screens)
      this.debugStore.log('Got screens, setting ready.')
      this.setState({
        ready: true,
        measuring: false,
      })
      this.jobs.start()
      this.debugStore.log('Rendering screens 😎')
    }
  }

  private initMeasureContainer = (): ReactNode =>
    this.state.gotData &&
    this.state.measuring && (
      <InitialMeasure
        meetingsQueue={this.meetingsQueue}
        onMeasure={this.gotMeasurements}
        raceComponentPriority={raceComponentPriority}
        meetingInfoPriority={meetingInfoPriority}
        ofScreen={this.ofScreen}
        loadingStore={this.loadingStore}
        enableTipster={this.enableTipster}
      />
    )
}

export default Horses
