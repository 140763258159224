import React, { FunctionComponent, Fragment } from 'react'
import cx from 'classnames'
import { IMiddleColumn } from '../RaceCardInterfaces/IHorseInfo'
import CustomText from '@horses/CustomText/CustomText'
import { truncate } from '@utils/utils'
import RunnerStats from './RunnerStats'

const MiddleColumn: FunctionComponent<IMiddleColumn> = ({
  horseName,
  countryCode,
  horseColour,
  horseSex,
  horseSire,
  horseDam,
  jockeyName,
  jockeyFirstTime,
  weightAllowance,
  trainerName,
  newTrainerRacesCount,
  ownerName,
  geldingFirstTime,
  nonRunner,
  raceCountryCode,
  extraWeight,
  handicapFirstTime,
  runner,
}) => {
  const trainerSection = `T: ${trainerName}`

  return (
    <div
      className={cx('race-card--flex-col race-card__name-container', {
        ['race-card__non-runner-container']: nonRunner,
      })}
    >
      <div className="race-card__name">
        <CustomText
          subText={countryCode !== raceCountryCode ? countryCode : ''}
          text={horseName || ''}
          bold={true}
          proximaCondBold={true}
          uppercase={true}
          size="xmedium"
          letterSpacing={true}
        />
        <RunnerStats
          topRow={{
            jockeyWeight: runner.getFormattedJockeyWeight(),
            headGear: runner.getHeadGearCode(),
            headGearFirstTime: runner.getHeadGearFirstTime(),
            horseAge: runner.getHorseAge(),
            plus10: runner.getPlus10Horse(),
            distanceWins: runner.getDistanceWins(),
            courseWins: runner.getCourseWins(),
            courseDistanceWins: runner.getCourseAndDistanceWins(),
            slowGroundJumpsWins: runner.getSlowGroundJumpWins(),
            slowGroundFlatWins: runner.getSlowGroundFlatWins(),
            daysLastRun: runner.getDaysSinceLastRun(),
            daysLastRunFlat: runner.getDaysSinceLastRunFlat(),
            fastGroundWins: runner.getFastGroundWins(),
            daysLastRunJumps: runner.getDaysSinceLastRunJumps(),
            daysLastRunPTP: runner.getDaysSinceLastRunPTP(),
            beatenFav: runner.getBeatenFavourite(),
          }}
        />
      </div>
      {
        <Fragment>
          <div className="race-card--flex">
            <CustomText
              text={`${horseColour} ${horseSex}${geldingFirstTime ? '1' : ''}`}
              proximaCondSemiBoldIt={true}
              lowercase={true}
              italic={true}
              size="small"
            />
            <CustomText
              text={`${truncate(horseSire, 18)}-${truncate(horseDam, 18)}`}
              proximaCondSemiBoldIt={true}
              italic={true}
              size="small"
            />
            <div className="race-card__weight-handicap">
              {extraWeight && (
                <CustomText
                  text={`${extraWeight}ex`}
                  proximaCondSemiBold={true}
                  brackets={true}
                  size="smallest"
                  bold={true}
                />
              )}
              {handicapFirstTime && (
                <CustomText
                  text={'Hc1'}
                  proximaCondSemiBold={true}
                  size="smallest"
                  bold={true}
                />
              )}
            </div>
          </div>
          <div className="race-card--flex">
            <CustomText
              label="J: "
              preText={jockeyFirstTime && jockeyName ? 1 : ''}
              text={nonRunner ? 'NON RUNNER' : truncate(jockeyName, 25) || '-'}
              size="smaller"
              bold={true}
              proximaCondBold={true}
            />
            <CustomText
              text={weightAllowance && jockeyName ? `(${weightAllowance})` : ''}
              size="smaller"
              bold={true}
              proximaCondBold={true}
            />
          </div>
          <div className="race-card--flex race-card__owner-trainer">
            <div className="race-card--flex">
              <CustomText
                subScript={newTrainerRacesCount || ''}
                text={`T: ${trainerName}`}
                proximaCondBold={true}
                size="smaller"
                textEllipses={true}
              />
            </div>
            <div className="race-card--flex race-card__owner">
              <CustomText
                subScript={newTrainerRacesCount || ''}
                text={truncate(` O: ${ownerName}`, 28 - trainerSection.length)}
                proximaCondSemiBold={true}
                size="small"
                textEllipses={true}
              />
            </div>
          </div>
        </Fragment>
      }
    </div>
  )
}

export default MiddleColumn
