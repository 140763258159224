import uuid from 'uuid/v4'
import map from 'lodash/map'
import values from 'lodash/values'
import React, { FunctionComponent } from 'react'
import Meeting from './Meeting'
import Race from './Race'
import tipsterCreator from '@utils/tipsterCreator'

export interface IColumn {
  columnWidth: number
  freeSpace: number
  columnHeight: number
  course: string
  meeting: Meeting | null
  noHeader: boolean
}

export interface IElements {
  race?: Race
  elements: IElement[]
  columnIndex?: number
}

export interface IElementRace {
  [key: string]: IElements
}

export interface IElement {
  element: FunctionComponent | JSX.Element
  time?: string
  height: number
  raceId?: number
  race?: Race
  // tslint:disable-next-line
  props: any
  course?: string
  componentName?: string
}

class Column {
  public freeSpace: number
  public columnHeight: number
  public columnWidth: number
  public locked: boolean = false
  public course: string
  public uuid: string
  public noHeader: boolean
  public meeting: Meeting | null
  private elements: IElementRace
  private ads: IElement[]
  private tipster: JSX.Element | null = null
  private selectionBoxes: IElement[]
  private howToGuides: IElement[]
  constructor(column: IColumn) {
    this.elements = {}
    this.ads = []
    this.selectionBoxes = []
    this.tipster = null
    this.columnWidth = column.columnWidth
    this.freeSpace = column.freeSpace
    this.columnHeight = column.columnHeight
    this.course = column.course
    this.noHeader = column.noHeader
    this.meeting = column.meeting
    this.uuid = uuid()
    this.howToGuides = []
  }

  public isFreeColumn = (): boolean => !this.course

  public renderElements = () =>
    map(this.elements, elements => ({
      elements: elements.elements,
      race: elements.race,
      columnIndex: elements.columnIndex,
    }))

  public renderHowToGuides = () => {
    return this.howToGuides.map(item => ({ ...item.element, key: uuid() }))
  }

  public renderAds = () => {
    return this.ads.map(item => ({ ...item.element, key: uuid() }))
  }

  public renderTipster = () => {
    return this.tipster
  }

  public renderSelectionBoxes = () => {
    return this.selectionBoxes.map(item => ({
      ...item.element,
      key: uuid(),
    }))
  }

  public lock = () => {
    this.locked = true
  }

  public canAddHowToGuideOrAdvertOrSelectionBox = (height: number) =>
    height && height <= this.freeSpace

  public canAddElement = (height: number, courseName: string) =>
    this.canElementFit(height) && this.isElementSameCourse(courseName)

  public addHowToGuide = (options: IElement) => {
    const EMPTY_COLUMN = 1915
    if (this.freeSpace < EMPTY_COLUMN) {
      const { height } = options
      this.freeSpace -= height
      this.columnHeight += height
      this.howToGuides.push(options)
    }
  }

  public addAdvert = (options: IElement) => {
    const { height } = options
    this.freeSpace -= height
    this.columnHeight += height
    this.ads.push(options)
  }

  public addTipster = () => {
    this.tipster = tipsterCreator()
  }

  public subscribeAllRacesToChanges = () => {
    const allRaces = Object.values(this.elements)
    if (allRaces && allRaces.length) {
      allRaces.forEach(({ race }) => {
        if (race) {
          race.subscribeRaceToChanges()
        }
      })
    }
  }

  public addSelectionBox = (options: IElement) => {
    const { height, course } = options
    this.freeSpace -= height
    this.columnHeight += height

    if (course) {
      this.course = course
    }
    this.selectionBoxes.push(options)
  }

  // tslint:disable-next-line
  public createComponent = (component: any, props: any) =>
    React.createElement(component, { ...props, realRender: true })

  public addElement = (
    options: IElement,
    componentName: string,
    columnIndex: number,
  ) => {
    const { element, height, course, time, raceId, race, props } = options

    if (course) {
      this.course = course
    }
    this.freeSpace -= height
    this.columnHeight += height
    const id = raceId || uuid()

    if (!this.elements[id]) {
      this.elements[id] = {
        race,
        elements: [
          {
            element: this.createComponent(element, { ...props, columnIndex }),
            time,
            height,
            course,
            raceId,
            race,
            props,
          },
        ],
      }
    } else {
      this.elements[id].elements.push({
        element: this.createComponent(element, { ...props, columnIndex }),
        time,
        props,
        height,
        course,
        raceId,
        race,
        componentName,
      })
    }
  }

  public hasSelectionBoxes = () => this.selectionBoxes.length > 0

  public hasHowToGuides = () => this.howToGuides.length > 0

  public hasAds = () => this.ads.length > 0

  public hasTipster = () => this.tipster !== null

  public hasElements = () => values(this.elements).length > 0

  public isEmpty = () => !this.hasAds() && this.course === 'none'

  private canElementFit = (height: number) => {
    if (height === 0) {
      return
    }

    if (height > this.freeSpace) {
      return false
    }
    return true
  }

  private isElementSameCourse = (courseName: string) => {
    if (this.course && courseName !== this.course) {
      return false
    }
    return true
  }
}

export default Column
