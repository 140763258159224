import isEqual from "lodash/isEqual";

interface IKeyboardInput {
  code: string;
}

class OpenDevTools {
  private userCode: string[] = [];
  private codeToMatch: string[] = [];
  private onCompleteCallback: () => void;

  constructor(codeToMatch: string[], onCompleteCallback: () => void) {
    this.codeToMatch = codeToMatch;
    this.onCompleteCallback = onCompleteCallback;
  }

  public startListener = () => {
    document.addEventListener("keydown", this.onKeyInput);
  }

  public removeListener = () => {
    document.removeEventListener("keydown", this.onKeyInput);
  }

  private onKeyInput = (event: IKeyboardInput) => {
    if (this.codeToMatch.indexOf(event.code) > -1) {
      this.userCode.push(event.code);
      if (isEqual(this.codeToMatch, this.userCode)) {
        this.onCompleteCallback();
      }
    } else {
      this.userCode = [];
    }
  }
}

export default OpenDevTools;
