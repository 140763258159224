import React from 'react'
import { Container } from './RacingTypeHeader.css'
import HorseracingLogo from '@assets/RP_HorseracingTitle.png'

const RacingTypeHeader = () => (
  <Container>
    <img src={HorseracingLogo} />
  </Container>
)

export default RacingTypeHeader
