import React, { FunctionComponent } from 'react'
import verdictImage from '@utils/verdictImage'
import './verdictStyles.scss'
import { IVerdictProps } from './IVerdictWithRPR'
import GreyoutRP from '@utils/GreyoutRP'
import { observer } from 'mobx-react'
import AbandonedRaceStore from '@stores/AbandonedRaceStore'

const Verdict: FunctionComponent<IVerdictProps> = ({
  race,
  meetingAbandoned,
  innerRef,
  verdictAtBottom,
  lastSetSelectionBoxesRemoved,
  isLastSet,
  showInlineTip,
  showSpotlights,
  showInlineSpotlights,
  showMostTipped,
}) => {
  const raceHasTip = Boolean(race.getTipping())
  return race && !meetingAbandoned && race.getVerdict() ? (
    <GreyoutRP
      greyoutCondition={
        race.hasRaceFinished() ||
        race.raceHasResults() ||
        AbandonedRaceStore.isRaceAbandoned(race.getRaceInstanceUid())
      }
    >
      <div
        className="verdict__body"
        style={{
          borderBottom:
            (verdictAtBottom && showMostTipped) ||
            (!verdictAtBottom && (!raceHasTip || (raceHasTip && showInlineTip)))
              ? `1px solid black`
              : '',
          borderTop:
            !showInlineSpotlights && verdictAtBottom ? '1px solid black' : '',
        }}
        ref={ref =>
          innerRef &&
          innerRef(
            ref,
            {
              race,
              meetingAbandoned,
              lastSetSelectionBoxesRemoved,
              isLastSet,
              innerRef,
              verdictAtBottom,
              showInlineSpotlights,
              showInlineTip,
              showSpotlights,
              showMostTipped,
            },
            observer(Verdict),
          )
        }
      >
        <img src={verdictImage} className="verdict__body--img" />
        <span
          className="verdict--horse-highlight"
          dangerouslySetInnerHTML={{
            __html: race
              .getVerdict()
              .replace(/\\b/g, '<b>')
              .replace(/\\p/g, '</b>'),
          }}
        />
      </div>
    </GreyoutRP>
  ) : null
}
export default Verdict
