import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import WebfontLoader from '@dr-kobros/react-webfont-loader'
import Horses from '@horses/Horses/Horses'
import * as Sentry from '@sentry/browser'
import ReactGA from 'react-ga'

import { SENTRY_DSN } from '@config'

Sentry.init({
  dsn: SENTRY_DSN,
  environment: process.env.NODE_ENV,
})

import './styles/reset.css'
import './styles/fontFace.css'
import './styles/index.scss'
import Loading from '@components/Loading/Loading'
import LoadingStore from '@stores/LoadingStore'

if (process.env.NODE_ENV !== 'development') {
  ReactGA.initialize('UA-77659740-2')
}

const NotFound = () => (
  <Loading
    error="404! 😭 - Please check the URL."
    loadingStore={new LoadingStore()}
  />
)

const Routes = () => (
  <Router>
    <Switch>
      <Route exact path="/" component={Horses} />
      <Route exact path="/bsd" component={Horses} />
      <Route component={NotFound} />
    </Switch>
  </Router>
)

const config = {
  custom: {
    families: [
      'Proxima Nova',
      'Proxima Nova Bold',
      'Proxima Nova Italic',
      'Proxima Nova Cond',
      'Proxima Nova Cond Black',
      'Proxima Nova Cond Extra Bold',
      'Swis721BT',
      'Swis721BT Black',
      'Muybridge',
    ],
    urls: ['./styles/fontFace.css'],
  },
}

// Wait for fonts to load before rendering app, as initial measure will try to measure incorrect sizes
ReactDOM.render(
  <WebfontLoader config={config}>
    <Routes />
  </WebfontLoader>,
  document.getElementById('root'),
)
