import React, { FunctionComponent } from 'react'
import CustomText from '@horses/CustomText/CustomText'

interface IIndividualTip {
  tipping: ITip
}
export interface ITip {
  race_datetime: string
  race_instance_uid: number
  tipster_name: string
  tipster_uid: number
  tipster_type: string
  tip_type: string
  horse_name: string
  horse_uid: number
  course_name: string
  verdict: string
}

const InlineTip: FunctionComponent<IIndividualTip> = ({ tipping }) => {
  const verdict = tipping && tipping.verdict.replace(/\\n/g, '')
  const tipsterName = tipping && tipping.tipster_name.toUpperCase()

  return (
    <div>
      <div className="tipping__title">
        <p className="tipping__title-text">{`${tipsterName} TIP`}</p>
      </div>
      <div className="tip__info">
        <CustomText fontTertiary={true} text={verdict} size="normal" />
      </div>
    </div>
  )
}

export default InlineTip
