import React, { FunctionComponent } from 'react'
import cx from 'classnames'
import { THREE_QUARTERS_OF_SCREEN } from '@config'
import './Header.scss'
import { ISubHeaderProps } from './HeaderProps'

const ContinuedHeader: FunctionComponent<ISubHeaderProps> = ({
  meeting,
  viewportWidth,
  columnIndex,
}) => {
  const colour = meeting ? meeting.getMeetingColour() : 'green'
  const courseName = meeting ? meeting.getCourseName() : ''
  return (
    <div
      className={cx('header__container', 'header--continued', {
        'header--border': columnIndex > 0,
      })}
      style={{
        backgroundColor: colour,
        width: `${viewportWidth}vw`,
      }}
    >
      <span className="header-name--continued">{courseName}</span>
      {viewportWidth <= THREE_QUARTERS_OF_SCREEN && (
        <span className="header--continued">continued</span>
      )}
    </div>
  )
}

export default ContinuedHeader
