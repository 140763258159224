import React, { Fragment, FunctionComponent } from 'react'
import CustomText from '@horses/CustomText/CustomText'
import Container from '@horses/Container/Container'
import { ITopSpeed } from './ITopSpeed'
import get from 'lodash/get'
import dayjs from 'dayjs'
import './TopSpeed.scss'

const TopSpeed: FunctionComponent<ITopSpeed> = ({
  courseName,
  innerRef,
  colour,
  data,
}) => {
  if (data && data.length) {
    return (
      <Container
        colour={colour}
        title={`${courseName} TOPSPEED`}
        innerRef={ref =>
          innerRef(ref, { data, innerRef, courseName, colour }, TopSpeed)
        }
      >
        <div className="helpers--flex">
          <div className="top-speed__parent">
            {data.map(({ race_details, runners }, index) => {
              return (
                <div key={`topSpeed${index}`} className="top-speed__section">
                  <Fragment>
                    <div className="top-speed__title">
                      <div className="helpers--flex helpers--flex-col helpers--display-table">
                        <CustomText
                          text="Horse & rating"
                          fontTertiary={true}
                          size="extraSmall"
                        />
                        <CustomText
                          fontTertiary={true}
                          text="from last run"
                          size="extraSmall"
                        />
                      </div>
                      <CustomText
                        text={dayjs(race_details.race_datetime).format('HH:mm')}
                        proximaCondBold={true}
                        size="normal"
                      />
                      <div className="helpers--flex helpers--flex-col helpers--align-right helpers--display-table">
                        <CustomText
                          text="Adjusted rating -"
                          fontTertiary={true}
                          size="extraSmall"
                        />
                        <CustomText
                          text="date, course, dist, going"
                          fontTertiary={true}
                          size="extraSmall"
                        />
                      </div>
                    </div>
                    <div>
                      {Object.values(runners).map(item => {
                        const date = get(
                          item,
                          'best_topspeed.last_year.race_datetime',
                        )
                        const rating = get(
                          item,
                          'best_topspeed.last_year.adjusted_rp_topspeed',
                          '',
                        )
                        const name = get(
                          item,
                          'best_topspeed.last_year.course_style_name',
                          '',
                        )

                        return name ? (
                          <div
                            key={item.horse_style_name}
                            className="helpers--flex helpers--flex-between"
                          >
                            <CustomText
                              text={`${
                                item.horse_style_name
                              } (${item.rp_topspeed || '-'})`}
                              size="smallest"
                              fontTertiary={true}
                              dottedBorder={true}
                              noSpace={true}
                              flexOne={true}
                              flex={true}
                              bold={true}
                            />
                            <CustomText
                              text={`${
                                date ? dayjs(date).format('MMM-DD') : ''
                              } ${name.slice(0, 4)} ${get(
                                item,
                                'best_topspeed.last_year.distance_furlong_rounded',
                                '',
                              )} ${get(
                                item,
                                'best_topspeed.last_year.services_desc',
                                '',
                              )}`}
                              fontTertiary={true}
                              size="smallest"
                              noSpace={true}
                            />
                            <div className="top-speed__rating">
                              {rating && (
                                <CustomText
                                  proximaCondBold={true}
                                  size="smallest"
                                  noSpace={true}
                                  text={rating}
                                  bold={true}
                                />
                              )}
                            </div>
                          </div>
                        ) : null
                      })}
                    </div>
                  </Fragment>
                </div>
              )
            })}
          </div>
        </div>
      </Container>
    )
  }

  innerRef(null, null, null)
  return null
}

export default TopSpeed
