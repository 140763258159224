import CustomText from '@horses/CustomText/CustomText'
import { ITableHeadProps } from '../ITopTrainers'
import React, { FunctionComponent } from 'react'
import capitalize from 'lodash/capitalize'

const Head: FunctionComponent<ITableHeadProps> = ({
  dataForYearsSince,
  courseType,
}) => {
  const setCorrectYear = (year: number): string =>
    year > 0 ? `${year}+` : 'Previous Years'

  return (
    <thead>
      <tr className="top-trainers__header top-trainers__row top-trainers--padding-bottom ">
        <th className="top-trainers__trainer-name top-trainers--align-left">
          <CustomText
            fontTertiary={true}
            text={`${capitalize(courseType || 'Stats for ')} ${setCorrectYear(
              dataForYearsSince,
            )}`}
            size="smallest"
            noSpace={true}
            bold={true}
          />
        </th>
        <th className="top-trainers--centre">
          <CustomText
            fontTertiary={true}
            text="Wins-Rides"
            size="smallest"
            noSpace={true}
            bold={true}
          />
        </th>
        <th className="top-trainers--flex top-trainers--flex-col top-trainers__stake-header top-trainers--flex-quarter">
          <CustomText
            fontTertiary={true}
            text="£1 STAKE"
            size="smallest"
            noSpace={true}
            bold={true}
          />
        </th>
        <th className="top-trainers--centre">
          <CustomText
            fontTertiary={true}
            size="smallest"
            noSpace={true}
            bold={true}
            text="2YO"
          />
          <CustomText
            fontTertiary={true}
            text="Wins-Rides"
            size="smallest"
            noSpace={true}
            bold={true}
          />
        </th>
        <th className="top-trainers--centre">
          <CustomText
            fontTertiary={true}
            size="smallest"
            noSpace={true}
            text="3YO+"
            bold={true}
          />
          <CustomText
            fontTertiary={true}
            text="Wins-Rides"
            size="smallest"
            noSpace={true}
            bold={true}
          />
        </th>
        <th className="top-trainers--flex top-trainers--flex-col">
          <CustomText
            fontTertiary={true}
            text="Course rides"
            size="smallest"
            noSpace={true}
            bold={true}
          />
          <CustomText
            fontTertiary={true}
            text="since a win"
            size="smallest"
            noSpace={true}
            bold={true}
          />
        </th>
      </tr>
    </thead>
  )
}

export default Head
