import React, { FunctionComponent } from 'react'
import Spotlight from '@components/HorseComponents/Spotlight/Spotlight'
import { ISpotlightsData } from '@components/HorseComponents/Spotlight/ISpotlight'

interface IRunnerSpotlight {
  item: ISpotlightsData
  raceId: string
  showDiomedSpotlights: boolean
  tippedHorse: boolean | null
}

const RunnerSpotlight: FunctionComponent<IRunnerSpotlight> = ({
  item,
  raceId,
  showDiomedSpotlights,
  tippedHorse,
}) => {
  return (
    <Spotlight
      raceId={raceId}
      item={item}
      showDiomedSpotlights={showDiomedSpotlights}
      inline={true}
      tippedHorse={tippedHorse}
    />
  )
}

export default RunnerSpotlight
