import { observable, action } from 'mobx'

export interface IAbandonedRaces {
  [raceId: number]: true
}

class AbandonedRaceStore {
  @observable private races: IAbandonedRaces = {}

  @action
  public addAbandonedRaces = (races: IAbandonedRaces) => {
    this.races = { ...this.races, ...races }
  }

  @observable
  public isRaceAbandoned = (raceId: number) => Boolean(this.races[raceId])
}

export default new AbandonedRaceStore()
