import TopDraw from '@horses/TopDraw/TopDraw'
import TopSpeed from '@horses/TopSpeed/TopSpeed'
import CourseInfo from '@horses/CourseInfo/CourseInfo'
import TopTrainers from '@horses/TopTrainers/TopTrainers'
import TopJockeys from '@horses/TopJockeys/TopJockeys'
import Favourites from '@horses/Favourites/Favourites'
import TenYear from '@horses/TenYear/TenYear'
import TravellersCheck from '@horses/TravellersCheck/TravellersCheck'

const meetingInfoPriority = () => [
  {
    component: Favourites,
    props: (getRaceElementHeight, { meeting, meetingColour, courseName }) => {
      return {
        colour: meetingColour,
        innerRef: (node, props, component) =>
          getRaceElementHeight({
            node,
            props,
            component,
            meeting,
            name: 'Favourites',
          }),
        data: meeting.getFavourites(),
        courseName,
      }
    },
  },
  {
    component: TopTrainers,
    props: (getRaceElementHeight, { meeting, meetingColour, courseName }) => {
      return {
        colour: meetingColour,
        courseName,
        topTrainers: meeting.getTopTrainers(),
        dataForYearsSince: meeting.getDataForYearsSince(),
        meetingType: meeting.getMeetingType(),
        innerRef: (node, props, component) =>
          getRaceElementHeight({
            node,
            props,
            component,
            meeting,
            name: 'TopTrainers',
          }),
      }
    },
  },
  {
    component: TopJockeys,
    props: (getRaceElementHeight, { meeting, meetingColour, courseName }) => {
      return {
        colour: meetingColour,
        courseName,
        topJockeys: meeting.getTopJockeys(),
        dataForYearsSince: meeting.getDataForYearsSince(),
        meetingType: meeting.getMeetingType(),
        innerRef: (node, props, component) =>
          getRaceElementHeight({
            node,
            props,
            component,
            meeting,
            name: 'TopJockeys',
          }),
      }
    },
  },
  {
    component: TopDraw,
    props: (getRaceElementHeight, { meeting, meetingColour, courseName }) => {
      return {
        colour: meetingColour,
        courseName,
        data: meeting.getTopDraw(),
        innerRef: (node, props, component) =>
          getRaceElementHeight({
            node,
            props,
            component,
            meeting,
            name: 'TopDraw',
          }),
      }
    },
  },
  {
    component: TravellersCheck,
    props: (getRaceElementHeight, { meeting, meetingColour, courseName }) => {
      return {
        colour: meetingColour,
        courseName,
        data: meeting.getTravellersCheck(),
        innerRef: (node, props, component) =>
          getRaceElementHeight({
            node,
            props,
            component,
            meeting,
            name: 'TravellersCheck',
          }),
      }
    },
  },
  {
    component: TopSpeed,
    props: (getRaceElementHeight, { meeting, meetingColour, courseName }) => {
      return {
        colour: meetingColour,
        courseName,
        data: meeting.getTopSpeed(),
        innerRef: (node, props, component) =>
          getRaceElementHeight({
            node,
            props,
            component,
            meeting,
            name: 'TopSpeed',
          }),
      }
    },
  },
  {
    component: TenYear,
    props: (getRaceElementHeight, { meeting, meetingColour, courseName }) => {
      return {
        colour: meetingColour,
        courseName,
        data: meeting.getTenYear(),
        innerRef: (node, props, component) =>
          getRaceElementHeight({
            node,
            props,
            component,
            meeting,
            name: 'TenYear',
          }),
      }
    },
  },
  {
    component: CourseInfo,
    props: (getRaceElementHeight, { meeting, meetingColour, courseName }) => {
      return {
        colour: meetingColour,
        innerRef: (node, props, component) =>
          getRaceElementHeight({
            node,
            props,
            component,
            meeting,
            name: 'CourseInfo',
          }),
        courseName,
        info: meeting.getMeetingInfo(),
        courseMaps: meeting.getCourseMap(),
        records: meeting.getRaceDistanceRecords(),
        secondMapRemoved: meeting.secondMapRemoved,
      }
    },
  },
]

export default meetingInfoPriority
