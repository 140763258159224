import styled, { css } from 'styled-components'
import {
  POPUP_HEADER_HEIGHT,
  POPUP_CONTENT_MAX_HEIGHT,
} from '@stores/PopupStore'
import closeIcon from '@assets/close-icon.png'

interface IStyleProps {
  meetingColour?: string
}

export const CloseButton = styled.button<IStyleProps>`
  ${({ meetingColour }) =>
    css`
      height: 40px;
      width: 40px;
      border: none;
      background-image: url(${closeIcon});
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      margin-right: 5px;
      background-color: ${meetingColour || '#eeeeee'};
    `}
`

export const WhiteBackgroundWrapper = styled.div<IStyleProps>`
  background: white;
  margin: 10px;
  border: 1px solid #979797;
  border-radius: 5px;
`

export const NewspaperBackgroundWrapper = styled.div<IStyleProps>`
  ${({ meetingColour }) => css`
    background: white;
    border: 3px solid ${meetingColour};
  `}
`

export const PopupHeaderRunner = styled.div<IStyleProps>`
  ${({ meetingColour }) => css`
    display: flex;
    height: ${POPUP_HEADER_HEIGHT}px;
    justify-content: space-between;
    align-items: center;
    margin: 0;
    padding: 2px 0 0 10px;
    border-radius: 4px;
    background-color: ${meetingColour || '#eeeeee'};
    > div {
      display: flex;
      align-items: center;
      > p {
        color: ${meetingColour ? 'white' : 'black'};
        margin-left: 10px;
      }
    }
  `}
`

export const PopupHeaderNewspaper = styled.div<IStyleProps>`
  ${({ meetingColour }) => css`
    display: flex;
    height: ${POPUP_HEADER_HEIGHT}px;
    justify-content: space-between;
    align-items: center;
    margin: 0;
    padding: 2px 0 0 10px;
    background-color: ${meetingColour};
    > p {
      color: white;
    }
  `}
`

export const PopupCourseNameHeader = styled.div<IStyleProps>`
  ${({ meetingColour }) => css`
    display: flex;
    height: ${POPUP_HEADER_HEIGHT}px;
    justify-content: space-between;
    align-items: center;
    padding: 2px 0 0 10px;
    background-color: ${meetingColour};
    > div {
      display: flex;

      > p {
        color: white;
        margin-right: 10px;
      }
    }
  `}
`
export const PopupErrorStyle = styled.p`
  text-align: center;
  padding-bottom: 10px;
  padding-top: 10px;
`

// inner styling for injected html
export const PopupContentStyles = styled.div<IStyleProps>`
  /* bottom fade out to suggest content is scrollable */
  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    background-image: linear-gradient(
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 1) 80%
    );
    margin-right: 20px;
    height: 70px;
    width: 430px;
    margin-bottom: 11px;
    border-radius: 4px;
  }

  max-height: ${POPUP_CONTENT_MAX_HEIGHT}px;
  overflow-y: auto;
  padding-bottom: 50px;
  padding-right: 10px;

  /* scrollbar styling - only chrome and safari */
  ::-webkit-scrollbar {
    width: 12px;
  }

  ::-webkit-scrollbar-track {
    background: #f1f1f1;
    margin-bottom: 30px;
    margin-top: 10px;
    border-radius: 6px;
  }

  ::-webkit-scrollbar-thumb {
    background: #1e1e1e;
    border-radius: 6px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  /* define default font and size */
  * {
    font-family: ${({ theme }) => theme.fonts.proximaCondRegular};
    font-size: 16px;
  }

  > div {
    margin: 10px 0px 10px 10px;
  }

  hr {
    visibility: hidden;
    position: absolute;
    top: -9999px;
  }
  .horse-info {
    border-bottom: 1px solid #979797;
    padding-bottom: 10px;
    > div {
    }
    .form-overview {
      padding-bottom: 10px;
      padding-top: 10px;
      margin-top: 10px;
      border-bottom: 1px solid #979797;
      border-top: 1px solid #979797;
      font-family: ${({ theme }) => theme.fonts.proximaRegular};
    }
    .section-name {
      display: none;
    }

    .form-overview--placings {
      span {
        font-family: ${({ theme }) => theme.fonts.proximaRegular};
        font-weight: bold;
      }
    }

    .horse-info--age {
      margin-right: -3px;
      font-weight: bold;
      font-family: ${({ theme }) => theme.fonts.proximaBold};
      font-size: 18px;
    }

    .horse-info--trainer {
      font-weight: bold;
      font-family: ${({ theme }) => theme.fonts.proximaRegular};
    }

    .horse-info--trainer_jockey {
      display: flex;
      justify-content: space-between;
    }

    .form-overview--summary {
      display: flex;
      flex-wrap: wrap;
      justify-content: start;
      margin-top: 10px;
      span {
        padding-right: 3px;
      }
    }

    > .form-overview--summary_entry {
      white-space: nowrap;
      margin-right: 10px;
    }

    .form-overview--list_of_wins_table:first {
    }
    .total-win {
      border-top: 1px solid #979797;
      border-bottom: 1px solid #979797;
      background-color: #eeeeee;
      margin-top: 10px;
      margin-bottom: 10px;
      .bold {
        font-weight: bold;
      }
    }
    .form-overview--list_of_wins_table {
      tbody {
        tr {
          height: 25px;
          td:nth-child(5) {
            width: 25%;
            text-align: right;
          }
          td:nth-child(4) {
            width: 47%;
          }
          td:nth-child(3) {
            width: 13%;
          }
          td:nth-child(2) {
            width: 10%;
          }
        }
      }
    }
    .form-overview--result_stats_table_header {
      border-bottom: 1px solid #979797;
      background-color: #eeeeee;
      tbody {
        tr {
          height: 25px;
          th:nth-child(1) {
            text-align: left;
          }
          th:nth-child(2) {
            text-align: left;
          }
        }
        tr {
          th:nth-child(7) {
            text-align: right;
            padding-right: 4px;
          }
        }
      }
    }
    .form-overview--result_stats_table_body {
      margin-bottom: 10px;
    }
    .form-overview--result_stats_table_body {
      table:last-of-type {
        border-bottom: 1px solid #979797;
      }
    }
    .form-overview--race_records {
      justify-content: space-between;
    }
  }

  .last-races {
    .last-races--race {
      table {
        tbody {
          tr {
            td:nth-child(1) {
              width: 15%;
            }
            td:nth-child(2) {
              width: 15%;
            }
            td:nth-child(3) {
              width: 0%;
            }
            td:nth-child(4) {
              width: 30%;
            }
            td:nth-child(5) {
              width: 30%;
              text-align: right;
            }
          }
        }
      }
    }
    .last_races--next {
      position: relative;
      .last-races--next_run {
        .right-side {
          position: absolute;
          right: 0;
        }
      }
    }
    border-bottom: 1px solid #979797;
    > div {
      margin-bottom: 10px;
    }
    .last-races--header-one {
      padding-bottom: 10px;
      width: 100%;
      justify-content: space-between;
      display: flex;
    }
    .last-races--places {
      width: 100%;
      font-size: 18px;
      position: relative;
      .last-races--something_3 {
        right: 0;
        position: absolute;
        font-style: normal;
        margin-left: 10px;
      }
      .last-races--jockey_allowance {
        right: 40px;
        position: absolute;
        font-style: normal;
        margin-right: 10px;
        float: right;
      }
    }
  }

  .bold {
    font-weight: 700;
  }
  .huge {
    font-size: 20px;
  }
  .normal {
    font-weight: normal;
  }
  .hmidle {
    text-align: center;
  }
  .italic {
    font-style: italic;
  }
  .smaller-text {
    font-size: 10px;
  }

  /* ------------------ */
  /* horse info section */
  .horse-info--weight {
  }
  .horse-info--age {
  }
  .years-old {
    font-family: ${({ theme }) => theme.fonts.proximaBold};
  }
  .horse-info--color {
    font-style: italic;
  }
  .horse-info--sex {
    font-style: italic;
  }
  .horse-info--sire {
    font-style: italic;
  }
  .horse-info--dam {
    font-style: italic;
  }
  .horse-info--dam_sire {
  }
  .horse-info--jockey {
    font-family: ${({ theme }) => theme.fonts.proximaRegular};
  }
  .horse-info--jockeyWC {
    font-family: ${({ theme }) => theme.fonts.proximaRegular};
    font-style: normal;
  }

  .horse-info--trainer_jockey {
    padding-top: 10px;
  }
  /* --------------------- */
  /* form overview section */
  .form-overview--placings {
  }
  .form-overview--race_records {
  }
  .form-overview--official_rating {
    font-family: ${({ theme }) => theme.fonts.proximaRegular};
    font-size: 18px;
  }
  .form-overview--official_rating_num {
    font-family: ${({ theme }) => theme.fonts.proximaRegular};
    font-size: 18px;
  }
  .form-overview--OR_HC_letter {
    font-family: ${({ theme }) => theme.fonts.proximaRegular};
    font-size: 18px;
  }
  .form-overview--OR_HC_num {
    font-family: ${({ theme }) => theme.fonts.proximaRegular};
    font-size: 18px;
    font-weight: bold;
  }
  .form-overview--start {
  }
  .form-overview--first {
  }
  .form-overview--second {
  }
  .form-overview--third {
  }
  .form-overview--WinPl {
  }
  .form-overview--race_type {
  }
  .form-overview--start_total {
  }
  .form-overview--first_place {
  }
  .form-overview--second_place {
  }
  .form-overview--third_place {
  }
  .form-overview--prize_money {
    text-align: right;
    padding-right: 4px;
    font-weight: normal;
  }
  .form-overview--list_of_wins_table {
    padding-right: 4px;
  }

  .form-overview--date {
    text-align: left;
  }
  .form-overview--course {
    text-align: left;
  }
  .form-overview--distance {
  }
  .form-overview--details {
  }
  .form-overview--summary_entry {
  }
  .form-overview--summary_entry_text {
  }
  .form-overview--summary_entry_stat {
  }

  /* ------------------ */
  /* last races section */

  .last-races--header-one-leftSide {
    font-weight: bold;
  }
  .last-races--header-two {
    width: 100%;
    justify-content: space-between;
    display: flex;
    font-size: 18px;
  }
  .last-races--header-one-rightSide {
  }
  .last-races--header-two-leftSide {
    font-size: 18px;
  }
  .last-races--header-two-rightSide {
  }
  .last-races--date {
  }
  .last-races--course {
  }
  .last-races--distance {
    position: relative;
    .left {
      .last-races--distance_text {
        font-weight: bold;
      }
    }
    .right {
      position: absolute;
      right: 0;
      .last-races--RPR_text {
        font-weight: bold;
      }
    }
    > span {
      margin-right: 2px;
      > sup {
        vertical-align: baseline;
      }
      > sub {
        vertical-align: baseline;
      }
    }
  }
  .last-races--details {
  }
  .last-races--prize_money {
  }
  .last-races--runners {
  }
  .last-races--runners_text {
  }
  .last-races--going {
  }
  .last-races--no_of_fances {
  }
  .last-races--no_of_fances_text {
  }
  .last-races--winnign_time_text {
  }
  .last-races--winnign_time {
  }
  .last-races--difference {
  }
  .last-races--place {
  }
  .last-races--form_horse_name {
  }
  .last-races--other_horse_name {
  }
  .last-races--age {
  }
  .last-races--something_1 {
  }
  .last-races--something_2 {
  }
  .last-races--jockey {
  }
  .last-races--jockey_allowance {
    margin-right: 0;
  }
  .last-races--something_4 {
  }
  .last-races--note {
  }
  .last-races--distance_text {
  }
  .last-races--RPR_text {
  }
  .last-races--RPR {
  }
  .last-races--next_run_text {
    font-weight: bold;
  }
  .last-races--next_run_first {
  }
`
