import { observable, action } from 'mobx'
import reject from 'lodash/reject'
import { IPopupProps } from '@components/Popup/IPopup'
import { HEIGHT_4K, WIDTH_4K, MAX_COLS_PER_SCREEN } from '@config'

const COLUMN_WIDTH = 480
const COLUMN_OFFSET = 9
const RACECARD_MARGIN = 12
const COLUMN_BORDER_WIDTH = 2

export const POPUP_HEADER_HEIGHT = 60
export const POPUP_CONTENT_MAX_HEIGHT = 450
export const POPUP_WIDTH = COLUMN_WIDTH - COLUMN_OFFSET

export const POPUP_MAX_HEIGHT =
  POPUP_HEADER_HEIGHT * 2 + POPUP_CONTENT_MAX_HEIGHT

interface IRaceCardPosition {
  top: number
  left: number
  right: number
  bottom: number
}

export interface IPopupContent {
  props: IPopupProps
  id: string
  raceId: number
  left: number
  top: number | null
  bottom: number | null
  closeAfter?: number
  open?: boolean
  inColumn?: number
  raceCardPosition?: IRaceCardPosition
  runnerColumnIndex?: number
  raceStartColumnIndex?: number
}

class PopupStore {
  @observable public popupContents: IPopupContent[] = []

  public openPopup = (content: IPopupContent): boolean => {
    // reject any popup when one is open for the same race
    if (this.popupContents.find(({ id, raceId }) => id === content.id)) {
      return false
    }

    // only allow two runner popups for the same race to be open
    const sameRacePopups = this.popupContents.filter(
      (runnerPopup: IPopupContent) => {
        const { raceId, props } = runnerPopup
        return raceId === content.raceId && props.runnerInfo
      },
    )

    if (sameRacePopups && sameRacePopups.length >= 2) {
      this.closePopup(sameRacePopups[0].id)
    }

    const popup = this.calculatePopupPosition(content)

    if (popup) {
      this.addNewModal(popup)
      return true
    }

    return false
  }

  public closePopup = (id: string) => {
    this.setModalAsClosed(id)

    setTimeout(() => this.removeModal(id), 300)
  }

  @action private addNewModal = (content: IPopupContent) =>
    this.popupContents.push({ ...content, open: true })

  @action private setModalAsClosed = (id: string) => {
    this.popupContents = this.popupContents.map(popup => {
      if (popup.id === id) {
        popup.props.onClose()
        return { ...popup, open: false }
      } else {
        return popup
      }
    })
  }

  @action private removeModal = (id: string) => {
    this.popupContents = reject(
      this.popupContents,
      content => content.id === id,
    )
  }

  private calculatePopupPosition = ({
    left: raceCardLeft,
    top,
    bottom,
    raceStartColumnIndex,
    runnerColumnIndex,
    ...content
  }: IPopupContent): IPopupContent | false => {
    if (!top || !bottom) {
      throw new Error('Racecard position was not passed to store correctly')
    }
    // popups have four possible positions when portioned relative to the race card
    // we need to check if each any one of those positions are valid
    // and do not collide with other popups, open race cards or screen edges

    // position that will be passed to the final popup and what will go into the css
    // bottom here is css style - bottom of the element, relative from the bottom of the screen
    interface IActualPosition {
      id?: string
      left: number
      top: number | null
      bottom: number | null
    }

    // position calculated based on height and width,
    // bottom here is BoundingClientRectangle style - bottom of the element, relative from the top of the screen
    interface INormalisedPosition {
      id?: string
      left: number
      right: number
      top: number
      bottom: number
    }

    // popups are always anchored to the corner of a race card,
    // positionTopLeft meaning from the top left corner of a race card going down
    // these four functions generate an ActualPosition that gets passed to the css
    const positionTopLeft = (): IActualPosition => ({
      left:
        raceCardLeft -
        COLUMN_WIDTH +
        COLUMN_OFFSET -
        RACECARD_MARGIN +
        COLUMN_BORDER_WIDTH,
      top,
      bottom: null,
    })

    const positionTopRight = (): IActualPosition => ({
      left: raceCardLeft + COLUMN_WIDTH - RACECARD_MARGIN - COLUMN_BORDER_WIDTH,
      top,
      bottom: null,
    })
    const positionBottomRight = (): IActualPosition => ({
      ...positionTopRight(),
      top: null,
      bottom: HEIGHT_4K - bottom + 1,
    })
    const positionBottomLeft = (): IActualPosition => ({
      ...positionTopLeft(),
      top: null,
      bottom: HEIGHT_4K - bottom + 1,
    })

    const positionTopLeftRunner = (): IActualPosition => {
      if (runnerColumnIndex && raceStartColumnIndex) {
        if (runnerColumnIndex === raceStartColumnIndex) {
          return {
            left:
              raceCardLeft -
              COLUMN_WIDTH +
              COLUMN_OFFSET -
              RACECARD_MARGIN +
              COLUMN_BORDER_WIDTH,
            top,
            bottom: null,
          }
        }
        if (runnerColumnIndex > raceStartColumnIndex) {
          const columnDifference = runnerColumnIndex - raceStartColumnIndex
          const columnWidth = COLUMN_WIDTH * (columnDifference + 1)
          const raceCardMargin = RACECARD_MARGIN * (columnDifference * 1)
          const left =
            raceCardLeft -
            columnWidth +
            COLUMN_OFFSET -
            raceCardMargin +
            COLUMN_BORDER_WIDTH
          return {
            left,
            top,
            bottom: null,
          }
        }
      }
      return {
        left:
          raceCardLeft -
          COLUMN_WIDTH +
          COLUMN_OFFSET -
          RACECARD_MARGIN +
          COLUMN_BORDER_WIDTH,
        top,
        bottom: null,
      }
    }
    const positionBottomLeftRunner = (): IActualPosition => ({
      ...positionTopLeftRunner(),
      top: null,
      bottom: HEIGHT_4K - bottom + 5,
    })

    // this helper will turn an actual position into a normalised position
    const normalisePosition = (pos: IActualPosition): INormalisedPosition => ({
      id: pos.id,
      left: pos.left,
      right: pos.left + COLUMN_WIDTH,
      top:
        pos.top ||
        (pos.bottom ? HEIGHT_4K - (pos.bottom + POPUP_MAX_HEIGHT) : 0),
      bottom: pos.bottom
        ? HEIGHT_4K - pos.bottom
        : pos.top
        ? pos.top + POPUP_MAX_HEIGHT
        : 0,
    })

    // store an array of ids of collided popups to close them as a last resort
    const collidedPopups: string[] = []

    // four collision checkers all use normalised positions
    const collidesWithOtherPopup = (
      newPopup: INormalisedPosition,
      normalisedPositionsOfOpenPopups: INormalisedPosition[],
    ): boolean =>
      normalisedPositionsOfOpenPopups.reduce(
        (collision: boolean, openPopup) => {
          if (collision) {
            return collision
          }

          const topBottomCollision =
            newPopup.bottom > openPopup.top && newPopup.top < openPopup.bottom
          const leftRightCollision =
            newPopup.right > openPopup.left && newPopup.left < openPopup.right

          if (topBottomCollision && leftRightCollision && openPopup.id) {
            collidedPopups.push(openPopup.id)
          }

          return topBottomCollision && leftRightCollision
        },
        false,
      )
    const collidesWithOpenRaceCard = (newPopup: INormalisedPosition): boolean =>
      this.popupContents.reduce((collision: boolean, openPopup) => {
        if (collision) {
          return collision
        }
        if (!openPopup.raceCardPosition || !openPopup.open) {
          return false
        }

        const topBottomCollision =
          newPopup.bottom > openPopup.raceCardPosition.top &&
          newPopup.top < openPopup.raceCardPosition.bottom
        const leftRightCollision =
          newPopup.right > openPopup.raceCardPosition.left &&
          newPopup.left < openPopup.raceCardPosition.right - 13

        if (topBottomCollision && leftRightCollision) {
          collidedPopups.push(openPopup.id)
        }

        return topBottomCollision && leftRightCollision
      }, false)
    const collidesWithScreenEdge = (newPopup: INormalisedPosition): boolean => {
      const topScreenCollision = newPopup.top < 0
      const rightScreenCollision = newPopup.right > WIDTH_4K
      const bottomScreenCollision = newPopup.bottom > HEIGHT_4K
      const leftScreenCollision = newPopup.left < 0

      return (
        topScreenCollision ||
        rightScreenCollision ||
        bottomScreenCollision ||
        leftScreenCollision
      )
    }

    const raceCardCollidesWithModal = (
      newPopupRaceCard: INormalisedPosition,
      normalisedPositionsOfOpenPopups: INormalisedPosition[],
    ): boolean =>
      normalisedPositionsOfOpenPopups.reduce(
        (collision: boolean, openPopup) => {
          if (collision) {
            return collision
          }

          const topBottomCollision =
            newPopupRaceCard.bottom > openPopup.top &&
            newPopupRaceCard.top < openPopup.bottom
          const leftRightCollision =
            newPopupRaceCard.right > openPopup.left &&
            newPopupRaceCard.left < openPopup.right

          if (topBottomCollision && leftRightCollision && openPopup.id) {
            collidedPopups.push(openPopup.id)
          }

          return topBottomCollision && leftRightCollision
        },
        false,
      )

    // helper to combine collision checkers
    const collidesWithNothing = (
      pos: INormalisedPosition,
      raceCardPos: INormalisedPosition,
      normalisedPositionsOfOpenPopups: INormalisedPosition[],
    ): boolean =>
      !(
        collidesWithScreenEdge(pos) ||
        collidesWithOtherPopup(pos, normalisedPositionsOfOpenPopups) ||
        raceCardCollidesWithModal(
          raceCardPos,
          normalisedPositionsOfOpenPopups,
        ) ||
        collidesWithOpenRaceCard(pos)
      )

    // generate a normalised position of the current race card so we can use to the check for collisions on new popups
    const raceCardPosition = {
      left: raceCardLeft,
      top,
      bottom,
      right: raceCardLeft + COLUMN_WIDTH,
    }

    // only attempt to add 3 times - removing popups each time
    for (let attempt = 1; attempt <= 3; attempt++) {
      // Runners popovers
      if (
        content.props.runnerInfo &&
        runnerColumnIndex &&
        raceStartColumnIndex
      ) {
        const normalisedPositionsOfOpenPopups = this.popupContents
          .filter(({ open }) => open)
          .map(normalisePosition)

        // position runner popovers on the right if they are in the first column on the screen

        const firstColumnRunner = runnerColumnIndex % MAX_COLS_PER_SCREEN === 0
        const firstColumnRace = raceStartColumnIndex % MAX_COLS_PER_SCREEN === 0
        if (firstColumnRunner || firstColumnRace) {
          const topRight = positionTopRight()
          if (
            collidesWithNothing(
              normalisePosition(topRight),
              raceCardPosition,
              normalisedPositionsOfOpenPopups,
            )
          ) {
            return { ...content, ...topRight, raceCardPosition }
          }
          const bottomRight = positionBottomRight()
          if (
            collidesWithNothing(
              normalisePosition(bottomRight),
              raceCardPosition,
              normalisedPositionsOfOpenPopups,
            )
          ) {
            return {
              ...content,
              ...bottomRight,
              raceCardPosition,
            }
          }
        }
        const topLeft = positionTopLeftRunner()
        if (
          collidesWithNothing(
            normalisePosition(topLeft),
            raceCardPosition,
            normalisedPositionsOfOpenPopups,
          )
        ) {
          return { ...content, ...topLeft, raceCardPosition }
        }

        const bottomLeft = positionBottomLeftRunner()
        if (
          collidesWithNothing(
            normalisePosition(bottomLeft),
            raceCardPosition,
            normalisedPositionsOfOpenPopups,
          )
        ) {
          return {
            ...content,
            ...bottomLeft,
            raceCardPosition,
          }
        }

        this.closePopup(collidedPopups[0])
        collidedPopups.shift()
      } else {
        // newspaper tips popup
        const normalisedPositionsOfOpenPopups = this.popupContents
          .filter(({ open }) => open)
          .map(normalisePosition)

        const topRight = positionTopRight()
        if (
          collidesWithNothing(
            normalisePosition(topRight),
            raceCardPosition,
            normalisedPositionsOfOpenPopups,
          )
        ) {
          return { ...content, ...topRight, raceCardPosition }
        }
        const bottomRight = positionBottomRight()
        if (
          collidesWithNothing(
            normalisePosition(bottomRight),
            raceCardPosition,
            normalisedPositionsOfOpenPopups,
          )
        ) {
          return {
            ...content,
            ...bottomRight,
            raceCardPosition,
          }
        }

        const topLeft = positionTopLeft()
        if (
          collidesWithNothing(
            normalisePosition(topLeft),
            raceCardPosition,
            normalisedPositionsOfOpenPopups,
          )
        ) {
          return { ...content, ...topLeft, raceCardPosition }
        }

        const bottomLeft = positionBottomLeft()
        if (
          collidesWithNothing(
            normalisePosition(bottomLeft),
            raceCardPosition,
            normalisedPositionsOfOpenPopups,
          )
        ) {
          return {
            ...content,
            ...bottomLeft,
            raceCardPosition,
          }
        }

        this.closePopup(collidedPopups[0])
        collidedPopups.shift()
      }
    }

    // give up, don't create a popup
    return false
  }
}

export default PopupStore
