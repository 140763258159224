import React, { Fragment, FunctionComponent } from 'react'
import { observer } from 'mobx-react'
import Horse from './Horse'
import ProgressBar from './ProgressBar'
import { BrandLogo } from 'rp-components'

import { ILoading } from './ILoading'

import './Loading.scss'

const Loading: FunctionComponent<ILoading> = ({
  hasGotData,
  error,
  loadingStore,
}) =>
  loadingStore ? (
    <div className="loading__container">
      <BrandLogo whiteText={true} scale={6} />
      {!error ? (
        <Fragment>
          <Horse />
          <ProgressBar />
          <div className="loading__text">
            <p>{hasGotData ? 'Loading' : 'Fetching Race Data'}</p>
            {!!loadingStore.amountOfCols && !!loadingStore.colsToHit && (
              <p>
                {loadingStore.amountOfCols} / {loadingStore.colsToHit}
              </p>
            )}
            {loadingStore.currentStep && <p>{loadingStore.currentStep}</p>}
          </div>
        </Fragment>
      ) : (
        <div className="loading__text">{error}</div>
      )}
    </div>
  ) : null

export default observer(Loading)
