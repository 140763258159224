import React, { FunctionComponent, Fragment } from 'react'
import { IRunner } from '../RaceCardInterfaces/IRunner'
import { observer } from 'mobx-react'
import WeightRating from './WeightRating'
import get from 'lodash/get'
import HorseInfo from './HorseInfo'
import cx from 'classnames'
import Silk from './Silk'
import RaceBoard from '../RaceBoard'
import RunnerSpotlight from './RunnerSpotlight'
import GreyoutRP from '@utils/GreyoutRP'
import Verdict from '@components/HorseComponents/VerdictWithRPR/Verdict'
import Tipping from '@components/HorseComponents/Tipping/Tipping'
import PopupClickWrapperRunner from '@components/Popup/RunnerPopup/PopupClickWrapperRunner'
import InlineTip from '@components/HorseComponents/Tipping/InlineTip'
import AbandonedRaceStore from '@stores/AbandonedRaceStore'

const Runner: FunctionComponent<IRunner> = ({
  runner,
  raceIndex,
  runnerGroupIndex,
  runnerIndex,
  race,
  showSpotlights,
  showDiomedSpotlights,
  showTips,
  showVerdict,
  realRender,
  meetingAbandoned,
  showInlineSpotlights,
  screenStore,
  verdictAtBottom,
  meetingColour,
  showInlineTip,
  isLastRunnerGroup,
  columnIndex,
}) => {
  const result = runner.getResult()
  const placesToShow = race.getRunnersToPlaceCount()
  const outcome = get(result, 'race_outcome_position', 999)
  const placedHorse = outcome <= 3
  const outComeAsInt = parseInt(outcome, 10)
  const resultStartingPrice = get(result, 'odds_desc')
  const tipping = race.getTipping()
  const tippedHorse = tipping
    ? tipping.horse_uid === runner.runner.horse_uid
    : null
  const shouldShowResult = outComeAsInt <= placesToShow
  const shouldGreyoutRunner =
    (!placedHorse && race.hasRaceFinished() && result === null) ||
    outcome === null ||
    (race.raceHasResults() && !shouldShowResult) ||
    AbandonedRaceStore.isRaceAbandoned(race.getRaceInstanceUid())

  const shouldGreyoutRaceBoard =
    race.hasRaceFinished() ||
    race.raceHasResults() ||
    AbandonedRaceStore.isRaceAbandoned(race.getRaceInstanceUid())

  const popupRunnerInfo = {
    courseName: race.getDiffusionName(),
    raceTime: race.getParsedRaceTime(),
    silk: runner.getSilkImagePath(),
  }

  // disable for SAF races
  const isSafRace = race.getCountryCode() === 'SAF'

  if (runnerGroupIndex === 0 && runnerIndex === 0 && columnIndex) {
    race.setRaceStartColumn(columnIndex)
  }
  const raceStartColumn = race.raceStartColumn
  return (
    <Fragment>
      {runnerGroupIndex === 0 && runnerIndex === 0 && (
        <GreyoutRP greyoutCondition={shouldGreyoutRaceBoard}>
          <RaceBoard
            realRender={realRender}
            race={race}
            raceIndex={raceIndex}
          />
        </GreyoutRP>
      )}
      {!verdictAtBottom &&
        runnerGroupIndex === 0 &&
        runnerIndex === 0 &&
        showVerdict && (
          <Verdict
            lastSetSelectionBoxesRemoved={true}
            isLastSet={true}
            race={race}
            meetingAbandoned={meetingAbandoned}
            showInlineTip={showInlineTip}
            showSpotlights={showSpotlights}
            showInlineSpotlights={showInlineSpotlights}
          />
        )}
      {!showInlineTip &&
        showTips &&
        runnerGroupIndex === 0 &&
        runnerIndex === 0 &&
        tipping && (
          <Tipping
            realRender={realRender}
            race={race}
            meetingAbandoned={meetingAbandoned}
            tip={tipping}
          />
        )}
      <GreyoutRP greyoutCondition={shouldGreyoutRunner} realRender={realRender}>
        <PopupClickWrapperRunner
          realRender={realRender}
          raceId={race.getRaceInstanceUid()}
          horseName={runner.getHorseName() || ''}
          disable={shouldGreyoutRunner || runner.getNonRunner() || isSafRace}
          tippedHorse={tippedHorse}
          showInlineSpotlights={showInlineSpotlights}
          nonRunner={runner.getNonRunner()}
          showSpotlights={showSpotlights}
          meetingColour={meetingColour}
          isLastRunnerGroup={isLastRunnerGroup}
          runnerGroupIndex={runnerIndex}
          runnerInfo={popupRunnerInfo}
          analyticsLabel={`${race.getDiffusionName()} > ${race.getRaceTime()} > ${runner.getHorseName()}`}
          section={'RaceCard'}
          runnerColumnIndex={columnIndex}
          raceStartColumnIndex={raceStartColumn}
        >
          <div
            className={cx({
              'race-card__tipped--horse': tippedHorse && !runner.getNonRunner(),
            })}
          >
            <div
              className={cx('race-card__row', {
                'race-card--non-runner': runner.getNonRunner(),
                'race-card__first--horse':
                  runnerGroupIndex === 0 && runnerIndex === 0,
                'race-card__row-bottom-border':
                  !showInlineSpotlights || !showSpotlights,
              })}
              style={{
                borderWidth:
                  tippedHorse &&
                  runner.runner.start_number === 1 &&
                  !shouldGreyoutRunner
                    ? '2px'
                    : 'none',
              }}
            >
              <div className="race-card__left">
                <Silk
                  silkPath={runner.getSilkImagePath()}
                  nonRunner={runner.getNonRunner()}
                  place={outcome}
                  shouldShowResult={shouldShowResult}
                  realRender={realRender}
                />
              </div>
              <div className="race-card__right">
                <div className="race-card--flex race-card--flex-one">
                  <HorseInfo
                    nonRunner={runner.getNonRunner()}
                    leftColumn={{
                      runnerForm: runner.getRunnerForm(),
                      startNumber: runner.getHorseStartNumber(),
                      saddleClothNumber: runner.getSaddleClothNumber(),
                      draw: runner.getDraw(),
                      postMark: runner.getRPPostMark(),
                      ratingImprover: runner.getRPPostMarkImprover(),
                      ratingHigh: runner.getRunnerIsHighestRPR(),
                    }}
                    middleColumn={{
                      horseName: runner.getHorseName(),
                      countryCode: runner.getCountryOriginCode(),
                      raceCountryCode: race.getCountryCode(),
                      weightAllowance: runner.getWeightAllowanceLbs(),
                      jockeyFirstTime: runner.getJockeyFirstTime(),
                      horseColour: runner.getHorseColourCode(),
                      geldingFirstTime: runner.getGeldingFirstTime(),
                      horseSex: runner.getHorseSexCode(),
                      jockeyName:
                        runner.getJockeyShortName() ||
                        runner.getJockeyLongName(),
                      horseSire: runner.getSireName(),
                      horseDam: runner.getDamName(),
                      newTrainerRacesCount: runner.getTrainerRacesCount(),
                      trainerName:
                        runner.getTrainerShortName() ||
                        runner.getTrainerLongName(),
                      ownerName: runner.getOwnerName(),
                      windSurgerySecondTime: runner.getWindSurgerySecondTime(),
                      windSurgeryFirstTime: runner.getWindSurgeryFirstTime(),
                      handicapFirstTime: runner.getFirstTimeHandiCap(),
                      extraWeight: runner.getExtraWeight(),
                      runner,
                    }}
                  />
                  <WeightRating
                    placedHorse={placedHorse}
                    topRow={{
                      jockeyWeight: runner.getFormattedJockeyWeight(),
                      headGear: runner.getHeadGearCode(),
                      headGearFirstTime: runner.getHeadGearFirstTime(),
                      horseAge: runner.getHorseAge(),
                      plus10: runner.getPlus10Horse(),
                      distanceWins: runner.getDistanceWins(),
                      courseWins: runner.getCourseWins(),
                      courseDistanceWins: runner.getCourseAndDistanceWins(),
                      slowGroundJumpsWins: runner.getSlowGroundJumpWins(),
                      slowGroundFlatWins: runner.getSlowGroundFlatWins(),
                      daysLastRun: runner.getDaysSinceLastRun(),
                      daysLastRunFlat: runner.getDaysSinceLastRunFlat(),
                      fastGroundWins: runner.getFastGroundWins(),
                      daysLastRunJumps: runner.getDaysSinceLastRunJumps(),
                      daysLastRunPTP: runner.getDaysSinceLastRunPTP(),
                      beatenFav: runner.getBeatenFavourite(),
                    }}
                    bottomRow={{
                      nonRunner: runner.getNonRunner(),
                      price: runner.price,
                      prices: runner.priceHistory,
                      favourite: runner.isFavourite,
                      race,
                      shouldShowPrices: runner.shouldShowPrices(),
                      shouldShowResult,
                      resultStartingPrice,
                    }}
                    screenStore={screenStore}
                  />
                </div>
              </div>
            </div>
            {showInlineTip && tippedHorse && tipping && (
              <InlineTip tipping={tipping} />
            )}
          </div>
          {showSpotlights && showInlineSpotlights && (
            <RunnerSpotlight
              raceId={race.getRaceInstanceUid().toString()}
              tippedHorse={tippedHorse}
              showDiomedSpotlights={showDiomedSpotlights}
              item={runner.getSpotlight()}
            />
          )}
        </PopupClickWrapperRunner>
      </GreyoutRP>
    </Fragment>
  )
}

export default observer(Runner)
