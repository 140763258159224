import React, { FunctionComponent, Fragment } from 'react'
import get from 'lodash/get'
import { IAllSpotlights } from './ISpotlight'
import SpotlightGroup from './SpotlightGroup'
import pair from '@utils/pair'

const Spotlights: FunctionComponent<IAllSpotlights> = ({
  meetingAbandoned,
  innerRef,
  data,
  showDiomedSpotlights,
  postDataHorse,
  rprHorse,
  race,
  verdictAtBottom,
  showVerdict,
  showSelectionBoxes,
  tippedHorse,
}) => {
  if (data && data.length && !meetingAbandoned && !race.isRaceAbandoned()) {
    const pairedSpotlights = pair(data)
    return (
      <Fragment>
        {pairedSpotlights.map((spotlightGroup, spotlightGroupIndex) => (
          <SpotlightGroup
            key={get(spotlightGroup, '[0].spotlight')}
            innerRef={innerRef}
            spotlightGroup={spotlightGroup}
            race={race}
            showDiomedSpotlights={showDiomedSpotlights}
            spotlightGroupIndex={spotlightGroupIndex}
            isLastSpotlightGroup={
              spotlightGroupIndex === pairedSpotlights.length - 1
            }
            postDataHorse={postDataHorse}
            rprHorse={rprHorse}
            verdictAtBottom={verdictAtBottom}
            meetingAbandoned={meetingAbandoned}
            showVerdict={showVerdict}
            showSelectionBoxes={showSelectionBoxes}
            tippedHorse={tippedHorse}
          />
        ))}
      </Fragment>
    )
  }
  return null
}

export default Spotlights
