import MeetingQueue from '@classes/MeetingQueue'
import dayjs from 'dayjs'
import { IHorseRunner } from '@models/HorseMeeting'
import { formattedJockeyWeight } from '@utils/utils'
import { computed } from 'mobx'
import get from 'lodash/get'
import last from 'lodash/last'
import toUpper from 'lodash/toUpper'
import takeRight from 'lodash/takeRight'
import Race from './Race'
import NonRunnersStore from '@stores/NonRunnersStore'

interface IRunnerOptions {
  runner: IHorseRunner
  course: string | null
  raceTime: string | null
}

enum Wins {
  CourseAndDistanceWins = 'course_and_distance_wins',
  SlowGroundsJumpsWins = 'slow_ground_jumps_wins',
  SlowGroundFlatWins = 'slow_ground_flat_wins',
  FastGroundWins = 'fast_ground_wins',
  CourseWins = 'course_wins',
  DistanceWins = 'distance_wins',
}

class Runner {
  public runner: IHorseRunner
  private raceTime: string | null
  private course: string | null
  private meetingQueue: MeetingQueue
  private race: Race

  constructor(options: IRunnerOptions, race: Race) {
    const { runner, course, raceTime } = options
    this.course = course
    this.raceTime = raceTime
    this.runner = runner
    this.meetingQueue = race.meetingQueue
    this.race = race
  }

  public getPriceId = () => {
    if (this.raceTime && this.course && this.meetingQueue.bookmakerId) {
      const { bookmakerId } = this.meetingQueue
      const upperCaseHorseName = toUpper(this.getHorseName() || '')
      const raceDateTime = dayjs(this.raceTime)
      const date = raceDateTime.format('YYYY-MM-DD')
      const time = raceDateTime.format('HH:mm')
      const courseName = this.setCourseName(this.course)
      const id = `${date}/${toUpper(
        courseName,
      )}/${time}${bookmakerId}${upperCaseHorseName}`

      return id
    }
    return ''
  }

  public getResult = () => {
    const horseName = this.getHorseName()
    if (horseName) {
      return this.race.getRunnerResult(horseName)
    }
    return null
  }

  public getNonRunner = () => {
    return NonRunnersStore.isNonRunner(this.getRaceId(), this.getHorseId())
  }

  public getHorseName = () => {
    return get(this.runner, 'horse_name', null)
  }

  public getHorseId = () => {
    return get(this.runner, 'horse_uid', 0)
  }

  public getCourseId = () => {
    return get(this.runner, 'course_uid', null)
  }

  public getRaceId = () => {
    return get(this.runner, 'race_instance_uid', null)
  }

  public getSilkImagePath = () => {
    const { silk_image_path } = this.runner

    if (!silk_image_path) {
      return null
    }

    const baseURL = this.race.isGlobal
      ? 'https://www.rp-assets.com/sa_svg/'
      : '//images.racingpost.com/svg/'

    return `${baseURL}${silk_image_path}.svg`
  }

  public getRunnerForm = () => {
    if (this.runner && this.runner.figures_calculated) {
      const calculatedRunnerForm = this.runner.figures_calculated
        .slice()
        .reverse()
      const specialFigureProps = ['X', 'P']
      const HTML = calculatedRunnerForm
        .map(figure =>
          specialFigureProps.includes(figure.race_type_code)
            ? `<b>${figure.form_figure}</b>`
            : figure.form_figure,
        )
        .join('')
      return { __html: HTML }
    }
    return null
  }

  public getWindSurgerySecondTime = () => {
    return get(this.runner, 'wind_surgery_second_time', null)
  }

  public getWindSurgeryFirstTime = () => {
    return get(this.runner, 'wind_surgery_first_time', null)
  }

  public getCourseAndDistanceWins = () =>
    this.getWinsByKey(Wins.CourseAndDistanceWins)

  public getSlowGroundJumpWins = () =>
    this.getWinsByKey(Wins.SlowGroundsJumpsWins)

  public getSlowGroundFlatWins = () =>
    this.getWinsByKey(Wins.SlowGroundFlatWins)

  public getFastGroundWins = () => this.getWinsByKey(Wins.FastGroundWins)

  public getCourseWins = () => this.getWinsByKey(Wins.CourseWins)

  public getDistanceWins = () => this.getWinsByKey(Wins.DistanceWins)

  public getDaysSinceLastRunJumps = () => {
    return get(this.runner, 'days_since_last_run_jumps', null)
  }

  public getDaysSinceLastRunFlat = () => {
    return get(this.runner, 'days_since_last_run_flat', null)
  }

  public getDaysSinceLastRunPTP = () => {
    return get(this.runner, 'days_since_last_run_ptp', null)
  }

  public getDaysSinceLastRun = () => {
    return get(this.runner, 'days_since_last_run', null)
  }

  public getCountryOriginCode = () => {
    return get(this.runner, 'country_origin_code', null)
  }

  public getBeatenFavourite = () => {
    return get(this.runner, 'beaten_favourite', null)
  }

  public getExtraWeight = () => {
    return get(this.runner, 'extra_weight', null)
  }

  public getPlus10Horse = () => {
    return get(this.runner, 'plus10_horse', null)
  }

  public getWeightAllowanceLbs = () => {
    return get(this.runner, 'weight_allowance_lbs', null)
  }

  public getGeldingFirstTime = () => {
    return get(this.runner, 'gelding_first_time', null)
  }

  public getJockeyFirstTime = () => {
    return get(this.runner, 'jockey_first_time', null)
  }

  public getHorseColourCode = () => {
    return get(this.runner, 'horse_colour_code', null)
  }

  public getHorseStartNumber = () => {
    return get(this.runner, 'start_number', null)
  }

  public getHorseSexCode = () => {
    return get(this.runner, 'horse_sex_code', null)
  }
  public getHorseAge = () => {
    return get(this.runner, 'horse_age', null)
  }

  public getJockeyShortName = () => {
    return get(this.runner, 'short_jockey_name', null)
  }

  public getJockeyLongName = () => {
    return get(this.runner, 'jockey_name', null)
  }

  public getSireName = () => {
    return get(this.runner, 'sire_name', null)
  }

  public getDamName = () => {
    return get(this.runner, 'dam_name', null)
  }

  public getOwnerName = () => {
    return get(this.runner, 'owner_name', null)
  }

  public getTrainerShortName = () => {
    return get(this.runner, 'short_trainer_name', null)
  }

  public getTrainerLongName = () => {
    return get(this.runner, 'trainer_stylename', null)
  }

  public getTrainerRacesCount = () => {
    return get(this.runner, 'new_trainer_races_count', null)
  }

  public getRPPostMarkImprover = () => {
    return get(this.runner, 'rp_postmark_improver', null)
  }

  public getRPPostMark = () => {
    return get(this.runner, 'rp_postmark', null)
  }

  public getRunnerIsHighestRPR = () => {
    return get(this.runner, 'highest', null)
  }

  public getFormattedJockeyWeight = () => {
    const weight = get(this.runner, 'weight_carried_lbs', 0)
    if (weight) {
      return formattedJockeyWeight(weight)
    }
    return null
  }

  public getHeadGearCode = () => {
    return get(this.runner, 'rp_horse_head_gear_code', null)
  }

  public getHeadGearFirstTime = () => {
    return get(this.runner, 'first_time', null)
  }

  public getSpotlight = () => {
    return get(this.runner, 'spotlight', null)
  }

  public getDraw = () => {
    return get(this.runner, 'draw', null)
  }

  public getSaddleClothNumber = () => {
    return get(this.runner, 'saddle_cloth_no', null)
  }

  public getFirstTimeHandiCap = () => {
    return get(this.runner, 'handicap_first_time', false)
  }

  public shouldShowPrices = () => {
    return get(this.meetingQueue, 'showPrices', true)
  }

  @computed
  get prices() {
    const prices = this.meetingQueue.priceStore.getOddsForRunner(
      this.race.getStatusId(),
      this.getPriceId(),
    )
    return prices
  }

  @computed
  get price() {
    const prices = this.prices

    if (prices) {
      const price = last(prices.odds) || null
      return price
    }
    return ''
  }

  @computed
  get priceHistory() {
    // take the last 4 prices, but remove the last one as that is the current price
    if (this.meetingQueue.showPriceHistory && this.prices) {
      const priceHistory = takeRight(this.prices.odds, 4)
      priceHistory.pop()
      return priceHistory
    }
    return []
  }

  @computed get isFavourite() {
    if (this.prices) {
      return this.prices.favourite
    }

    return false
  }

  private setCourseName = (course: string) => {
    // diffusion deals with this course differently and maps it to Newmarket.
    if (course.toUpperCase() === 'NEWMARKET <O>JULY<C>') {
      return 'NEWMARKET'
    }
    return course
  }

  private getWinsByKey = (key: Wins) => this.runner[key] || 0
}

export default Runner
