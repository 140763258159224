import styled, { keyframes } from 'styled-components'

interface IClockProps {
  rotate: number
}

export const ClockContainer = styled.div`
  display: flex;
  align-items: center;
`

export const Clock = styled.div`
  background-color: ${({ theme }) => theme.colors.primary};
  height: 50px;
  width: 50px;
  position: relative;
  border-radius: 50%;
  margin-left: 20px;
`
const BaseTimeUnit = styled.div<IClockProps>`
  background-color: white;
  height: 24px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform-origin: 50% 100%;
  border-top-left-radius: 20%;
  border-top-right-radius: 20%;
  transform: ${({ rotate }) => `translate(-50%, -100%) rotate(${rotate}deg)`};
`

export const Hours = styled(BaseTimeUnit)`
  height: 30%;
  width: 3px;
`

export const Minutes = styled(BaseTimeUnit)`
  width: 2px;
  height: 40%;
`

export const Pin = styled.div`
  height: 2px;
  width: 2px;
  background-color: white;
  border-radius: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  top: 50%;
  left: 50%;
`

export const CurrentTime = styled.h1`
  font-size: 50px;
  font-family: ${({ theme }) => theme.fonts.proximaBold};
  min-width: 85px;
  padding-left: 15px;
  text-align: right;
`

const BlinkAnimation = keyframes`
  0% {
    opacity: 0;
  }
  10% {
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  60% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
`

export const ClockColon = styled.span`
  color: black;
  animation: ${BlinkAnimation} ease-out 2s infinite;
`
