import React, { Fragment, FunctionComponent } from 'react'
import IRaceBoard from './RaceCardInterfaces/IRaceBoard'
import CustomText from '@horses/CustomText/CustomText'
import './RaceCardStyles/RaceBoard.scss'
import OffCountDown from '../OffCountDown/OffCountDown'
import ChannelLogo from './RaceCardComponents/ChannelLogo'

const RaceBoard: FunctionComponent<IRaceBoard> = ({ raceIndex, race }) => {
  const raceTime = race.getParsedRaceTime()
  const raceInstanceTitle = race.getRaceInstanceTitle()
  const raceClass = race.getRaceClass()
  const raceWinnings = race.getWinnings()
  const raceDistance = race.getRaceDistanceAsFurlongs()
  const tvText = race.getTvText()
  return (
    <div className="raceboard__container">
      <div className="raceboard__inner-container">
        <div className="raceboard__time-info">
          {raceTime && (
            <CustomText
              text={raceTime}
              size="giant"
              uppercase={true}
              proximaBlack={true}
            />
          )}
          <div className="raceboard__info">
            <Fragment>
              {raceInstanceTitle && raceInstanceTitle}
              <div className="raceboard__class-winner ">
                {raceClass && (
                  <CustomText
                    size="smaller"
                    text={`Class ${raceClass}`}
                    brackets={true}
                    proximaCondMedium={true}
                  />
                )}
                {raceWinnings && (
                  <CustomText
                    proximaCondMedium={true}
                    size="smaller"
                    text={raceWinnings}
                  />
                )}
              </div>
            </Fragment>
          </div>
        </div>
        <div className="raceboard__tv-winner-container">
          <div className="raceboard__tv-container">
            <ChannelLogo channel={tvText} />
          </div>
        </div>
      </div>
      <div className="raceboard__race-details">
        <div>
          <p className="raceboard__race-num">{`RACE ${raceIndex + 1}`}</p>
        </div>
        <OffCountDown race={race} />
        <div className="raceboard__distance">
          {raceDistance && (
            <CustomText
              text={raceDistance}
              colour="white"
              proximaExtraBold={true}
              size="raceboard"
              noSpace={true}
            />
          )}
        </div>
      </div>
    </div>
  )
}

export default RaceBoard
