import React, { FunctionComponent } from 'react'
import { PopupContentStyles, PopupErrorStyle } from '../Popup.css'

export interface IPopupProps {
  html: string
  meetingColour?: string
}

const PopupRunnerContent: FunctionComponent<IPopupProps> = ({
  html,
  meetingColour,
}) => (
  <div>
    {html && html.includes('last-races') ? (
      <PopupContentStyles
        meetingColour={meetingColour}
        dangerouslySetInnerHTML={{ __html: html }}
      />
    ) : (
      <PopupErrorStyle>No form available</PopupErrorStyle>
    )}
  </div>
)

export default PopupRunnerContent
