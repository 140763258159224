import React, { FunctionComponent } from 'react'
import CustomText from '@horses/CustomText/CustomText'
import { ICourseInfoBodyProps } from '../ICourseInfo'
import { yardsToFurlongs } from 'korelogic-rp-utils'
import map from 'lodash/map'

const Body: FunctionComponent<ICourseInfoBodyProps> = ({ records }) => {
  return (
    <tbody>
      {map(records, record => {
        const parsedDistance = yardsToFurlongs(record.distance_yards)
        return (
          <tr key={`${record.distance_yards}`} className="helpers--table-row">
            <td className="course-info--distance">
              <CustomText
                text={parsedDistance || '-'}
                fontTertiary={true}
                dottedBorder={true}
                noSpace={true}
                flexOne={true}
                size="small"
                flex={true}
              />
            </td>
            <td className="course-info--record-holder ">
              <CustomText
                text={record.horse_name || ''}
                fontTertiary={true}
                dottedBorder={true}
                noSpace={true}
                flexOne={true}
                size="small"
                flex={true}
              />
            </td>
            <td className="course-info--course-record">
              <CustomText
                text={`${record.winners_time}s`}
                fontTertiary={true}
                dottedBorder={true}
                noSpace={true}
                flexOne={true}
                size="small"
                flex={true}
              />
            </td>
            <td className="course-info--average-time">
              <CustomText
                text={`${record.average_time_sec}s`}
                fontTertiary={true}
                dottedBorder={true}
                noSpace={true}
                flexOne={true}
                size="small"
                flex={true}
              />
            </td>
          </tr>
        )
      })}
    </tbody>
  )
}

export default Body
