import React, { FunctionComponent } from 'react'
import { TInnerRef } from '@models/InnerRef'
import { ISpotlightsData } from './ISpotlight'
import SpotlightGroupChild from './SpotlightGroupChild'
import cx from 'classnames'
import Race from '@classes/Race'

interface ISpotlightBase {
  innerRef: TInnerRef
}

export interface ISpotlight {
  spotlightGroup: ISpotlightsData[]
  spotlightGroupIndex: number
  showDiomedSpotlights: boolean
  isLastSpotlightGroup: boolean
  postDataHorse: string
  rprHorse: string
  race: Race
  verdictAtBottom: boolean
  meetingAbandoned: boolean
  showVerdict: boolean
  showSelectionBoxes: boolean
  tippedHorse: boolean | null
}

interface ISpotlightGroup extends ISpotlightBase, ISpotlight {}

const SpotlightGroup: FunctionComponent<ISpotlightGroup> = ({
  innerRef,
  spotlightGroup,
  spotlightGroupIndex,
  showDiomedSpotlights,
  isLastSpotlightGroup,
  rprHorse,
  postDataHorse,
  race,
  verdictAtBottom,
  meetingAbandoned,
  showVerdict,
  showSelectionBoxes,
  tippedHorse,
}) => (
  <div
    ref={ref =>
      innerRef(
        ref,
        {
          innerRef,
          spotlightGroup,
          showDiomedSpotlights,
          spotlightGroupIndex,
          isLastSpotlightGroup,
          rprHorse,
          race,
          postDataHorse,
          verdictAtBottom,
          meetingAbandoned,
          showVerdict,
          showSelectionBoxes,
          tippedHorse,
        },
        SpotlightGroup,
        `SpotlightGroup ${isLastSpotlightGroup ? 'SpotlightGroup--last' : ''}`,
      )
    }
    className={cx('spotlight_container', {
      ['spotlight_container-no-rpr']:
        isLastSpotlightGroup && !postDataHorse && !rprHorse,
      ['SpotlightGroupContainer--last']: isLastSpotlightGroup,
    })}
  >
    <SpotlightGroupChild
      race={race}
      spotlightGroup={spotlightGroup}
      spotlightGroupIndex={spotlightGroupIndex}
      showDiomedSpotlights={showDiomedSpotlights}
      isLastSpotlightGroup={isLastSpotlightGroup}
      rprHorse={rprHorse}
      postDataHorse={postDataHorse}
      verdictAtBottom={verdictAtBottom}
      meetingAbandoned={meetingAbandoned}
      showVerdict={showVerdict}
      showSelectionBoxes={showSelectionBoxes}
      tippedHorse={tippedHorse}
    />
  </div>
)

export default SpotlightGroup
