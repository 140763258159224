import CustomText from '@horses/CustomText/CustomText'
import React, { FunctionComponent } from 'react'

const Head: FunctionComponent = () => (
  <thead>
    <tr className="top-jockeys__header top-jockeys__row">
      <th>
        <CustomText
          fontTertiary={true}
          text="Wins-Rides"
          size="smallest"
          noSpace={true}
        />
      </th>
      <th
        className="
        top-jockeys--flex
        top-jockeys--flex-col
        top-jockeys--align-right
        top-jockeys__jockey-trainer-name-header"
      >
        <CustomText
          text="Trainer supplying rides today"
          fontTertiary={true}
          size="smallest"
          noSpace={true}
        />
        <CustomText
          text="giving best course record"
          fontTertiary={true}
          size="smallest"
          noSpace={true}
        />
      </th>
      <th>
        <CustomText
          fontTertiary={true}
          size="smallest"
          noSpace={true}
          text="STAKE"
        />
      </th>
    </tr>
  </thead>
)

export default Head
