import React, { Component } from 'react'
import { MAX_WIDTH } from '@config'
import Column from '@classes/Column'
import GreyOut from '@horses/GreyOut/GreyOut'
import map from 'lodash/map'
import cx from 'classnames'
import './Column.scss'
import AbandonedRaceStore from '@stores/AbandonedRaceStore'
import { observer } from 'mobx-react'

interface IColumnProps {
  column: Column
  columnIndex: number
}

class SingleColumn extends Component<IColumnProps> {
  public componentDidMount() {
    this.props.column.subscribeAllRacesToChanges()
  }
  public render() {
    const { column } = this.props
    return (
      <div
        className={'column__container'}
        style={{
          width: column.columnHeight === 0 ? 0 : MAX_WIDTH * column.columnWidth,
        }}
      >
        {column.hasElements() && (
          <div>
            {map(column.renderElements(), (element, index) => {
              let raceAbandoned = false
              let greyoutCondition = false
              if (element && element.race) {
                raceAbandoned = AbandonedRaceStore.isRaceAbandoned(
                  element.race.getRaceInstanceUid(),
                )
                greyoutCondition = element.race.canGreyOut()
              }
              return (
                <div
                  className={cx('column-elements__container', {
                    'column-elements-greyout__container': greyoutCondition,
                  })}
                  key={index}
                >
                  <GreyOut
                    key={index}
                    raceElements={element}
                    index={index}
                    greyoutCondition={greyoutCondition}
                    raceAbandoned={raceAbandoned}
                  />
                </div>
              )
            })}
          </div>
        )}
        {column.hasTipster() && (
          <div className="column-tipster__container">
            {column.renderTipster()}
          </div>
        )}
        {column.hasHowToGuides() && (
          <div className="column-how-to-guide__container">
            {column.renderHowToGuides()}
          </div>
        )}
        {column.hasAds() && (
          <div className="column-ads__container">{column.renderAds()}</div>
        )}
      </div>
    )
  }
}

export default observer(SingleColumn)
