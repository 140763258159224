import styled from 'styled-components'

const SectionContainer = styled.div`
  font-size: 0.8rem;
  background-color: ${({ theme }) => theme.colors.lightGray};
  padding: 10px;
  margin-left: -7px;
  margin-right: -7px;
  border-top: 0;
`

export { SectionContainer }
