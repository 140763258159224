import React, { FunctionComponent } from 'react'
import { inject, observer } from 'mobx-react'
import styled from 'styled-components'

import PopupStore, { POPUP_WIDTH } from '@stores/PopupStore'
import CustomText from '@components/HorseComponents/CustomText/CustomText'
import { PopupCourseNameHeader } from './Popup.css'
import RunnerPopup from './RunnerPopup/RunnerPopup'
import MostTippedPopup from './MostTippedPopup/MostTippedPopup'
interface IProps {
  popupStore?: PopupStore
}

interface IStyleProps {
  left: number
  top: number | null
  bottom: number | null
  open?: boolean
  meetingColour?: string
}

const PopupWrapperStyles = styled.div<IStyleProps>`
  position: fixed;
  width: ${POPUP_WIDTH}px;
  left: ${({ left }) => left}px;

  ${({ top }) => top && `top: ${top}px;`}
  ${({ bottom }) => bottom && `bottom: ${bottom}px;`}
  border: ${({ meetingColour }) =>
    meetingColour ? `3px solid ${meetingColour}` : 'none'};

  z-index: 1000;
  background: white;

  ::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    filter: blur(7px);
    background: white;
    z-index: -1;
  }

  animation: ${({ open }) => (open ? 'fade-in 0.3s' : 'fade-out 0.3s')};

  @keyframes fade-in {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  @keyframes fade-out {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }
`

const PopupContainer: FunctionComponent<IProps> = ({ popupStore }) => {
  const { popupContents } = popupStore!

  return (
    <>
      {popupContents.map(content => {
        if (content.props.runnerInfo) {
          // Runner popover
          return (
            <PopupWrapperStyles
              key={content.id}
              left={content.left}
              top={content.top}
              open={content.open}
              bottom={content.bottom}
              meetingColour={content.props.meetingColour}
            >
              <PopupCourseNameHeader
                meetingColour={content.props.meetingColour}
              >
                <div>
                  <CustomText
                    text={content.props.runnerInfo.raceTime}
                    proximaCondBold={true}
                    uppercase={false}
                    size="big"
                  />
                  <CustomText
                    text={content.props.runnerInfo.courseName}
                    proximaCondBold={true}
                    uppercase={false}
                    size="big"
                  />
                </div>
              </PopupCourseNameHeader>
              <RunnerPopup
                {...content.props}
                onClose={() => popupStore!.closePopup(content.id)}
                closeAfter={content.closeAfter}
              />
            </PopupWrapperStyles>
          )
        } else {
          // Newspaper popover
          return (
            <PopupWrapperStyles
              key={content.id}
              left={content.left}
              top={content.top}
              open={content.open}
              bottom={content.bottom}
            >
              <MostTippedPopup
                {...content.props}
                onClose={() => popupStore!.closePopup(content.id)}
                closeAfter={content.closeAfter}
              />
            </PopupWrapperStyles>
          )
        }
      })}
    </>
  )
}

export default inject('popupStore')(observer(PopupContainer))
