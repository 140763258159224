import { theme } from 'rp-components'

const headerBaseHeight = 100
const infoBarBaseHeight = 100
const marginColumnTop = 15
const ghAnimationBase = 500
const ghAnimationSizeBase = 650

export default {
  headerHeight: headerBaseHeight + 'px',
  infoBarHeight: infoBarBaseHeight + 'px',
  infoAndHeader: headerBaseHeight + infoBarBaseHeight + marginColumnTop + 'px',
  jsHeaderAndInfoBar: headerBaseHeight + infoBarBaseHeight + marginColumnTop,
  spacingExtraSmall: '5px',
  spacingSmall: '10px',
  spacingMedium: '15px',
  spacing: '20px',
  spacingBig: '40px',
  ghAnimationTime: ghAnimationBase + 'ms',
  ghAnimationWidth: ghAnimationSizeBase + 'px',

  colors: {
    backgroundColor: '#fff',
    primary: theme.colors.primary,
    lightGray: 'lightgray',
    headerColor: '#0f5b31',
    titleColor: '#002699',
    borderColor: '#000',
    white: '#fff',
    black: '#000',
    grey: 'grey',
    red: 'red',
    green: '#2bc500',
    highlight: '#f0eff1',
    ghBlue: '#60abdd',
    ghLightBlue: '#859ecd',
    williamHillBlue: '#041338',
    coralBlue: '#004696',
    cardUnderlineColor: '#c9c9c9',
    oddBoxRed: '#d11f25',
    tipping: '#fbf1f1',
    mostTippedHeaderHighlight: '#eeeeee',
    papersHighlightColor: '#f4f4f4',
  },

  fonts: {
    primaryFont: 'Proxima Nova, Georgia, Times New Roman, Times, serif',
    secondaryFont: 'Swis721BT, arial',
    tertiaryFont: 'Proxima Nova Cond, arial',

    // Alternatives
    proximaBold: 'Proxima Nova Bold',
    proximaBoldItalic: 'Proxima Nova Bold Italic',
    proximaCondBlack: 'Proxima Nova Cond Black',
    proximaCondExtraBold: 'Proxima Nova Cond Extra Bold',
    swisBlack: 'Swis721BT Black',
    timesRoman: 'Times New Roman',
    proximaCondSemiBold: 'Proxima Nova Cond Semi Bold',
    proximaCondSemiBoldIt: 'Proxima Nova Cond Semi Bold Italic',
    proximaCondMedium: 'Proxima Nova Cond Medium',
    proximaMedium: 'Proxima Nova Medium',
    proximaCondBold: 'Proxima Nova Cond Bold',
    proximaExtraBold: 'Proxima Nova Extra Bold',
    proximaRegular: 'Proxima Nova Regular',
    proximaCondRegular: 'Proxima Nova Cond Regular',
    proximaSemiBold: 'Proxima Nova Semi Bold',
  },
}
