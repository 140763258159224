import { observable } from 'mobx'
import DiffusionSocket from '@classes/Socket'
import get from 'lodash/get'
import forEach from 'lodash/forEach'

import { STATUS_URL } from '@config'
import Race from '@classes/Race'
import { differenceInMinutes } from 'date-fns'
import { Status } from 'rp-diffusion'
import axios from 'axios'
export interface IHorseStatuses {
  [id: string]: Status | null
}

interface IRaces {
  [key: string]: Race
}

class HorseStatusStore {
  @observable public statuses: IHorseStatuses = {}
  public races: IRaces = {}
  constructor() {
    DiffusionSocket.addStatusConnection({ on: this.onStatus })
  }

  public getStatusForRace = (id: string) => {
    const status = get(this.statuses, id)
    return status || null
  }

  public registerRace = (race: Race) => {
    this.races[race.getStatusId()] = race
    this.createOffSignal(race, race.getStatusId(), new Date())
  }

  public getInitialStatuses = async () => {
    try {
      const response = await axios.get(`${STATUS_URL}/horses`)
      const json = await response.data

      if (json && json.data && json.data.length) {
        json.data.forEach((status: Status) => {
          this.onStatus(status)
        })
      }
    } catch (error) {
      throw error
    }
  }

  public checkTimes = () => {
    const now = new Date()
    forEach(this.races, (race, id) => {
      this.createOffSignal(race, id, now)
    })
  }

  private createOffSignal = (race: Race, id: string, now: Date) => {
    const [date, course, time] = id.split('/')
    const datetime = race.getRaceDateObject()
    const wasFiveMinutesAgo = differenceInMinutes(now, datetime) >= 5
    if (wasFiveMinutesAgo) {
      this.statuses[id] = {
        id,
        sisMessage: 'Off',
        progressCode: 'O',
        sport: 'HORSES',
        raceTime: time,
        date,
        course_name: course,
        offTime: '',
        PMSG: '',
        resultSettlingStatus: '',
        abandoned: '',
        eventStatus: '',
        resultPosition: '',
      }
    }
  }

  private onStatus = (status: Status) => {
    const id = get(status, 'id', null)
    const PMSG = get(status, 'PMSG', null)
    const pmsgIsFinalResult = PMSG === 'FINAL RESULT'
    if (
      (id && status.progressCode) ||
      (id && pmsgIsFinalResult && status.progressCode)
    ) {
      let newStatus = status
      if (pmsgIsFinalResult) {
        newStatus = { ...status, sisMessage: 'Finished', progressCode: 'O' }
      }
      this.statuses[id] = newStatus
    }
  }
}

export default HorseStatusStore
