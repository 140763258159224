import React, { FunctionComponent } from 'react'
import { ILeftColumn } from '../RaceCardInterfaces/IHorseInfo'
import Arrow from './Arrow'
import CustomText from '@horses/CustomText/CustomText'
import cx from 'classnames'

const LeftColumn: FunctionComponent<ILeftColumn> = ({
  startNumber,
  draw,
  runnerForm,
  postMark,
  ratingImprover,
  ratingHigh,
  saddleClothNumber,
}) => {
  return (
    <div className="race-card--flex-col race-card__start-draw">
      {
        <div className="race-card--min-height race-card--align-right">
          {runnerForm && (
            <span
              className="race-card--font-med"
              dangerouslySetInnerHTML={runnerForm}
            />
          )}
        </div>
      }
      <div className="race-card--flex">
        <CustomText
          text={startNumber || saddleClothNumber || ''}
          proximaCondBold={true}
          bold={true}
          size="big"
        />
        {!!draw && <CustomText brackets={true} text={draw} size="small" />}
      </div>

      <div className="race-card--flex race-card--flex-between race-card--flex-align-start">
        {postMark && (
          <div
            className={cx('race-card__rating', {
              'race-card__rating--improved': ratingHigh,
            })}
          >
            {ratingImprover && <Arrow />}
            <CustomText
              colour={ratingHigh ? 'white' : 'black'}
              text={postMark}
              proximaCondSemiBold={true}
              size="small"
              noSpace={true}
            />
          </div>
        )}
      </div>
    </div>
  )
}

export default LeftColumn
