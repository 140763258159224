import { observable, action } from 'mobx'
import { Hook, Decode } from 'console-feed'
import { logger } from '@utils/utils';

interface ILog {
  data: string[],
  id: string
  method: string
}

class DebugStore {
  @observable public enabled: boolean = false
  @observable public logs: ILog[] = []

  constructor(enabled: boolean) {
    this.setEnabled(enabled)
    if (enabled) {
      this.startHook()
    }
  }

  public log = (log: string) => {
    if (this.enabled) {
      logger(log)
    }
  }

  private startHook = () => {
    Hook(window.console, (log: ILog) => {
      const decodedLog = Decode(log)
      this.addLog(decodedLog)
    })
  }

  @action
  private addLog = (log: ILog) => {
    this.logs.push(log)
  }

  @action
  private setEnabled = (enabled: boolean) => {
    this.enabled = enabled
  }
}

export default DebugStore
