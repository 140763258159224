import { observable, action } from 'mobx'

interface IWeatherMap {
  [meetingId: number]: IWeather
}

export interface IWeather {
  temperature: number
  icon: string
}

class WeatherStore {
  @observable private weatherMap: IWeatherMap = {}

  @action
  public setWeatherForMeeting = (meetingId: number, weather: IWeather) =>
    (this.weatherMap[meetingId] = weather)

  public getWeatherForMeeting = (meetingId: number) =>
    this.weatherMap[meetingId]
}

export default new WeatherStore()
