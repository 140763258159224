import styled from 'styled-components'

export const InfoBarContainer = styled.div`
  align-items: center;
  display: flex;
  height: ${({ theme }) => theme.infoBarHeight};
  justify-content: space-between;
  padding: 0 26px;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  img {
    height: 60px;
  }
`

export const InfoBarPriceGuidance = styled.div`
  font-size: 20px;
  text-align: right;
`

export const InfoBarDate = styled.div`
  font-family: ${({ theme }) => theme.fonts.proximaBold};
  font-size: 50px;
  display: flex;
  align-items: center;
  margin-left: auto;
`
