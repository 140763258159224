import React, { FunctionComponent } from 'react'
import { IDebugger } from './IDebugger'
import { toJS } from 'mobx'
import { inject, observer } from 'mobx-react'
import { Console } from 'console-feed'

import './Debugger.scss'

// Have to use toJS with this Console component.
const Debugger: FunctionComponent<IDebugger> = ({ debugStore }) =>
  (debugStore && debugStore.enabled) ? (
    <div className="console-debug">
      <Console logs={toJS(debugStore.logs)} variant="dark" />
    </div>
  ) : null

export default inject('debugStore')(observer(Debugger))
