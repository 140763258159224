import React, { FunctionComponent, Fragment } from "react";

import DevTools from "@horses/DevTools/DevTools"
import Debugger from "@horses/Debugger/Debugger"

const DebugDevTools: FunctionComponent<{}> = () => (
  <Fragment>
    <DevTools />
    <Debugger />
  </Fragment>
)

export default DebugDevTools
