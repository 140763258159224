import h240_3 from '@assets/guides/h240_3.png'
import h250_4 from '@assets/guides/h250_4.png'
import h250_13 from '@assets/guides/h250_13.png'
import h250_17 from '@assets/guides/h250_17.png'
import h250_18 from '@assets/guides/h250_18.png'
import h250_20 from '@assets/guides/h250_20.png'
import h270_7 from '@assets/guides/h270_7.png'
import h270_8 from '@assets/guides/h270_8.png'
import h270_10 from '@assets/guides/h270_10.png'
import h270_12 from '@assets/guides/h270_12.png'
import h300_2 from '@assets/guides/h300_2.png'
import h300_21 from '@assets/guides/h300_21.png'
import h320_5 from '@assets/guides/h320_5.png'
import h320_6 from '@assets/guides/h320_6.png'
import h320_9 from '@assets/guides/h320_9.png'
import h320_16 from '@assets/guides/h320_16.png'
import h350_14 from '@assets/guides/h350_14.png'
import h360_11 from '@assets/guides/h360_11.png'
import h420_15 from '@assets/guides/h420_15.png'
import h450_1 from '@assets/guides/h450_1.png'
import h510_19 from '@assets/guides/h510_19.png'

import get from 'lodash/get'
import sample from 'lodash/sample'
import keys from 'lodash/keys'
import reduce from 'lodash/reduce'
import filter from 'lodash/filter'
import includes from 'lodash/includes'

interface ILadbIreAds {
  src: string
  type: string
}

interface IImages {
  [key: string]: IImageGroup
}

interface IImageGroup {
  [key: string]: IImageSet
}

interface IImageSet {
  [key: number]: string | ILadbIreAds
}
interface IImageSrc {
  imageSrc: string
  px: number
  type?: string
}

// TILL WE GET CMS WE NEED TO ADD ADS VIA THESE OPTIONS
// ADS WILL BE DELETED PER OPTION GROUP ONCE AN AD HAS BEEN CHOSEN TO
// REMOVE DUPLICATES TYPES BEING DISPLAYED. - REMOVED THIS FEATURE FOR GALWAY â˜˜ï¸
let srcAtIndex: { [index: number]: string[] } = {}

export const images: IImages = {
  defaultSet: {
    270: [{ type: '3', src: h240_3 }],
    280: [
      { type: '3', src: h240_3 },
      { type: '4', src: h250_4 },
      { type: '13', src: h250_13 },
      { type: '17', src: h250_17 },
      { type: '18', src: h250_18 },
      { type: '20', src: h250_20 },
    ],
    300: [
      { type: '3', src: h240_3 },
      { type: '4', src: h250_4 },
      { type: '7', src: h270_7 },
      { type: '8', src: h270_8 },
      { type: '10', src: h270_10 },
      { type: '12', src: h270_12 },
      { type: '13', src: h250_13 },
      { type: '17', src: h250_17 },
      { type: '18', src: h250_18 },
      { type: '20', src: h250_20 },
    ],
    330: [
      { type: '2', src: h300_2 },
      { type: '3', src: h240_3 },
      { type: '4', src: h250_4 },
      { type: '7', src: h270_7 },
      { type: '8', src: h270_8 },
      { type: '10', src: h270_10 },
      { type: '12', src: h270_12 },
      { type: '13', src: h250_13 },
      { type: '17', src: h250_17 },
      { type: '18', src: h250_18 },
      { type: '20', src: h250_20 },
    ],
    350: [
      { type: '2', src: h300_2 },
      { type: '3', src: h240_3 },
      { type: '4', src: h250_4 },
      { type: '5', src: h320_5 },
      { type: '6', src: h320_6 },
      { type: '7', src: h270_7 },
      { type: '8', src: h270_8 },
      { type: '9', src: h320_9 },
      { type: '10', src: h270_10 },
      { type: '12', src: h270_12 },
      { type: '13', src: h250_13 },
      { type: '17', src: h250_17 },
      { type: '18', src: h250_18 },
      { type: '20', src: h250_20 },
      { type: '21', src: h300_21 },
    ],
    380: [
      { type: '2', src: h300_2 },
      { type: '3', src: h240_3 },
      { type: '4', src: h250_4 },
      { type: '5', src: h320_5 },
      { type: '6', src: h320_6 },
      { type: '7', src: h270_7 },
      { type: '8', src: h270_8 },
      { type: '9', src: h320_9 },
      { type: '10', src: h270_10 },
      { type: '12', src: h270_12 },
      { type: '13', src: h250_13 },
      { type: '16', src: h320_16 },
      { type: '17', src: h250_17 },
      { type: '18', src: h250_18 },
      { type: '20', src: h250_20 },
      { type: '21', src: h300_21 },
    ],
    390: [
      { type: '2', src: h300_2 },
      { type: '3', src: h240_3 },
      { type: '4', src: h250_4 },
      { type: '5', src: h320_5 },
      { type: '6', src: h320_6 },
      { type: '7', src: h270_7 },
      { type: '8', src: h270_8 },
      { type: '9', src: h320_9 },
      { type: '10', src: h270_10 },
      { type: '12', src: h270_12 },
      { type: '13', src: h250_13 },
      { type: '14', src: h350_14 },
      { type: '16', src: h320_16 },
      { type: '17', src: h250_17 },
      { type: '18', src: h250_18 },
      { type: '20', src: h250_20 },
      { type: '21', src: h300_21 },
    ],
    450: [
      { type: '2', src: h300_2 },
      { type: '3', src: h240_3 },
      { type: '4', src: h250_4 },
      { type: '5', src: h320_5 },
      { type: '6', src: h320_6 },
      { type: '7', src: h270_7 },
      { type: '8', src: h270_8 },
      { type: '9', src: h320_9 },
      { type: '10', src: h270_10 },
      { type: '11', src: h360_11 },
      { type: '12', src: h270_12 },
      { type: '13', src: h250_13 },
      { type: '14', src: h350_14 },
      { type: '16', src: h320_16 },
      { type: '17', src: h250_17 },
      { type: '18', src: h250_18 },
      { type: '20', src: h250_20 },
      { type: '21', src: h300_21 },
    ],
    480: [
      { type: '1', src: h450_1 },
      { type: '2', src: h300_2 },
      { type: '3', src: h240_3 },
      { type: '4', src: h250_4 },
      { type: '5', src: h320_5 },
      { type: '6', src: h320_6 },
      { type: '7', src: h270_7 },
      { type: '8', src: h270_8 },
      { type: '9', src: h320_9 },
      { type: '10', src: h270_10 },
      { type: '11', src: h360_11 },
      { type: '12', src: h270_12 },
      { type: '13', src: h250_13 },
      { type: '14', src: h350_14 },
      { type: '15', src: h420_15 },
      { type: '16', src: h320_16 },
      { type: '17', src: h250_17 },
      { type: '18', src: h250_18 },
      { type: '20', src: h250_20 },
      { type: '21', src: h300_21 },
    ],
    540: [
      { type: '1', src: h450_1 },
      { type: '2', src: h300_2 },
      { type: '3', src: h240_3 },
      { type: '4', src: h250_4 },
      { type: '5', src: h320_5 },
      { type: '6', src: h320_6 },
      { type: '7', src: h270_7 },
      { type: '8', src: h270_8 },
      { type: '9', src: h320_9 },
      { type: '10', src: h270_10 },
      { type: '11', src: h360_11 },
      { type: '12', src: h270_12 },
      { type: '13', src: h250_13 },
      { type: '14', src: h350_14 },
      { type: '15', src: h420_15 },
      { type: '16', src: h320_16 },
      { type: '17', src: h250_17 },
      { type: '18', src: h250_18 },
      { type: '19', src: h510_19 },
      { type: '20', src: h250_20 },
      { type: '21', src: h300_21 },
    ],
  },
}

export const roundDown = (height: number): number =>
  height ? Number(Math.floor(height / 100)) * 100 : 0

export const createImage = (
  px: number,
  bookie: string = 'defaultSet',
  index: number,
): IImageSrc | null => {
  const bookieSet = get(images, bookie, images.defaultSet)
  let imageToSend = null
  const imageHeightsAvailable = keys(bookieSet)
  const heightThatWillFit = reduce(
    imageHeightsAvailable,
    (acc, pxHeight) => {
      const adHeightAsNumber = Number(pxHeight)
      if (adHeightAsNumber <= px && adHeightAsNumber > acc) {
        acc = adHeightAsNumber
      }
      return acc
    },
    0,
  )
  const imageArray = get(bookieSet, heightThatWillFit, null) as ILadbIreAds[]
  const allUsedTypes = srcAtIndex[index]
  const filteredOutUsedGuides = filter(
    imageArray,
    img => !includes(allUsedTypes, img.type),
  )
  const imageSrc = sample(filteredOutUsedGuides)

  if (imageSrc) {
    imageToSend = {
      imageSrc: imageSrc.src,
      px: heightThatWillFit,
      type: imageSrc.type,
    }
  }
  return imageToSend as { imageSrc: string; px: number; type?: string } | null
}

export const resetHowToGuides = () => {
  srcAtIndex = {}
}

const getHowToGuide = (height: number, bookie: string, index: number) => {
  const roundedPX = roundDown(height)
  const image = createImage(roundedPX, bookie, index)

  if (!srcAtIndex[index] && image && image.type) {
    srcAtIndex[index] = [image.type]
  } else if (image && image.type) {
    srcAtIndex[index].push(image.type)
  }
  return image
}

export default getHowToGuide
