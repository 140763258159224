import React, { FunctionComponent } from 'react'
import { observer } from 'mobx-react'
import Race from '@classes/Race'
import GreyoutRP from '@utils/GreyoutRP'
import BettingForecast from '../BettingForecast'
import RaceCardFooter from '../RaceCardFooter'
import AbandonedRaceStore from '@stores/AbandonedRaceStore'

interface IFooterAndBettingForecastProps {
  race: Race
  showSpotlights: boolean
}

const FooterAndBettingForecast: FunctionComponent<IFooterAndBettingForecastProps> = ({
  race,
}) => (
  <GreyoutRP
    greyoutCondition={
      race.hasRaceFinished() ||
      race.raceHasResults() ||
      AbandonedRaceStore.isRaceAbandoned(race.getRaceInstanceUid())
    }
  >
    <RaceCardFooter data={race.getPastWinners()} />
    <BettingForecast
      data={race.getPastWinners()}
      forecast={race.getBettingForecast()}
    />
  </GreyoutRP>
)

export default observer(FooterAndBettingForecast)
