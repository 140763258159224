import React, { Fragment, Component } from 'react'
import { func, number, bool, object } from 'prop-types'
import { inject } from 'mobx-react'
import map from 'lodash/map'
import debounce from 'lodash/debounce'
import forEachRight from 'lodash/forEachRight'
import uuid from 'uuid/v4'
import Columns from '@classes/Columns'
import {
  MAX_COLS_PER_SCREEN,
  MAX_WIDTH_PERCENTAGE,
  MAX_SCREEN_SIZE,
} from '@config'
import Column from '@classes/Column'
import get from 'lodash/get'
import includes from 'lodash/includes'
import some from 'lodash/some'
import isEmpty from 'lodash/isEmpty'

const containerStyle = {
  width: `${MAX_WIDTH_PERCENTAGE}vw`,
  padding: '13px',
  borderRight: '1px solid black',
  display: 'flex',
  flexDirection: 'column',
}

const DEBOUNCE_TIME = 200

class InitialMeasure extends Component {
  constructor(props) {
    super(props)

    this.props.debugStore.log('Started Measurement Component')
    this.initColumns()
    this.initMeetingQueue(props.meetingsQueue)
    this.state = {
      render: true,
      renderMeetings: false,
    }

    this.props.debugStore.log('Started Rendering Race Components')

    this.rr = true
    this.rm = false
    this.screensSent = false

    // store meeting elements (stats) so they can be added again later if removed
    this.meetingElements = {}
  }

  initMeetingQueue = meetingsQueue => {
    this.props.debugStore.log('init meeting queue')
    this.meetingQueue = meetingsQueue

    this.meetingQueue.orderMeetingsIntoSets()

    this.sets = this.meetingQueue.getSets()
    this.activeSet = this.sets.getActiveSet()
    this.activeMeeting = this.activeSet.getActiveMeeting()

    if (this.activeMeeting) {
      this.activeMeetingRaces = this.activeMeeting.getRaces()
    }
  }

  initPriorityQueues = () => {
    this.props.debugStore.log('Init Priority Queue')
  }

  initColumns = () => {
    this.props.debugStore.log('Init Columns')

    const { ofScreen } = this.props
    this.screenOptions = {
      maxColsPerScreen: MAX_COLS_PER_SCREEN,
      maxScreens: ofScreen,
    }

    this.props.loadingStore.setColsToHit(MAX_COLS_PER_SCREEN * ofScreen)

    if (this.props.enableTipster) {
      this.tipsterColumn = new Column({
        noHeader: false,
        columnHeight: 0,
        meeting: null,
        freeSpace: MAX_SCREEN_SIZE,
        course: 'Tipster',
        columnWidth: 1,
      })

      this.tipsterColumn.addTipster()

      this.columns = new Columns({
        columns: [this.tipsterColumn],
        screenOptions: this.screenOptions,
      })
    } else {
      this.columns = new Columns({
        columns: [],
        screenOptions: this.screenOptions,
      })
    }

    this.activeColumns = this.columns
  }

  reRender = () => {
    if (this.state.render) {
      this.forceUpdate()
    }
  }

  /**
   *@function createComponent - Returns new React component with realRender prop for use in render.
   *@param component {ComponentElement} - React Component
   *@param props {Object} - Component Props
   */
  createComponent = (component, props) =>
    props && component
      ? React.createElement(component, { ...props, realRender: true })
      : false

  getNodeHeight = node => {
    const height = node.offsetHeight
    const style = getComputedStyle(node)
    return height + parseInt(style.marginTop) + parseInt(style.marginBottom)
  }

  getRaceElementHeight = (options, columnOptions) => {
    if (this.screensSent) {
      return false
    }
    const { race, node, component, props, meeting } = options

    const course = meeting.getCourseName()
    const time = race ? race.getRaceTime() : null
    const raceInstanceUid = race ? race.getRaceInstanceUid() : null
    const raceId = `${time}/${raceInstanceUid}`

    if (course && node && component && props) {
      const height = this.getNodeHeight(node)

      if (height >= MAX_SCREEN_SIZE || height === 0) {
        return
      }

      const element = {
        element: component,
        height,
        course,
        time,
        raceId,
        race,
        props,
      }

      this.sortColumns(element, options, columnOptions, () =>
        this.getRaceElementHeight(options, columnOptions),
      )
    }

    this.onLastRace_()
  }

  getMeetingElementHeight = options => {
    if (this.screensSent) {
      return false
    }
    const { meeting, node, component, props, name } = options
    const course = meeting.getCourseName()

    if (course && node && component && props) {
      const height = this.getNodeHeight(node)

      if (height >= MAX_SCREEN_SIZE || height === 0) {
        return
      }

      const element = {
        element: component,
        height,
        course,
        raceId: uuid(),
        time: null,
        props,
      }

      this.meetingElements[course]
        ? (this.meetingElements[course][name] = element)
        : (this.meetingElements[course] = { [name]: element })

      this.sortColumns(
        element,
        options,
        { mainComponent: true, isMeetingComponent: true },
        () => this.getMeetingElementHeight(options),
      )
    }
    this.onLastMeeting_()
  }

  sortColumns = (element, options, columnOptions = {}, callback = () => {}) => {
    const { course, height, raceId } = element
    const { meeting, componentName } = options
    const { mainComponent } = columnOptions
    // If the main component has not been added, then dont add the rest.
    if (!this.activeColumns.hasMainComponentBeenAdded(raceId, mainComponent)) {
      return false
    }

    if (this.activeColumns.isEmpty()) {
      this.activeColumns.addNewColumn(
        {
          elements: [],
          freeSpace: MAX_SCREEN_SIZE,
          columnWidth: 1,
          columnHeight: 0,
          freeColumn: false,
          meeting,
          course,
          id: uuid(),
        },
        true,
      )
    }

    if (this.activeColumns.canAddElement(height, course)) {
      this.activeColumns.addElement(element, mainComponent, componentName)
    } else {
      this.activeColumns.addNewColumn(
        {
          elements: {},
          freeSpace: MAX_SCREEN_SIZE,
          columnWidth: 1,
          columnHeight: 0,
          freeColumn: false,
          course,
          meeting,
          id: uuid(),
        },
        true,
      )
      this.activeColumns.incrementActiveColumnIndex()
      this.props.loadingStore.setAmountOfCols(this.activeColumns.columns.length)
      callback(options, columnOptions)
    }
  }

  setNextMeeting = () => {
    this.activeSet.incrementActiveMeetingIndex()
    this.activeMeeting = this.activeSet.getActiveMeeting()
    this.activeMeetingRaces = this.activeMeeting.getRaces()
  }

  setNextSet = () => {
    this.sets.incrementActiveSetIndex()
    this.activeSet = this.sets.getActiveSet()
    this.activeMeeting = this.activeSet.getActiveMeeting()
    this.activeMeetingRaces = this.activeMeeting.getRaces()
  }

  onLast = () => {
    const isScreenCountHit = this.columns.haveHitScreenCount()
    if (!isScreenCountHit) {
      this.sendScreens()
    } else {
      this.removal()
    }
  }

  removal = () => {
    const allStatsRemoved = this.sets.haveAllStatsHaveBeenRemoved()
    const allSetsAreDiomed = this.sets.allSetsHaveBeenDiomed()
    const lastSetSpotlightsRemoved = this.sets.hasLastSetSpotlightsRemoved()
    const lastSetSelectionBoxesRemoved = this.sets.hasLastSetSelectionBoxesRemoved()
    const lastSetVerdictsRemoved = this.sets.hasLastSetVerdictsRemoved()
    const lastSetTipsRemoved = this.sets.hasLastSetTipsBeenRemoved()
    const firstSetHadAllSpotligtsRemoved = this.sets.hasFirstSetSpotlightsRemoved()
    const allRacesFromLastSetRemoved = this.sets.hasLastSetHadAllRacesRemoved()
    const allRacesFromFirstSetRemoved = this.sets.hasFirstSetHadAllRacesRemoved()
    if (!allStatsRemoved) {
      this.props.debugStore.log('removeStats')
      this.props.loadingStore.setCurrentStep('Removing All Course Stats')
      this.sets.removeStats()
    } else if (!allSetsAreDiomed) {
      this.props.debugStore.log('diomeSpotlights')
      this.props.loadingStore.setCurrentStep('Setting Diomed Spotlights')
      this.sets.diomeSpotlights()
    } else if (!lastSetSpotlightsRemoved) {
      this.props.debugStore.log('removeLastSetSpotlights')
      this.props.loadingStore.setCurrentStep(
        'Removing Spotlights From Last Set',
      )
      this.sets.removeLastSetSpotlights()
    } else if (!lastSetSelectionBoxesRemoved) {
      this.props.debugStore.log('removeLastSetSelectionBoxes')
      this.props.loadingStore.setCurrentStep(
        'Remove Selection Boxes From Last Set',
      )
      this.sets.removeLastSetSelectionBoxes()
    } else if (!lastSetTipsRemoved) {
      this.props.debugStore.log('removeTips')
      this.props.loadingStore.setCurrentStep('Removing Tips From Last Set')
      this.sets.removeTipsFromLastSet()
    } else if (!lastSetVerdictsRemoved) {
      this.props.debugStore.log('removeLastSetVerdicts')
      this.props.loadingStore.setCurrentStep('Removing Verdicts From Last Set')
      this.sets.removeLastSetVerdicts()
    } else if (!allRacesFromLastSetRemoved) {
      this.props.debugStore.log('removeRaceFromLastSet')
      this.props.loadingStore.setCurrentStep('Removing Races From Last Set')
      this.sets.removeRaceFromLastSet()
    } else if (!firstSetHadAllSpotligtsRemoved) {
      this.props.debugStore.log('removeFirstSetSpotlights')
      this.props.loadingStore.setCurrentStep(
        'Removing Spotlights From First Set',
      )
      this.sets.removeFirstSetSpotlights()
    } else if (!allRacesFromFirstSetRemoved) {
      this.props.debugStore.log('removeRaceFromFirstSet')
      this.props.loadingStore.setCurrentStep('Removing Races From First Set')
      this.sets.removeRaceFromFirstSet()
    }
    this.reset()
    this.setRacesToRender()
  }

  onLastRace_ = debounce(() => {
    if (this.rr && !this.rm) this.setMeetingsToRender()
  }, DEBOUNCE_TIME)

  onLastMeeting_ = debounce(() => {
    if (!this.rr && this.rm) {
      const isLastMeeting = this.activeSet.isLastMeeting()
      const isLastSet = this.sets.isLastSet()
      const isLast = isLastSet && isLastMeeting

      if (!isLastMeeting) {
        this.setNextMeeting()
        this.setRacesToRender()
      } else if (!isLastSet) {
        this.setNextSet()
        this.setRacesToRender()
      } else if (isLast) {
        this.onLast()
      }
    }
  }, DEBOUNCE_TIME)

  setRacesToRender = () => {
    this.rr = true
    this.rm = false
    this.reRender()
  }

  setMeetingsToRender = () => {
    this.rr = false
    this.rm = true
    this.reRender()
  }

  reset = () => {
    this.sets.reset()
    this.activeSet = this.sets.getActiveSet()
    this.activeMeeting = this.activeSet.getActiveMeeting()
    this.activeMeetingRaces = this.activeMeeting
      ? this.activeMeeting.getRaces()
      : null
    this.initColumns()
  }

  sendScreens = () => {
    if (this.state.render && !this.screensSent) {
      this.props.debugStore.log(
        'No More Meeting Components, chunking columns to screens.',
      )
      this.screensSent = true
      this.setState(
        {
          render: false,
        },
        () => {
          if (this.sets.haveAllStatsHaveBeenRemoved()) {
            const columns = this.activeColumns.getColumns()
            // add back in stats for meetings
            // preserve order and track whats on each course
            const meetingStats = [
              { name: 'Favourites', onCourse: [] },
              { name: 'TopTrainers', onCourse: [] },
              { name: 'TopJockeys', onCourse: [] },
              { name: 'TopDraw', onCourse: [] },
              { name: 'TravellersCheck', onCourse: [] },
              { name: 'TopSpeed', onCourse: [] },
              { name: 'TenYear', onCourse: [] },
              { name: 'CourseInfo', onCourse: [] },
            ]

            const attemptToAddAllStats = () =>
              forEachRight(columns, col => {
                meetingStats.forEach((meetingStat, index) => {
                  // only add if we've not already added
                  if (!includes(meetingStat.onCourse, col.course)) {
                    const element = get(this.meetingElements, [
                      col.course,
                      meetingStat.name,
                    ])

                    // only add if fits
                    if (
                      element &&
                      col.canAddElement(get(element, 'height'), col.course)
                    ) {
                      col.addElement(element)

                      // add to whats already been added
                      meetingStats[index].onCourse.push(col.course)
                    }
                  }
                })
              })
            attemptToAddAllStats()
            // if we haven't managed to fit all the stats on in the right place then try one more time
            if (
              some(
                Object.keys(this.meetingElements).map(
                  key => !isEmpty(this.meetingElements[key]),
                ),
              )
            ) {
              this.sets.removeSecondCourseMap()

              attemptToAddAllStats()
            }
            this.activeColumns.setColumns(columns)
          }

          const screens = this.activeColumns.chunkColumnsToScreens()
          const removedRaces = this.sets.getAllRemovedRaces()

          this.columns = null
          this.sets = null
          this.activeSet = null
          this.activeMeeting = null
          this.activeMeetingRaces = null
          this.activeColumns = null
          this.meetingElements = null

          this.props.onMeasure(screens, removedRaces)
        },
      )
    }
  }

  renderRaces = () => {
    const courseName = this.activeMeeting.getCourseName()
    const meetingAbandoned = this.activeMeeting.isAbandoned()
    const meetingColour = this.activeMeeting.getMeetingColour()
    const raceComponentQueue = this.activeMeeting.getRaceComponentQueue()
    const lastSetSelectionBoxesRemoved = this.sets.hasLastSetSelectionBoxesRemoved()
    const isLastSet = this.sets.isLastSet()
    const raceQueue = map(this.activeMeetingRaces, (race, raceIndex) => {
      const raceId = race.getRaceInstanceUid()
      // need to change key to something more suitable.
      const raceComponents = map(
        raceComponentQueue,
        ({
          component: View,
          componentName,
          props,
          renderIfAbandoned = false,
          mainComponent = false,
        }) =>
          (meetingAbandoned && renderIfAbandoned) || !meetingAbandoned ? (
            <View
              key={raceIndex + Math.random()}
              {...props(
                options =>
                  this.getRaceElementHeight(options, {
                    mainComponent,
                    componentName,
                  }),
                {
                  race,
                  raceIndex,
                  raceId,
                  meetingColour,
                  meeting: this.activeMeeting,
                  meetingAbandoned,
                  lastSetSelectionBoxesRemoved,
                  isLastSet,
                  courseName,
                },
              )}
            />
          ) : null,
      )
      return raceComponents
    })

    if (this.activeMeeting.hasRaces()) {
      return raceQueue
    }

    this.onLastRace_()
  }

  renderMeetings = () => {
    const meetingColour = this.activeMeeting.getMeetingColour()
    const courseName = this.activeMeeting.getCourseName()
    const meetingAbandoned = this.activeMeeting.isAbandoned()
    const lastSetSelectionBoxesRemoved = this.sets.hasLastSetSelectionBoxesRemoved()

    if (meetingAbandoned) {
      this.onLastMeeting_()
      return false
    }

    const meetingComponentQueue = this.activeMeeting.getMeetingComponentQueue()

    const showStats = () => {
      const acceptedCountries = ['GB', 'IRE', 'SAF', 'UAE']
      return acceptedCountries.includes(this.activeMeeting.meeting.country_code)
    }

    const meetingComponents = showStats()
      ? map(meetingComponentQueue, ({ component: View, props }) => (
          <View
            {...props(this.getMeetingElementHeight, {
              meeting: this.activeMeeting,
              meetingColour,
              courseName,
              lastSetSelectionBoxesRemoved,
            })}
            key={courseName + Math.random()}
          />
        ))
      : null

    if (meetingComponents && meetingComponents.length) {
      return meetingComponents
    }

    this.onLastMeeting_()
  }

  render() {
    return (
      <div style={{ position: 'fixed', left: -999999 }}>
        {this.state.render && (
          <Fragment>
            {this.rr && <div style={containerStyle}>{this.renderRaces()}</div>}
            {this.rm && (
              <div style={containerStyle}>{this.renderMeetings()}</div>
            )}
          </Fragment>
        )}
      </div>
    )
  }
}

InitialMeasure.propTypes = {
  meetingsQueue: object.isRequired,
  onMeasure: func.isRequired,
  raceComponentPriority: func.isRequired,
  meetingInfoPriority: func.isRequired,
  ofScreen: number.isRequired,
  loadingStore: object.isRequired,
  enableTipster: bool,
}

export default inject('debugStore')(InitialMeasure)
