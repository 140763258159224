function pair<T>(array: T[]): T[][] {
  const pairs = []
  const arrayCopy = [...array]

  const firstTwo = arrayCopy.splice(0, 2)
  const lastTwo = arrayCopy.splice(arrayCopy.length - 2, 2)

  pairs.push(firstTwo)

  arrayCopy.forEach(item => {
    pairs.push([item])
  })

  pairs.push(lastTwo)

  return pairs
}

export default pair
