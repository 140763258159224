import {
  normalisePrice,
  fractionalAsDecimalForComparisonOnly,
} from '@utils/utils'
import DiffusionSocket, { IOddsConnection } from '@classes/Socket'
import get from 'lodash/get'
import last from 'lodash/last'
import forEach from 'lodash/forEach'
import { observable } from 'mobx'
import Race from '@classes/Race'
import { Odd } from 'rp-diffusion'

interface IHorsePriceStoreConfig {
  target: string
  showPrices: boolean
  bookmakerId: string
}

interface IRunnerPrice {
  odds: string[]
  favourite: boolean
}

export interface IRunnerPrices {
  [id: string]: IRunnerPrice
}

interface IRacePrices {
  [key: string]: IRunnerPrices
}

interface ICachedOdds {
  [runnerId: string]: Odd
}

interface IRaces {
  [priceId: string]: Race
}

class HorsePriceStore {
  @observable public racePrices: IRacePrices = {}
  private races: IRaces | null = null
  private connectionAdded: boolean = false
  private showPrices: boolean = false
  private cachedOdds: ICachedOdds = {}
  private connection: IOddsConnection | null = null
  constructor(config: IHorsePriceStoreConfig) {
    const { bookmakerId, showPrices } = config
    this.showPrices = showPrices
    if (showPrices) {
      this.connection = {
        bookmaker: bookmakerId,
        on: this.onOdd,
      }
    }
  }

  public getOddsForRunner = (raceId: string, runnerId: string) =>
    this.racePrices[raceId] ? this.racePrices[raceId][runnerId] : null

  public getOddsForRace = (raceId: string) => this.racePrices[raceId] || null

  public registerRace = (race: Race) => {
    if (!this.racePrices[race.getStatusId()]) {
      this.racePrices[race.getStatusId()] = {}
    }

    if (!this.races) {
      this.races = {}
    }

    if (!this.races[race.getStatusId()]) {
      this.races[race.getStatusId()] = race
    }

    if (!this.connectionAdded && this.connection && this.showPrices) {
      DiffusionSocket.addOddsConnection(this.connection)
      this.connectionAdded = true
    }
  }
  public checkCachedOdds = () => {
    const allOdds = Object.values(this.cachedOdds)
    this.cachedOdds = {}
    for (const odd of allOdds) {
      this.applyOdd(odd)
    }
  }

  private onOdd = (odd: Odd) => {
    const { id, event } = odd

    const registeredRaces = Object.keys(this.racePrices)
    if (id && registeredRaces.includes(event)) {
      this.cachedOdds[id] = odd
    }
  }

  private applyOdd = (newOdd: Odd) => {
    const { id, event, odds: fractionalOdd } = newOdd

    // const decimalOdd = get(newOdd, 'odds_decimal', null)
    if (id && this.racePrices[event] && fractionalOdd) {
      // If runner is non runner, dont save odd.
      const raceRunner = this.races
        ? this.races[event].getRunnerByDiffusionId(id)
        : null
      if (raceRunner && raceRunner.getNonRunner()) {
        return
      }

      // If price exists, add current odd to historic, otherwise use current odd
      const currentOdds =
        get(this.racePrices, [`${event}`, `${id}`, 'odds'], []) || []
      if (fractionalOdd !== last(currentOdds)) {
        const odds = this.racePrices[event][id]
          ? [...this.racePrices[event][id].odds, fractionalOdd]
          : [fractionalOdd]
        this.racePrices[event][id] = { favourite: false, odds }
      }

      let bestPrices = [{ id: '', price: '999/1' }]
      // Assert best odds for 'favorite' marker
      forEach(this.racePrices[event], (runner, runnerId) => {
        let lastOdd = last(runner.odds)
        if (lastOdd) {
          lastOdd = normalisePrice(String(lastOdd))
          const priceAsDecimal = fractionalAsDecimalForComparisonOnly(lastOdd)
          const bestPriceAsDecimal = fractionalAsDecimalForComparisonOnly(
            bestPrices[0].price,
          )
          if (priceAsDecimal === bestPriceAsDecimal) {
            const priceObj = { price: lastOdd, id: runnerId }
            bestPrices.push(priceObj)
          } else if (priceAsDecimal < bestPriceAsDecimal) {
            const priceObj = { price: lastOdd, id: runnerId }
            bestPrices = [priceObj]
          }
          runner.favourite = false
        }
      })

      if (bestPrices.length) {
        forEach(bestPrices, runner => {
          if (runner.id) {
            this.racePrices[event][runner.id].favourite = true
          }
        })
      }
    }
  }
}

export default HorsePriceStore
