import { observable, action } from 'mobx'
import axios from 'axios'
import { NON_RUNNER_URL } from '@config'
interface INonRunners {
  [raceId: string]: number[]
}

interface IAPIResponse {
  data: INonRunners | null
}

class NonRunnersStore {
  @observable
  private nonRunners: INonRunners = {}

  @action
  public setNonRunners = (nonRunners: INonRunners) => {
    this.nonRunners = nonRunners
  }

  public isNonRunner = (raceId: string, runnerId: number) => {
    return this.nonRunners[raceId] && this.nonRunners[raceId].includes(runnerId)
  }

  public getNonRunners = async () => {
    const response = await axios.get<IAPIResponse>(NON_RUNNER_URL)

    if (response.data.data) {
      this.setNonRunners(response.data.data)
    }
  }
}

export default new NonRunnersStore()
