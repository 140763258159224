import React, { FunctionComponent, useState } from 'react'
import {
  PopupHeaderRunner,
  WhiteBackgroundWrapper,
  CloseButton,
} from '../Popup.css'
import CustomText from '@components/HorseComponents/CustomText/CustomText'
import IdleTimer from 'react-idle-timer'
import Silk from '../../HorseComponents/CompleteRaceCard/RaceCardComponents/Silk'

interface IPopupRunnerProps {
  content: JSX.Element
  title: string
  onClose: () => void
  closeAfter?: number
  runnerInfo?: {
    courseName: string
    raceTime: string | null
    silk: string | null
  }
}

const RunnerPopup: FunctionComponent<IPopupRunnerProps> = ({
  content,
  title,
  onClose,
  closeAfter,
  runnerInfo,
}) => {
  const [popupRef, setPopupRef] = useState<HTMLDivElement | null>(null)
  return runnerInfo ? (
    <WhiteBackgroundWrapper ref={ref => setPopupRef(ref)}>
      {popupRef && closeAfter && (
        <IdleTimer timeout={closeAfter} onIdle={onClose} element={popupRef} />
      )}
      <PopupHeaderRunner>
        <div>
          <Silk
            silkPath={runnerInfo.silk}
            shouldShowResult={false}
            place={null}
            realRender={true}
          />

          <CustomText
            text={title}
            proximaCondBold={true}
            uppercase={true}
            size="big"
          />
        </div>
        <CloseButton onClick={onClose} />
      </PopupHeaderRunner>
      {content}
    </WhiteBackgroundWrapper>
  ) : null
}

export default RunnerPopup
