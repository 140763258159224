import React, { FunctionComponent } from 'react'
import { IGreyOutProps } from './IGreyOut'
import { observer } from 'mobx-react'
import uuid from 'uuid/v4'
import cx from 'classnames'
import './GreyOut.scss'

const GreyOut: FunctionComponent<IGreyOutProps> = ({
  raceElements,
  index,
  columnIndex,
  greyoutCondition,
  raceAbandoned,
}) =>
  raceElements ? (
    <div
      className={cx('elements__container', {
        'elements-top-border-greyout': greyoutCondition,
      })}
    >
      {raceElements.elements.map((singleEl, index2) => {
        const lastColumnElement = index2 === raceElements.elements.length - 1
        const firstRaceElement = index2 === 0 && index !== 0 && singleEl.time
        const firstElement = index2 === 0 && index !== 0
        const statsElement = index2 === 0 && index !== 0 && !singleEl.time
        const bottomRaceElement =
          (lastColumnElement && singleEl.time) ||
          (lastColumnElement && index2 !== 0)
        return (
          <div
            className={cx('element', singleEl.componentName || '', {
              'element-race-separator': firstRaceElement || statsElement,
              'element-bottom-border': bottomRaceElement,
              'element-bottom-border-greyout':
                bottomRaceElement && greyoutCondition,
              'element-side-borders': !greyoutCondition && !statsElement,
              'element-side-borders-greyout': greyoutCondition && !statsElement,
              'elements-top-border-greyout': greyoutCondition && firstElement,
              'elements-top-border': firstElement && !greyoutCondition,
            })}
            style={{
              marginLeft: firstElement ? '-7px' : '',
              marginRight: firstElement ? '-7px' : '',
              paddingLeft: firstElement ? '7px' : '',
              paddingRight: firstElement ? '7px' : '',
              marginBottom:
                index2 === 0 && index === 0 && !raceAbandoned ? '' : '-1px',
            }}
            key={uuid()}
          >
            {singleEl.element}
          </div>
        )
      })}
    </div>
  ) : null

export default observer(GreyOut)
