import React, { FunctionComponent } from 'react'
import spotlightLogo from '@utils/spotlightImage'
import { ISpotlightLogo } from './ISpotlight'

import './spotlightStyles.scss'

const SpotlightLogo: FunctionComponent<ISpotlightLogo> = () => (
  <div className="spotlight__title-container">
    <img
      className="spotlight__logo"
      alt="racing post spotlight logo"
      src={spotlightLogo}
    />
  </div>
)

export default SpotlightLogo
