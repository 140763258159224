import React, { FunctionComponent } from 'react'
import itvLogo from '@assets/channel_logos/itvracinglogo.png'
import rtvLogo from '@assets/channel_logos/rtvlogo.png'
import skyLogo from '@assets/channel_logos/skysportsracing.png'
import CustomText from '@components/HorseComponents/CustomText/CustomText'
import '../RaceCardStyles/RaceBoard.scss'

interface IChannelLogoProps {
  channel: 'ITV4' | 'RTV' | 'SKY' | 'ITV' | 'ITV1' | 'ITV2' | 'ITV3'
}

const imageMap = {
  RTV: rtvLogo,
  SKY: skyLogo,
  ITV: itvLogo,
  ITV1: itvLogo,
  ITV2: itvLogo,
  ITV3: itvLogo,
  ITV4: itvLogo,
}

const ChannelLogo: FunctionComponent<IChannelLogoProps> = ({ channel }) =>
  imageMap[channel] ? (
    <img
      className="raceboard__tv-logo-container"
      src={imageMap[channel]}
      alt={`${channel} logo`}
    />
  ) : channel ? (
    <div className="raceboard__tv">
      <CustomText
        text={channel}
        colour="white"
        proximaBlack={true}
        bold={true}
      />
    </div>
  ) : null

export default ChannelLogo
