import orderBy from 'lodash/orderBy'
import Column, { IColumn, IElement } from '@classes/Column'

interface IScreenOptions {
  maxColsPerScreen: number
  maxScreens: number
}

interface IColumnsOptions {
  screenOptions: IScreenOptions
  columns: Column[]
}

class Columns {
  private columns: Column[]
  private mainComponents: number[] = []
  private maxColsPerScreen: number
  private maxScreens: number
  private activeColumnIndex: number
  constructor(options: IColumnsOptions) {
    const { screenOptions, columns = [] } = options
    const { maxColsPerScreen, maxScreens } = screenOptions
    this.columns = columns
    this.maxColsPerScreen = maxColsPerScreen
    this.maxScreens = maxScreens
    this.activeColumnIndex = 0
  }

  public addNewColumn = (column: IColumn, dontCheck: boolean = false) => {
    if (dontCheck || this.canAddColumn(column)) {
      const baseColumn = column
      const newCol = new Column(baseColumn)
      this.columns.push(newCol)
    }
  }

  public reset = () => {
    const columns = [...this.columns].filter(column => column.locked)
    this.mainComponents = []
    this.columns = columns
    this.resetActiveColumnIndex()
  }

  public hardReset = () => {
    this.columns = []
    this.mainComponents = []
    this.resetActiveColumnIndex()
  }

  public lockColumns = () => {
    this.columns.forEach(column => column.lock())
  }

  public activeColumn = (): Column =>
    this.columns[this.activeColumnIndex] || null

  public canAddElement = (height: number, course: string) => {
    return (
      this.activeColumn() && this.activeColumn().canAddElement(height, course)
    )
  }

  public addElement = (
    element: IElement,
    isMainComponent: boolean,
    componentName: string,
  ) => {
    const activeColumn = this.activeColumn()

    if (activeColumn) {
      activeColumn.addElement(element, componentName, this.columns.length - 1)
      if (isMainComponent && element.raceId) {
        this.addMainComponentElement(element.raceId)
      }
    }
  }

  public getColumnCount = (cols = this.columns) =>
    cols.reduce(
      (amountOfCols, column) => (amountOfCols += column.columnWidth),
      0,
    )

  public haveHitScreenCount = (): boolean => {
    return (
      this.getColumnCount() / this.maxColsPerScreen > Number(this.maxScreens)
    )
  }

  public isEmpty = (): boolean => this.columns.length === 0

  public activeIsNotAtMaxCols = (): boolean =>
    this.activeColumnIndex < this.maxCols() - 1

  public activeIsLastColumn = (): boolean =>
    this.activeColumnIndex === this.columns.length - 1

  public incrementActiveColumnIndex = () => (this.activeColumnIndex += 1)

  public resetActiveColumnIndex = () => (this.activeColumnIndex = 0)

  public addMainComponentElement = (raceId: number) => {
    this.mainComponents.push(raceId)
  }

  public hasMainComponentBeenAdded = (
    raceId: number,
    isMainComponent: boolean = false,
  ): boolean => {
    return this.mainComponents.includes(raceId) || isMainComponent
  }

  public chunkColumnsToScreens = (): Column[][] => {
    let screenIndex = 0
    const screens = this.columnsByEarliestTime().reduce(
      (screensAcc: Column[][], column: Column) => {
        if (!screensAcc[screenIndex]) {
          screensAcc[screenIndex] = []
        }
        if (
          column.columnWidth + this.getColumnCount(screensAcc[screenIndex]) >
          this.maxColsPerScreen
        ) {
          screenIndex += 1
          screensAcc[screenIndex] = []
          screensAcc[screenIndex].push(column)
        } else {
          screensAcc[screenIndex].push(column)
        }
        return screensAcc
      },
      [],
    )

    return screens
  }

  public getColumns = () => [...this.columns]
  public setColumns = (columns: Column[]) => (this.columns = columns)

  private columnsByEarliestTime = () =>
    orderBy(this.columns, ['courseStartTime'], ['asc'])

  private canAddColumn = (column: IColumn): boolean => {
    if (column && column.columnWidth) {
      return this.getColumnCount() + column.columnWidth <= this.maxCols()
    }
    return false
  }

  private maxCols = (): number => this.maxScreens * this.maxColsPerScreen
}

export default Columns
