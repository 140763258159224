import React, { FunctionComponent, Fragment } from 'react'
import SelectionsAndData from './SelectionsAndData'
import { ISelectionBoxesProps } from './ISelectionBoxes'
import isEmpty from 'lodash/isEmpty'
import RacingPostRating from '@horses/VerdictWithRPR/RacingPostRating'
import GreyoutRP from '@utils/GreyoutRP'
import { observer } from 'mobx-react'
import AbandonedRaceStore from '@stores/AbandonedRaceStore'

const SelectionBoxes: FunctionComponent<ISelectionBoxesProps> = ({
  innerRef,
  postDataHorse,
  race,
  meetingAbandoned,
  rprHorse,
  showSelectionBoxes,
  showMostTipped,
  meetingColour,
}) => {
  return race && !meetingAbandoned ? (
    <GreyoutRP
      greyoutCondition={
        race.hasRaceFinished() ||
        race.raceHasResults() ||
        AbandonedRaceStore.isRaceAbandoned(race.getRaceInstanceUid())
      }
    >
      <div
        ref={ref =>
          innerRef &&
          innerRef(
            ref,
            {
              race,
              rprHorse,
              meetingAbandoned,
              postDataHorse,
              showSelectionBoxes,
              showMostTipped,
              meetingColour,
            },
            observer(SelectionBoxes),
            'SelectionBoxes',
          )
        }
      >
        {
          <Fragment>
            {showSelectionBoxes &&
              showMostTipped &&
              !isEmpty(race.getSelections()) && (
                <SelectionsAndData
                  selections={race.getSelections()}
                  raceTime={race.getParsedRaceTime()}
                  race={race}
                  meetingColour={meetingColour}
                  postDataHorse={postDataHorse}
                />
              )}
            <RacingPostRating
              rprHorse={rprHorse}
              postDataHorse={postDataHorse}
            />
          </Fragment>
        }
      </div>
    </GreyoutRP>
  ) : null
}
export default SelectionBoxes
