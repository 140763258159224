import React from 'react'

import './Horse.scss'

const Horse = () => (
  <div className="horse__container">
    <span role="img" aria-label="horse">
      🐎
    </span>
  </div>
)

export default Horse
