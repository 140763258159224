import cx from 'classnames'
import React, { FunctionComponent, ReactNode } from 'react'
import { ICustomText } from './ICustomText'
import './TextStyles.scss'

const CustomText: FunctionComponent<ICustomText> = ({
  preTextSize = 'smallest',
  preTextColour = 'black',
  colour = 'black',
  size = 'normal',
  subScriptDegreeAlignment,
  subScriptSize = 'smallest',
  proximaCondSemiBoldIt,
  proximaCondSemiBold,
  proximaCondMedium,
  proximaCondBold,
  proximaExtraBold,
  letterSpacing,
  proximaMedium,
  fontSecondary,
  textEllipses,
  fontTertiary,
  ratingBorder,
  dottedBorder,
  spacingLarge,
  italicCustom,
  proximaBlack,
  fontPrimary,
  timesRoman,
  swisBlack,
  uppercase,
  lowercase,
  highlight,
  spaceLeft,
  supScript,
  subScript,
  maxWidth,
  brackets,
  fontBold,
  preText,
  nowrap,
  subText,
  flexOne,
  noSpace,
  italic,
  inline,
  centre,
  light,
  flex,
  bold,
  text,
  header,
  label,
}) => {
  const setStyles = () => {
    return cx(`text--${colour} text--${size}`, 'text--space', {
      'text--bold': bold,
      'text--centre': centre,
      'text--dotted-border': dottedBorder,
      'text--flex': flex,
      'text--flex-one': flexOne,
      'text--font-bold': fontBold,
      'text--font-primary': fontPrimary,
      'text--font-secondary': fontSecondary,
      'text--font-tertiary': fontTertiary,
      'text--highlight': highlight,
      'text--inline': inline,
      'text--italic': italic,
      'text--italic-custom': italicCustom,
      'text--light': light,
      'text--lower': lowercase,
      'text--max-width': maxWidth,
      'text--no-space': noSpace,
      'text--space-left': spaceLeft,
      'text--rating-border': ratingBorder,
      'text--super': supScript,
      'text--space-large': spacingLarge,
      'text--swis-black': swisBlack,
      'text--times-roman': timesRoman,
      'text--upper': uppercase,
      'text--proxima-black': proximaBlack,
      'text--ellipses': textEllipses,
      '--alignment': subScriptDegreeAlignment,
      'text--proxima-cond-medium': proximaCondMedium,
      'text--proxima-cond-semi-bold': proximaCondSemiBold,
      'text--proxima-cond-semi-bold-italic': proximaCondSemiBoldIt,
      'text--proxima-cond-bold': proximaCondBold,
      'text--proxima-extra-bold': proximaExtraBold,
      'text--proxima-medium': proximaMedium,
      'text--letter-spacing': letterSpacing,
      'text--nowrap': nowrap,
    })
  }

  const setContent = (value?: string | number): string => {
    if (value === undefined) {
      return ''
    }

    return brackets ? `(${value})` : `${value}`
  }

  const setLabel = (value: string | number): ReactNode => {
    return `${value}`
  }

  const setPreText = (value: string | number): ReactNode => {
    return (
      <span
        className={`text--${preTextColour} text--${preTextSize} text--preText text--super`}
      >
        {value}
      </span>
    )
  }

  const setSubText = (value: string | number): ReactNode => {
    return <span className="text--smallest text--spacing">{`${value}`}</span>
  }

  const setSubScript = (
    value: string | number,
    alignment: boolean | undefined,
  ): ReactNode => {
    return (
      <span
        className={`text--${subScriptSize} text--super${
          alignment ? '--alignment' : ''
        } text--spacing`}
      >
        {value}
      </span>
    )
  }
  return (
    <p className={setStyles()}>
      {label && setLabel(label)}
      {preText && setPreText(preText)}
      {text && setContent(text)}
      {subText && setSubText(subText)}
      {subScript && setSubScript(subScript, subScriptDegreeAlignment)}
    </p>
  )
}
export default CustomText
