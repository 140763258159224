import get from 'lodash/get'
import split from 'lodash/split'
import dayjs from 'dayjs'
import { ANALYTICS_URL, DETAILED_FORM_URL } from '@config'
import { IMinimalTipData } from '@components/HorseComponents/Tipping/ITipping'
import { IHorseMeeting } from '@models/HorseMeeting'
import axios from 'axios'
const STONE_TO_POUNDS = 14
export const FIVE_SECONDS = 5000
export const ONE_MIN = 1000 * 60
export const FIVE_MIN = ONE_MIN * 5
export const TEN_SECS = ONE_MIN / 6
export const THIRTY_SECS = ONE_MIN / 2
export const FIFTEEN_MINS = ONE_MIN * 15
export const TEN_MINS = ONE_MIN * 10
export const DELAYED_RACE_CODES = ['D', 'K', 'J']
export const OFF_RACE_CODE = 'O'
export const OFF_SIGNAL_WAIT_TIME = FIVE_SECONDS
export const formattedJockeyWeight = (pounds: number) => {
  const stone = Math.floor(pounds / STONE_TO_POUNDS)
  const lbs = pounds % 14
  return `${stone}${`-${lbs}`}`
}

export const timeToDayjs = (time: string) => {
  const today = dayjs()
  const times = time.split(':')
  return today
    .set('hour', parseInt(times[0], 10))
    .set('minute', parseInt(times[1], 10))
    .set('second', 0)
}

export const getObjectValues = (
  data: object,
  key: string,
  // tslint:disable-next-line
  defaultValue: any = null,
) => {
  const value = get(data, key)
  return value ? Object.values(value) : defaultValue
}

export const truncate = (value: string | null, amount = 13) =>
  value && value.length > amount ? `${value.slice(0, amount)}...` : value

export const detectScreenSize = (
  options: { maxHeight?: number; maxWidth?: number } = {},
  onRightScreenSize: () => void,
  onWrongScreenSize: (width: number, height: number) => void,
) => {
  const { maxHeight, maxWidth } = options
  if (maxHeight && maxWidth) {
    const { screen } = window
    const { height, width } = screen
    if (height !== maxHeight && width !== maxWidth && onWrongScreenSize) {
      onWrongScreenSize(width, height)
    } else if (onRightScreenSize) {
      onRightScreenSize()
    }
  }
  return null
}

export const fetchRaces = async (url: string): Promise<IHorseMeeting[]> => {
  try {
    const response = await axios.get(url)
    const json = await response.data
    if (json.data && !json.error) {
      return json.data
    }
  } catch (error) {
    // tslint:disable-next-line
    console.error(error)
  }
  return []
}

export const sendAnalyticsData = (analytics: IMinimalTipData[]) => {
  const body = JSON.stringify({ analytics })

  return fetch(ANALYTICS_URL, {
    method: 'PUT',
    body,
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
    },
  })
}

export const fetchHorsePopup = async (raceId: number, horseName: string) => {
  const formattedHorseName = horseName.replace(/ /g, '').toLowerCase()

  const requestPath = `${DETAILED_FORM_URL}/${raceId}/${formattedHorseName}.html`

  let response
  try {
    // catch the 500 error thrown by axios when no form is available
    response = await axios.get(requestPath)
  } catch (e) {
    return ''
  }

  return response.status === 200 ? response.data : ''
}

export const fontSizes = {
  smallest: 'smallest',
  small: 'small',
  normal: 'normal',
  medium: 'medium',
  xmedium: 'xmedium',
  large: 'large',
}

export const logger = (message: string, extra = '') => {
  // tslint:disable-next-line
  console.log(message, dayjs().format('HH:mm:ss'), extra)
}

export const shouldAddToArray = (condition: boolean, object: object) =>
  condition ? [object] : []

export const courseTypes = {
  B: 'Flat & Jumps',
  F: 'Flat',
  J: 'Jumps',
  P: 'Point-to-point',
  X: 'All-weather',
}

/**
 * @param  {string} fractionalPrice
 * @returns {number} decimalised version of the fraction
 */
export const fractionalAsDecimalForComparisonOnly = (
  fractionalPrice: string,
) => {
  const [numerator, denominator] = split(fractionalPrice, '/')
  const numeratorN = Number(numerator)
  const denominatorN = Number(denominator)
  return numeratorN / denominatorN
}

/**
 * If price is `Evs`, normalise it to `1/1`
 * @param  {string} price
 * @returns {string}
 */
export const normalisePrice = (price: string) => {
  if (price === 'Evs') {
    return '1/1'
  }
  return price
}
