import React, { FunctionComponent } from 'react'
import CustomText from '@horses/CustomText/CustomText'
import { IFavouriteBody } from '../IFavourites'
import map from 'lodash/map'
import get from 'lodash/get'

const Body: FunctionComponent<IFavouriteBody> = ({
  nonHandicapData,
  handicapData,
  favourites,
}) => (
  <tbody>
    {map(favourites, key => (
      <tr key={key}>
        <td className="favourites__year helpers--padding-top-small">
          <CustomText
            fontTertiary={true}
            dottedBorder={true}
            maxWidth={true}
            flexOne={true}
            noSpace={true}
            size="small"
            flex={true}
            bold={true}
            text={key}
          />
        </td>
        <td>
          <div className="helpers--flex helpers--padding-top-small ">
            <div className="helpers--align-right favourites__wins-rides-percentage">
              <CustomText
                text={get(nonHandicapData, `${key}.wins`)}
                fontTertiary={true}
                noSpace={true}
                size="small"
                bold={true}
              />
            </div>
            <div className="helpers--align-left">
              <CustomText
                fontTertiary={true}
                noSpace={true}
                size="small"
                text="-"
              />
            </div>

            <div className="helpers--align-left favourites__wins-rides-percentage">
              <CustomText
                text={get(nonHandicapData, `[${key}].runs`)}
                fontTertiary={true}
                noSpace={true}
                size="small"
              />
            </div>
            <div className="helpers--align-right favourites__wins-rides-percentage">
              <CustomText
                text={`${get(nonHandicapData, `[${key}].percentage`) || '0'}%`}
                fontTertiary={true}
                noSpace={true}
                size="small"
              />
            </div>
          </div>
        </td>
        <td className="helpers--align-right helpers--padding-right-large helpers--padding-top-small">
          <CustomText
            text={get(nonHandicapData, `[${key}].stake`) || '0.00'}
            fontTertiary={true}
            size="small"
          />
        </td>
        <td className="helpers--border-left">
          <div className="helpers--flex helpers--padding-top-small">
            <div className="helpers--align-right favourites__wins-rides-percentage">
              <CustomText
                text={get(handicapData, `[${key}].wins`)}
                fontTertiary={true}
                noSpace={true}
                size="small"
                bold={true}
              />
            </div>
            <div className="helpers--align-right">
              <CustomText
                fontTertiary={true}
                noSpace={true}
                size="small"
                text="-"
              />
            </div>
            <div className="helpers--align-left favourites__wins-rides-percentage">
              <CustomText
                text={get(handicapData, `[${key}].runs`)}
                fontTertiary={true}
                noSpace={true}
                size="small"
              />
            </div>
            <div className="helpers--align-right favourites__wins-rides-percentage">
              <CustomText
                text={`${get(handicapData, `[${key}].percentage`) || '0'}%`}
                fontTertiary={true}
                noSpace={true}
                size="small"
              />
            </div>
          </div>
        </td>
        <td className="helpers--align-right helpers--padding-top-small">
          <CustomText
            text={get(handicapData, `[${key}].stake`) || '0.00'}
            fontTertiary={true}
            noSpace={true}
            size="small"
          />
        </td>
      </tr>
    ))}
  </tbody>
)

export default Body
