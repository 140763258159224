import React, { FunctionComponent } from 'react'
import { IDevTools } from './IDevTools'
import { observer, inject } from 'mobx-react'

import './DevTools.scss'

const DevTools: FunctionComponent<IDevTools> = ({ screenStore }) =>
  (screenStore && screenStore.devTools) ? (
    <div className="dev-tools__container">
      <div>
        <button
          className="dev-tools__button"
          onClick={() => screenStore.setPreviousScreen(true)}
        >
          Previous Screen
        </button>
        <button
          className="dev-tools__button"
          onClick={() => screenStore.setNextScreen(true)}
        >
          Next Screen
        </button>
      </div>
      <div className="dev-tools__title">
        {`Screen ${screenStore.returnActiveScreenToScreen()}`}
      </div>
      <button
        className="dev-tools__button"
        onClick={screenStore.logActiveScreenToConsole}
      >
        Log Screen
      </button>
      <button
        className="dev-tools__button"
        onClick={screenStore.throwSentryError}
      >
        Throw Error
      </button>
      <p className="dev-tools__title">Dev Tools</p>
    </div>
  ) : null

export default inject('screenStore')(observer(DevTools))
