import React, { FunctionComponent } from 'react'
import get from 'lodash/get'
import last from 'lodash/last'
import first from 'lodash/first'
import { inject, observer } from 'mobx-react'
import ContinuedHeader from './ContinuedHeader'
import MainHeader from './MainHeader'
import { IHeaderProps } from './HeaderProps'

import { EIGHTH_OF_SCREEN } from '@config'

const Header: FunctionComponent<IHeaderProps> = ({
  columnIndex,
  column,
  screenStore,
}) => {
  if (column && column.noHeader) {
    return null
  }

  if (column && screenStore) {
    const colsAhead: number = screenStore.amountOfColsAheadAreSame(columnIndex)
    const { meeting } = column
    const viewportWidth: number =
      colsAhead * EIGHTH_OF_SCREEN * column.columnWidth

    const prevScreen = screenStore.getPreviousScreen()
    const nextScreen = screenStore.getNextScreen()
    const prevScreenLastCol = last(prevScreen) || {}
    const prevScreenLastColCourse = get(prevScreenLastCol, 'course')
    const nextScreenFirstCol = first(nextScreen) || {}
    const nextScreenFirstColCourse = get(nextScreenFirstCol, 'course')

    if (
      viewportWidth &&
      viewportWidth <= EIGHTH_OF_SCREEN &&
      columnIndex === 0 &&
      prevScreen &&
      prevScreenLastColCourse === screenStore.activeScreen[columnIndex].course
    ) {
      return (
        <ContinuedHeader
          viewportWidth={viewportWidth}
          columnIndex={columnIndex}
          meeting={meeting}
        />
      )
    }
    if (viewportWidth) {
      return (
        <MainHeader
          viewportWidth={viewportWidth}
          meeting={meeting}
          titleOverride={column.course}
          isFree={column.isFreeColumn()}
          columnIndex={columnIndex}
          nextScreenFirstColCourse={nextScreenFirstColCourse}
          prevScreenLastColCourse={prevScreenLastColCourse}
        />
      )
    }
  }
  return null
}

export default inject('screenStore')(observer(Header))
