import { differenceInSeconds } from 'date-fns';
import dayjs from 'dayjs'
import Race from '@classes/Race';
import reduce from 'lodash/reduce';
import { observable } from 'mobx';

interface IRaceTimes {
  [key: string]: IRaceTime
}

export interface IRaceTime {
  countdownMin: number;
  countdownSec: number;
  race_datetime: Date;
}

class CountDownStore {
  @observable private times: IRaceTimes = {}
  public getRaceCountdown = (id: number) => {
    return this.times[id] || null
  }

  public registerRace = (race: Race) => {
    const id = race.getRaceInstanceUid()
    const time = race.getRaceDateObject()
    if (!this.times[id]) {
      this.times[id] = {
        race_datetime: time,
        countdownMin: 0,
        countdownSec: 0
      }
    } else if (this.times[id] && dayjs(time).isBefore(dayjs())) {
      delete this.times[id]
    }
  }

  public checkTimes = (date: Date) => {
    const times = reduce(this.times, (acc: IRaceTimes, race, key: string) => {
      const diffInSeconds = differenceInSeconds(race.race_datetime, date)
      const mins = Math.floor(diffInSeconds / 60)
      const seconds = diffInSeconds % 60
      if (mins > 0 || seconds > 0) {
        acc[key] = { ...race, countdownMin: mins, countdownSec: seconds }
      }
      return acc
    }, {})
    this.times = times
  }
}

export default CountDownStore
