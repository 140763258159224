import React, { FunctionComponent } from 'react'
import CustomText from '@horses/CustomText/CustomText'
import Container from '@horses/Container/Container'
import capitalize from 'lodash/capitalize'
import dayjs from 'dayjs'
import toNumber from 'lodash/toNumber'

import './TravellersCheck.scss'
import { ITravellersCheckProps } from './ITravellersCheck'

const TravellersCheck: FunctionComponent<ITravellersCheckProps> = ({
  courseName,
  data,
  innerRef,
  colour,
}) => {
  return data && data.length ? (
    <Container
      colour={colour}
      title={`${courseName} Travellers Check`}
      innerRef={ref =>
        innerRef(ref, { courseName, data, innerRef, colour }, TravellersCheck)
      }
    >
      <div className="travellers__body">
        <CustomText
          text={`The following horses have travelled the furthest to ${capitalize(
            courseName,
          )}`}
          fontTertiary={true}
        />
        {data
          .filter(({ dist_out }) => dist_out !== 'IRE' && toNumber(dist_out) > 0) // filter out horses that didn't travel
          .sort((a, b) => b.dist_out.localeCompare(a.dist_out))
          .map(({ dist_out, race_datetime, horse_name, trainer_name }) => {
            return (
              <div
                className="travellers__table"
                key={horse_name + race_datetime}
              >
                <CustomText
                  text={`${horse_name} (${dayjs(race_datetime).format(
                    'h:mm',
                  )})`}
                  fontTertiary={true}
                  dottedBorder={true}
                  flexOne={true}
                  bold={true}
                  flex={true}
                />
                <CustomText
                  text={`${trainer_name} - ${
                    Number(dist_out) ? `${dist_out} miles ` : '0'
                    }`}
                  fontTertiary={true}
                  bold={true}
                />
              </div>
            )
          })}

        {data
          .map(({ dist_out }) => dist_out)
          .every(item => item === 'IRE' || toNumber(item) <= 150) && (
            <CustomText
              text="No horses have travelled further than 150 miles"
              bold={true}
            />
          )}
      </div>
    </Container>
  ) : null
}

export default TravellersCheck
