import React, { FunctionComponent } from 'react'
import Container from '@horses/Container/Container'
import { IFavouritesProps } from './IFavourites'
import union from 'lodash/union'
import Head from './Table/Head'
import Body from './Table/Body'
import get from 'lodash/get'
import './Favourites.scss'

const Favourites: FunctionComponent<IFavouritesProps> = ({
  courseName,
  innerRef,
  colour,
  data,
}) => {
  const handicap = get(data, 'handicap')
  const nonHandicap = get(data, 'non_handicap')
  if (data && handicap && nonHandicap) {
    const handicapKeys = Object.keys(handicap)
    const nonHandicapKeys = Object.keys(nonHandicap)
    const allKeys = union(handicapKeys, nonHandicapKeys)
    return (
      <Container
        title={`${courseName || `Meeting's`} Favourites`}
        colour={colour}
        innerRef={ref =>
          innerRef(ref, { courseName, data, innerRef, colour }, Favourites)
        }
      >
        <table>
          <Head />
          <Body
            nonHandicapData={nonHandicap}
            handicapData={handicap}
            favourites={allKeys}
          />
        </table>
      </Container>
    )
  }

  return null
}

export default Favourites
