import React, { FunctionComponent, Fragment } from 'react'
import Container from '@horses/Container/Container'
import { ITenYear } from './ITenYear'
import dayjs from 'dayjs'
import './TenYear.scss'

const TenYear: FunctionComponent<ITenYear> = ({
  courseName,
  innerRef,
  colour,
  data,
}) =>
  (data && data.length) ? (
    <Container
      colour={colour} title={`${courseName} Ten-year Trends`}
      innerRef={ref =>
        innerRef(ref, { data, courseName, innerRef, colour }, TenYear)
      }
    >

      <div className="table-container ten-year__table">
        {data.map(race => {
          if (race) {
            const {
              winning_trainers,
              previous_run,
              favourites,
              jockeys,
              trends,
              draw,
            } = race

            const {
              market_position_highest,
              market_position_median,
              market_position_lowest,
              starting_price_highest,
              starting_price_lowest,
              starting_price_median,
              total_runners_highest,
              total_runners_lowest,
              total_runners_median,
              weight_highest,
              rating_highest,
              weight_median,
              rating_median,
              rating_lowest,
              weight_lowest,
              age_highest,
              age_lowest,
              age_median,
            } = trends

            return (
              Object.values(race).length > 1 && (
                <div
                  key={`${race.race_datetime}`}
                  className="ten-year__table-item"
                >
                  {Object.values(trends).length !== 0 && (
                    <table className="table__container">
                      <tbody>
                        <tr className="ten-year__table--main-title">
                          <td>{dayjs(race.race_datetime).format('h.mm')}</td>
                          <td>Range</td>
                          <td>Median</td>
                        </tr>
                        {weight_median && (
                          <tr>
                            <td>Weight</td>
                            <td>
                              {weight_lowest} -> {weight_highest}
                            </td>
                            <td>{weight_median}</td>
                          </tr>
                        )}
                        {rating_median && (
                          <tr>
                            <td>Handicap Rating</td>
                            <td>
                              {rating_lowest} -> {rating_highest}
                            </td>
                            <td>{rating_median}</td>
                          </tr>
                        )}
                        {age_median && (
                          <tr>
                            <td>Age</td>
                            <td>
                              {age_lowest} -> {age_highest}
                            </td>
                            <td>{age_median}</td>
                          </tr>
                        )}
                        {starting_price_median && (
                          <tr>
                            <td>SP</td>
                            <td>
                              {starting_price_lowest} ->{' '}
                              {starting_price_highest}
                            </td>
                            <td>{starting_price_median}</td>
                          </tr>
                        )}
                        {market_position_median && (
                          <tr>
                            <td>Market position</td>
                            <td>
                              {`${market_position_lowest} -> ${market_position_highest}`}
                            </td>
                            <td>{market_position_median}</td>
                          </tr>
                        )}
                        {total_runners_median && (
                          <tr className="ten-year__table--bottom-pad">
                            <td>Total Runners</td>
                            <td>
                              {total_runners_lowest} -> {total_runners_highest}
                            </td>
                            <td>{total_runners_median}</td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  )}

                  <table>
                    <tbody>
                      {previous_run && (
                        <tr className="ten-year__table--border ">
                          <td>Previous Runs</td>
                          <td>
                            W{previous_run.wins} PL{previous_run.placed}
                          </td>
                          <td>L{previous_run.lost}</td>
                          <td>Debut {previous_run.debuts}</td>
                        </tr>
                      )}

                      {favourites && (
                        <Fragment>
                          <tr className="ten-year__table--sub-title">
                            <td>Favourites</td>
                            <td>W</td>
                            <td>Races</td>
                            <td>£1 bet</td>
                          </tr>
                          <tr>
                            <td>1st fav</td>
                            <td>{favourites['1st_favourite'].wins}</td>
                            <td>{favourites['1st_favourite'].races}</td>
                            <td>{favourites['1st_favourite'].stake}</td>
                          </tr>
                          <tr className="ten-year__table--bottom-pad">
                            <td>2nd fav</td>
                            <td>{favourites['2nd_favourite'].wins}</td>
                            <td>{favourites['2nd_favourite'].races}</td>
                            <td>{favourites['2nd_favourite'].stake}</td>
                          </tr>
                        </Fragment>
                      )}

                      {/* TO DO : DRAW SECTION, WHERE IS THIS DATA ? */}

                      {draw && (
                        <Fragment>
                          <tr className="ten-year__table--border-top ten-year__table--sub-title ">
                            <td>Draw</td>
                            <td>Low</td>
                            <td>Mid</td>
                            <td>High</td>
                          </tr>

                          <tr className="ten-year__table--bottom-pad">
                            <td>Good</td>
                            <td>1</td>
                            <td>2</td>
                            <td>3</td>
                          </tr>
                        </Fragment>
                      )}

                      {winning_trainers && (
                        <Fragment>
                          <tr className="ten-year__table--border-top ten-year__table--sub-title ">
                            <td>Winner Trainer</td>
                            <td>W</td>
                            <td>PL</td>
                            <td>RNRS</td>
                          </tr>
                          {Object.values(winning_trainers).map(
                            ({
                              race_datetime,
                              trainer_name,
                              horse_name,
                              runners,
                              placed,
                              wins,
                            }) => (
                                <tr
                                  className="ten-year__table--bottom-pad"
                                  key={`trainer${race_datetime}${horse_name}`}
                                >
                                  <td>{trainer_name}</td>
                                  <td>{wins}</td>
                                  <td>{placed}</td>
                                  <td>{runners}</td>
                                </tr>
                              ),
                          )}
                        </Fragment>
                      )}

                      {jockeys && (
                        <Fragment>
                          <tr className="ten-year__table--border-top ten-year__table--sub-title ">
                            <td>Jockeys</td>
                            <td>W</td>
                            <td>PL</td>
                            <td>RIDES</td>
                          </tr>
                          {Object.values(jockeys).map(
                            ({
                              wins,
                              race_datetime,
                              placed,
                              rides,
                              jockey_name,
                              horse_name,
                            }) => (
                                <tr
                                  className="ten-year__table--bottom-pad"
                                  key={`jockey${race_datetime}${horse_name}`}
                                >
                                  <td>{jockey_name}</td>
                                  <td>{wins}</td>
                                  <td>{placed}</td>
                                  <td>{rides}</td>
                                </tr>
                              ),
                          )}
                        </Fragment>
                      )}
                    </tbody>
                  </table>
                </div>
              )
            )
          }
        })}
      </div>
    </Container>
  ) : null

export default TenYear
