import React, { Component } from 'react'
import { ITippingProps, IMinimalTipData, ITip } from './ITipping'
import Sweetspot from './Sweetspot'
import Tips from './Tip'
import './TippingStyles.scss'
import { inject, observer } from 'mobx-react'
import { pick } from 'lodash'
import GreyoutRP from '@utils/GreyoutRP'
import AbandonedRaceStore from '@stores/AbandonedRaceStore'
import Race from '@classes/Race'

class Tipping extends Component<ITippingProps> {
  public index: number = 0
  constructor(props: ITippingProps) {
    super(props)

    if (this.props.realRender && this.props.screenStore) {
      const minimalTipData = pick<IMinimalTipData>(this.props.tip, [
        'race_datetime',
        'course_name',
        'tipster_type',
        'tipster_name',
        'tip_type',
        'horse_name',
      ]) as IMinimalTipData

      minimalTipData.verdict_horse_name = this.props.race.getVerdictHorseName()

      this.index = this.props.screenStore.pushTipsAnalytics(minimalTipData)
    }
  }

  public componentDidMount() {
    if (this.props.realRender && this.props.screenStore) {
      this.props.screenStore.setTipComponentRendered(this.index)
    }
  }

  private raceHasTippedHorse = (race: Race, tip: ITip) => {
    const validTip = race.runners.filter(
      runner => runner.runner.horse_uid === tip.horse_uid,
    )
    return validTip && validTip.length ? true : false
  }
  public render() {
    const { race, meetingAbandoned, tip } = this.props
    const shouldGreyoutTip =
      race.hasRaceFinished() ||
      AbandonedRaceStore.isRaceAbandoned(race.getRaceInstanceUid())

    return race && !meetingAbandoned && this.raceHasTippedHorse(race, tip) ? (
      <GreyoutRP greyoutCondition={shouldGreyoutTip}>
        <div className="tipping__body">
          {tip.tipster_type === 'SIGNPOSTS SWEETSPOTS' ? (
            <Sweetspot tip={tip} race={race} />
          ) : (
            <Tips tip={tip} />
          )}
        </div>
      </GreyoutRP>
    ) : null
  }
}

export default inject('screenStore')(observer(Tipping))
