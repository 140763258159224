import React, { FunctionComponent } from 'react'
import CustomText from '@horses/CustomText/CustomText'
import dayjs from 'dayjs'

const Head: FunctionComponent = () => (
  <thead>
    <tr className="helpers--table-head">
      <th className="helpers--align-left favourites__year helpers--padding-top-small">
        <CustomText
          text={`${dayjs()
            .subtract(4, 'year')
            .format('YYYY')}+`}
          noSpace={true}
          bold={true}
          fontTertiary={true}
        />
      </th>
      <th className="helpers--align-left helpers--padding-bottom-medium">
        <div>
          <CustomText
            text="NON-HANDICAPS"
            fontTertiary={true}
            noSpace={true}
            size="small"
          />
          <CustomText
            text="WINS-RACES"
            fontTertiary={true}
            noSpace={true}
            size="small"
          />
        </div>
      </th>
      <th className="helpers--padding-right-large helpers--padding-top-small">
        <CustomText
          fontTertiary={true}
          text="£1 STAKE"
          noSpace={true}
          bold={true}
        />
      </th>
      <th className=" helpers--padding-left-large helpers--border-left">
        <div className="helpers--align-left ">
          <CustomText
            fontTertiary={true}
            text="HANDICAPS"
            noSpace={true}
            size="small"
          />
          <CustomText
            fontTertiary={true}
            text="WINS-RACES"
            noSpace={true}
            size="small"
          />
        </div>
      </th>
      <th className="helpers--align-right helpers--padding-top-small">
        <CustomText
          fontTertiary={true}
          text="£1 STAKE"
          noSpace={true}
          bold={true}
        />
      </th>
    </tr>
  </thead>
)

export default Head
