import Spotlights from '@horses/Spotlight/Spotlights'
import Verdict from '@components/HorseComponents/VerdictWithRPR/Verdict'
import { shouldAddToArray } from '@utils/utils'
import RaceCard from '../CompleteRaceCard/RaceCard'
import SelectionBoxes from '../SelectionsAndData/SelectionBoxes'

// Order the components by where you want them to be rendered.
// the position argument to getRaceElementHeight sets order in which they should be rendered in
// column. For example, verdict might be higher priority than betting forecast, but verdict goes after
// forecast in order of position. So we order measure them by priority first, then order by position

/*
  priority = {
    component: - Component To Render
    position: - Position to render component in paper
    props: props to pass to component.
    dontRemove: - dont remove this component from queue.
    mainComponent: - tells component queue to only add other components if this has been added.
  }
*/
const raceComponentPriority = ({
  showSpotlights = true,
  showVerdict = true,
  showSelectionBoxes = true,
  showDiomedSpotlights = false,
  showInlineSpotlights = false,
  showTips = true,
  verdictAtBottom = false,
  showMostTipped = true,
  showInlineTip = false,
} = {}) => [
  {
    component: RaceCard,
    dontRemove: true,
    renderIfAbandoned: true,
    position: 1,
    mainComponent: true,
    props: (
      getRaceElementHeight,
      {
        race,
        raceId,
        raceIndex,
        meeting,
        meetingAbandoned,
        shouldGreyoutTip,
        meetingColour,
      },
    ) => {
      return {
        raceIndex,
        race,
        raceId,
        showInlineSpotlights,
        showSpotlights,
        showDiomedSpotlights,
        showSelectionBoxes,
        showTips,
        showVerdict,
        meetingAbandoned,
        verdictAtBottom,
        shouldGreyoutTip,
        showMostTipped,
        showInlineTip,
        meetingColour,
        innerRef: (node, props, component, componentName) =>
          getRaceElementHeight({
            node,
            props,
            meeting,
            raceIndex,
            component,
            race,
            shouldGreyoutTip,
            meetingColour,
            componentName,
          }),
      }
    },
  },
  ...shouldAddToArray(showSpotlights && !showInlineSpotlights, {
    component: Spotlights,
    position: 2,
    props: (
      getRaceElementHeight,
      { race, raceId, raceIndex, meetingColour, meetingAbandoned, meeting },
    ) => {
      return {
        raceId,
        meetingAbandoned,
        colour: meetingColour,
        data: race.getSpotlights(),
        postDataHorse: race.getPostDataHorse(),
        rprHorse: race.getRPRHorse(),
        race,
        showDiomedSpotlights,
        showSelectionBoxes,
        verdictAtBottom,
        showVerdict,
        meetingColour,
        innerRef: (node, props, component, componentName) =>
          getRaceElementHeight({
            node,
            props,
            raceIndex,
            meeting,
            component,
            race,
            componentName,
            meetingColour,
          }),
      }
    },
  }),
  ...shouldAddToArray(showVerdict && verdictAtBottom, {
    component: Verdict,
    position: 3,
    props: (
      getRaceElementHeight,
      {
        race,
        raceIndex,
        meetingAbandoned,
        meeting,
        lastSetSelectionBoxesRemoved,
        isLastSet,
      },
    ) => {
      return {
        meetingAbandoned,
        race,
        verdictAtBottom,
        lastSetSelectionBoxesRemoved,
        isLastSet,
        showInlineSpotlights,
        showSpotlights,
        showInlineTip,
        showMostTipped,
        innerRef: (node, props, component, componentName) =>
          getRaceElementHeight({
            node,
            props,
            raceIndex,
            meeting,
            component,
            race,
            showInlineSpotlights,
            showSpotlights,
            showInlineTip,
            showMostTipped,
            componentName,
          }),
      }
    },
  }),
  ...shouldAddToArray(showSelectionBoxes, {
    component: SelectionBoxes,
    position: 4,
    props: (
      getRaceElementHeight,
      { race, raceId, raceIndex, meetingAbandoned, meeting },
    ) => {
      const meetingColour = meeting.getMeetingColour()
      return {
        meetingColour,
        raceId,
        showMostTipped,
        meetingAbandoned,
        data: race.getSpotlights(),
        postDataHorse: race.getPostDataHorse(),
        rprHorse: race.getRPRHorse(),
        race,
        showSelectionBoxes,
        innerRef: (node, props, component, componentName) =>
          getRaceElementHeight({
            node,
            props,
            raceIndex,
            meeting,
            component,
            race,
            meetingColour,
            componentName,
          }),
      }
    },
  }),
]

export default raceComponentPriority
