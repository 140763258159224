import React, { FunctionComponent } from 'react'
import SocketConnection from '@horses/SocketConnection/SocketConnection'
import NetworkConnection from '@horses/NetworkConnection/NetworkConnection'
import dayjs from 'dayjs'
import { inject, observer } from 'mobx-react'
import ScreenStore from '@stores/ScreenStore'

import RacingTypeHeader from './RacingTypeHeader'

import Time from '@horses/Time/Time'
import {
  InfoBarContainer,
  InfoBarDate,
  InfoBarPriceGuidance,
} from './InfoBar.css'

const InfoBar: FunctionComponent<{ screenStore?: ScreenStore }> = ({
  screenStore,
}) => (
  <InfoBarContainer>
    <RacingTypeHeader />
    <div>
      <InfoBarDate>
        {process.env.NODE_ENV === 'staging' && <span>STAGING: </span>}
        {process.env.NODE_ENV === 'test' && <span>DEV: </span>}
        {dayjs().format('dddd MMMM D, YYYY')}
        <Time />
      </InfoBarDate>
      <InfoBarPriceGuidance>
        {`${
          screenStore && screenStore.bookmakerId === 'TOALS'
            ? 'Prices shown are Toals Early Prices'
            : 'Prices are for guidance only'
        } - may differ at time of bet placement.`}
      </InfoBarPriceGuidance>
    </div>
    <NetworkConnection />
    <SocketConnection />
  </InfoBarContainer>
)

export default inject('screenStore')(observer(InfoBar))
