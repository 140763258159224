import React, { FunctionComponent, Fragment } from 'react'
import cx from 'classnames'
import { observer } from 'mobx-react'
import { IWeightRating } from '../RaceCardInterfaces/IWeightRating'
import CustomText from '@horses/CustomText/CustomText'
import PriceHistory from './PriceHistory'
import theme from '@styles/theme'
import OddsBox from './OddsBox'

const Weight: FunctionComponent<IWeightRating> = ({
  topRow,
  bottomRow,
  screenStore,
  placedHorse,
}) => {
  const { race, resultStartingPrice, favourite, shouldShowResult } = bottomRow

  const favCount = race.getFavoriteCount()
  return (
    <div
      className={cx('race-card__weight-stats-odds-wrapper', {
        ['race-card__non-runner-container']: bottomRow.nonRunner,
      })}
    >
      {
        <div className="race-card--flex race-card-weight">
          <CustomText
            text={topRow.headGear || ''}
            proximaCondSemiBold={true}
            size="smallest"
            noSpace={true}
            spaceLeft={true}
          />
          <CustomText
            text={topRow.headGearFirstTime ? '1' : ''}
            proximaCondSemiBold={true}
            size="smallest"
            light={true}
            noSpace={true}
            spaceLeft={true}
          />
          <div className="race-card__age-weight">
            <CustomText
              text={topRow.horseAge || ''}
              proximaCondSemiBold={true}
              size="info"
              noSpace={true}
              spaceLeft={true}
            />

            <CustomText
              text={topRow.jockeyWeight}
              proximaCondSemiBold={true}
              size="info"
              noSpace={true}
              spaceLeft={true}
              nowrap={true}
            />
          </div>
        </div>
      }
      {!placedHorse && bottomRow.nonRunner ? (
        <div className="race-card__price-container-non-runner">
          <CustomText
            text="NR"
            noSpace={true}
            size="xmedium"
            bold={true}
            proximaBlack={true}
          />
        </div>
      ) : (
        <Fragment>
          {shouldShowResult && race.raceHasResults() && (
            <div className="race-card__starting-price-container">
              {resultStartingPrice}
            </div>
          )}
          {!race.raceHasResults() && bottomRow.shouldShowPrices && (
            <OddsBox
              colour={
                (screenStore && screenStore.getBookmakerColor()) ||
                theme.colors.oddBoxRed
              }
              favourite={favourite}
              favCount={favCount}
              nonRunner={bottomRow.nonRunner}
              price={bottomRow.price}
            />
          )}
          {bottomRow.shouldShowPrices &&
            !race.raceHasResults() &&
            bottomRow.prices.length > 1 && (
              <PriceHistory prices={bottomRow.prices} />
            )}
        </Fragment>
      )}
    </div>
  )
}

export default observer(Weight)
