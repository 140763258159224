import styled, { css } from 'styled-components'
import theme from '@styles/theme'

interface IStyleProps {
  open?: boolean
  tippedHorse?: boolean | null
  showInlineSpotlights?: boolean | null
  nonRunner?: boolean
  showSpotlights?: boolean | undefined
  isLastRunnerGroup?: boolean | undefined
  runnerGroupIndex?: number | undefined
  meetingColour: string
  postDataHorse?: string | null
}

export const RaceCardWrapperStyles = styled.div<IStyleProps>`
  ${({
    open,
    tippedHorse,
    showInlineSpotlights,
    nonRunner,
    isLastRunnerGroup,
    runnerGroupIndex,
  }) =>
    open
      ? css`
          margin-left: 7px;
          margin-right: 7px;
          position: relative;
          margin-bottom: ${isLastRunnerGroup &&
          runnerGroupIndex === 1 &&
          tippedHorse
            ? '7px'
            : isLastRunnerGroup && runnerGroupIndex === 1
            ? ''
            : '1px'};

          &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            filter: blur(7px);
            background: black;
            z-index: 0;
            margin: 0 -17px;
          }
        `
      : isLastRunnerGroup && runnerGroupIndex === 1
      ? css`
          padding-bottom: 7px;
          border-bottom: 0;
          margin-left: 7px;
          margin-right: 7px;
        `
      : tippedHorse && !nonRunner
      ? css`
          background-color: ${theme.colors.tipping};
          padding-bottom: 0;
          border-bottom: 1px solid ${theme.colors.cardUnderlineColor};
          margin-bottom: -1px;
          margin-left: 7px;
          margin-right: 7px;
        `
      : !showInlineSpotlights
      ? css`
        border-bottom: 1px solid ${theme.colors.cardUnderlineColor}
        padding-bottom: 7px;
        margin-bottom: -1px;
        margin-left: 7px;
        margin-right: 7px;
      `
      : css`
          padding-bottom: 7px;
          border-bottom: 1px solid ${theme.colors.cardUnderlineColor};
          margin-bottom: -1px;
          margin-left: 7px;
          margin-right: 7px;
        `}
`

export const RaceCardWhiteBackgroundWrapper = styled.div<IStyleProps>`
  ${({ open, tippedHorse, meetingColour }) =>
    open
      ? css`
          border: 3px solid ${meetingColour};
          border-radius: 2px;
          background: #eeeeee;
          margin: -3px -17px 0 -17px;
          padding: -3px 17px -3px 17px;
          position: relative;
          z-index: 999;
          ${!tippedHorse
            ? css`
                padding: 0 14px 3px 14px;
              `
            : ''}
          ${tippedHorse
            ? css`
                margin-left: -17px;
                margin-right: -17px;
                padding-left: 14px;
                padding-right: 14px;
              `
            : ''}
        `
      : ''}
`
export const NewspaperTipsWrapperStyles = styled.div<IStyleProps>`
  ${({ open }) =>
    open
      ? css`
          position: relative;
          border-top: 1px solid black;
        `
      : css`
          border-bottom: postDataHorse? '':1px solid black;
          border-top: 1px solid black;

        `}
`

export const NewspaperTipsWhiteBackgroundWrapper = styled.div<IStyleProps>`
  ${({ open, meetingColour }) =>
    open
      ? css`
          > div {
            .count-col {
              color: white;
              background-color: ${meetingColour};
              border: 1px solid ${meetingColour};
              > div {
                border-right: 2px solid white;
                border-bottom: 2px solid white;
              }
            }
          }
        `
      : css`
          > div {
            .count-col {
              color: black;
              border: 1px solid black;
              > div {
                border-right: 2px solid #ed1c24;
                border-bottom: 2px solid #ed1c24;
              }
            }
          }
        `}
`
