import React, { FunctionComponent } from 'react'
import SVG from 'react-inlinesvg'

import snow from '@assets/weather/snowing.svg'
import rain from '@assets/weather/raining.svg'
import wind from '@assets/weather/windy.svg'
import clear from '@assets/weather/clear.svg'
import fog from '@assets/weather/fog.svg'
import partlyCloudy from '@assets/weather/cloudyAndSunny.svg'
import cloudy from '@assets/weather/overcast.svg'
import sleet from '@assets/weather/sleet.svg'
import clearNight from '@assets/weather/clearNight.svg'
import cloudyNight from '@assets/weather/cloudyNight.svg'

import './Header.scss'

interface IWeather {
  weather: string | null
}

const Weather: FunctionComponent<IWeather> = ({ weather }) => {
  let weatherIcon = null
  switch (weather) {
    case 'clear-day':
    case '(Mostly sunny)':
      weatherIcon = clear
      break
    case 'partly-cloudy-day':
    case '(Partly cloudy)':
    case '(Party cloudy)':
      weatherIcon = partlyCloudy
      break
    case 'rain':
    case '(Showers)':
      weatherIcon = rain
      break
    case 'snow':
      weatherIcon = snow
      break
    case 'sleet':
      weatherIcon = sleet
      break
    case 'wind':
      weatherIcon = wind
      break
    case 'fog':
      weatherIcon = fog
      break
    case 'cloudy':
    case '(Mostly cloudy)':
    case '(cloudy)':
      weatherIcon = cloudy
      break
    case 'clear-night':
      weatherIcon = clearNight
      break
    case 'partly-cloudy-night':
      weatherIcon = cloudyNight
      break
    default:
      weatherIcon = null
  }

  return weatherIcon ? (
    <SVG src={weatherIcon} className="header__weather--img" />
  ) : null
}

export default Weather
