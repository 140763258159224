import React, { FunctionComponent } from 'react'
import { observer } from 'mobx-react'
import { ISpotlight } from './SpotlightGroup'
import GreyoutRP from '@utils/GreyoutRP'
import Spotlight from './Spotlight'
import AbandonedRaceStore from '@stores/AbandonedRaceStore'

const SpotlightGroupChild: FunctionComponent<ISpotlight> = ({
  race,
  spotlightGroup,
  spotlightGroupIndex,
  showDiomedSpotlights,
  tippedHorse,
}) => (
  <GreyoutRP
    greyoutCondition={
      race.hasRaceFinished() ||
      race.raceHasResults() ||
      AbandonedRaceStore.isRaceAbandoned(race.getRaceInstanceUid())
    }
  >
    {spotlightGroup.map((spotlight, index) => (
      <Spotlight
        showDiomedSpotlights={showDiomedSpotlights}
        spotlightGroupIndex={spotlightGroupIndex}
        item={spotlight}
        raceId={race.getRaceInstanceUid().toString()}
        index={index}
        key={spotlight.diomed}
        tippedHorse={tippedHorse}
      />
    ))}
  </GreyoutRP>
)

export default observer(SpotlightGroupChild)
