import React, { FunctionComponent, useState } from 'react'
import {
  NewspaperBackgroundWrapper,
  PopupHeaderNewspaper,
  CloseButton,
} from '../Popup.css'
import CustomText from '@components/HorseComponents/CustomText/CustomText'
import IdleTimer from 'react-idle-timer'

interface IPopupNewspaperProps {
  content: JSX.Element
  title: string
  onClose: () => void
  closeAfter?: number
  meetingColour?: string
}

const MostTippedPopup: FunctionComponent<IPopupNewspaperProps> = ({
  content,
  title,
  onClose,
  closeAfter,
  meetingColour,
}) => {
  const [popupRef, setPopupRef] = useState<HTMLDivElement | null>(null)
  return (
    <NewspaperBackgroundWrapper
      ref={ref => setPopupRef(ref)}
      meetingColour={meetingColour}
    >
      {popupRef && closeAfter && (
        <IdleTimer timeout={closeAfter} onIdle={onClose} element={popupRef} />
      )}

      <PopupHeaderNewspaper meetingColour={meetingColour}>
        <CustomText
          text={title}
          proximaCondBold={true}
          uppercase={false}
          size="big"
        />
        <CloseButton onClick={onClose} meetingColour={meetingColour} />
      </PopupHeaderNewspaper>
      {content}
    </NewspaperBackgroundWrapper>
  )
}

export default MostTippedPopup
