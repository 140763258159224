import React, { FunctionComponent, Fragment } from 'react'
import { TInnerRef } from '@models/InnerRef'
import Race from '@classes/Race'
import SingleRunner from '@classes/Runner'
import Runner from './Runner'
import { inject, observer } from 'mobx-react'
import ScreenStore from '@stores/ScreenStore'
import FooterAndBettingForecast from './FooterAndBettingForecast'

interface IRunnerGroup {
  innerRef: TInnerRef
  runnerGroup: SingleRunner[]
  race: Race
  realRender?: boolean
  isLastRunnerGroup: boolean
  raceDateTime: string | null
  raceDiffusionName: string | null
  raceIndex: number
  runnerGroupIndex: number
  showSpotlights: boolean
  showDiomedSpotlights: boolean
  showSelectionBoxes: boolean
  showTips: boolean
  showVerdict: boolean
  meetingAbandoned: boolean
  showInlineSpotlights: boolean
  screenStore?: ScreenStore
  verdictAtBottom: boolean
  meetingColour: string
  showInlineTip: boolean
  columnIndex?: number
}

const RunnerGroup: FunctionComponent<IRunnerGroup> = ({
  innerRef,
  race,
  runnerGroup,
  realRender,
  raceDateTime,
  raceDiffusionName,
  raceIndex,
  isLastRunnerGroup,
  runnerGroupIndex,
  showSpotlights,
  showDiomedSpotlights,
  showSelectionBoxes,
  showTips,
  showVerdict,
  meetingAbandoned,
  meetingColour,
  showInlineSpotlights,
  screenStore,
  verdictAtBottom,
  showInlineTip,
  columnIndex,
}) => {
  return (
    <div
      ref={ref =>
        innerRef(
          ref,
          {
            innerRef,
            race,
            runnerGroup,
            runnerGroupIndex,
            raceIndex,
            isLastRunnerGroup,
            realRender,
            showSpotlights,
            showDiomedSpotlights,
            showSelectionBoxes,
            showTips,
            showVerdict,
            meetingAbandoned,
            showInlineSpotlights,
            screenStore,
            verdictAtBottom,
            showInlineTip,
            meetingColour,
            columnIndex,
          },
          observer(RunnerGroup),
        )
      }
      style={{
        overflow: realRender ? 'visible' : 'hidden',
      }}
    >
      <Fragment>
        {runnerGroup.map((runner, index) => (
          <Runner
            raceDateTime={raceDateTime}
            raceCourse={raceDiffusionName}
            key={runner.getHorseId()}
            runner={runner}
            race={race}
            runnerIndex={index}
            raceIndex={raceIndex}
            runnerGroupIndex={runnerGroupIndex}
            realRender={realRender}
            showSpotlights={showSpotlights}
            showDiomedSpotlights={showDiomedSpotlights}
            showTips={showTips}
            showVerdict={showVerdict}
            meetingAbandoned={meetingAbandoned}
            showInlineSpotlights={showInlineSpotlights}
            screenStore={screenStore}
            verdictAtBottom={verdictAtBottom}
            showInlineTip={showInlineTip}
            isLastRunnerGroup={isLastRunnerGroup}
            meetingColour={meetingColour}
            columnIndex={columnIndex}
          />
        ))}
        {isLastRunnerGroup && (
          <FooterAndBettingForecast
            race={race}
            showSpotlights={showSpotlights}
          />
        )}
      </Fragment>
    </div>
  )
}

export default inject('screenStore')(observer(RunnerGroup))
