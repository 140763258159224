import head from 'lodash/head'
import last from 'lodash/last'
import countBy from 'lodash/countBy'
import { FunctionComponent, ReactNode } from 'react'

export interface IPriority {
  component: FunctionComponent | ReactNode
  position?: number
  props(): void
}

type IPriorityCallback = (
  queue: IPriority[],
) => IPriority[] | IPriority | undefined

interface IPriorityOptions {
  initWithFirstComponent?: boolean
  priorityList: IPriority[]
  deleteOnPush?: boolean
  reverse?: boolean
}

interface IPriorityProtocol {
  hasComponentsLeft(): boolean
  pushNextComponentToQueue(): void
  getPriorityQueue(
    callback: IPriorityCallback,
  ): IPriority[] | IPriority | undefined
}

class Priority implements IPriorityProtocol {
  private componentsToRender: IPriority[]
  private deleteOnPush: boolean
  private waitingComponents: IPriority[]
  private reverse: boolean = false

  constructor(options: IPriorityOptions) {
    const {
      priorityList,
      initWithFirstComponent = true,
      deleteOnPush = false,
      reverse = false,
    } = options
    this.componentsToRender = reverse ? priorityList : []
    this.deleteOnPush = deleteOnPush
    this.waitingComponents = reverse ? [] : priorityList
    this.reverse = reverse
    if (initWithFirstComponent) {
      this.pushNextComponentToQueue()
    }
  }

  public setPriorityList = (list: IPriority[]) => {
    this.componentsToRender = list
  }

  public hasComponentsLeft = (): boolean => {
    const arrayToCheck = this.reverse
      ? this.componentsToRender
      : this.waitingComponents
    const dontRemoveCount = countBy(arrayToCheck, 'dontRemove')
    const removeCount =
      dontRemoveCount && dontRemoveCount.true ? dontRemoveCount.true : 0
    return arrayToCheck.length !== removeCount
  }

  public pushNextComponentToQueue = (): void => {
    const nextComponent = head(this.waitingComponents)
    if (this.deleteOnPush) {
      this.componentsToRender = []
    }

    if (nextComponent) {
      this.componentsToRender.push(nextComponent)
      this.waitingComponents.splice(0, 1)
    }
  }

  public deleteComponentFromQueue = () => {
    const lastComponent = last(this.componentsToRender)
    if (lastComponent && this.hasComponentsLeft()) {
      const index = this.componentsToRender.length - 1
      this.componentsToRender.splice(index, 1)
    }
  }

  public getPriorityQueue = (
    callback: IPriorityCallback,
  ): IPriority | IPriority[] | undefined => {
    if (callback) {
      return callback(this.componentsToRender)
    }
    return this.componentsToRender
  }
}

export default Priority
