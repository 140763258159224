import React, { FunctionComponent } from 'react'
import cx from 'classnames'
import CustomText from '@horses/CustomText/CustomText'
import { IIndividualSpotlight } from './ISpotlight'
import SpotlightLogo from './SpotlightLogo'
import NonRunnersStore from '@stores/NonRunnersStore'
import colors from '@styles/colors.scss'

const Spotlight: FunctionComponent<IIndividualSpotlight> = ({
  item,
  showDiomedSpotlights,
  spotlightGroupIndex,
  colour,
  index,
  raceId,
  inline,
  tippedHorse,
}) => {
  const nonRunner = NonRunnersStore.isNonRunner(raceId, item.horse_id)
  const spotlightText = showDiomedSpotlights
    ? item.diomed
    : item.spotlight || item.prime

  if (spotlightText) {
    const parsedSpotlightText = `${
      spotlightText[spotlightText.length - 1] === '.'
        ? spotlightText.slice(0, spotlightText.length - 1)
        : spotlightText
    }.`
    return (
      <div key={item.horse_id}>
        {!inline && spotlightGroupIndex === 0 && index === 0 && (
          <SpotlightLogo colour={colour} />
        )}
        <div
          style={{
            marginLeft: tippedHorse ? '-14px' : '',
            marginRight: tippedHorse ? '-14px' : '',
            paddingLeft: tippedHorse ? '14px' : '',
            paddingRight: tippedHorse ? '14px' : '',
            paddingBottom: tippedHorse ? '7px' : '',
            backgroundColor:
              tippedHorse && !nonRunner ? colors.tippingBackground : '',
          }}
          className={cx(
            { ['spotlight__indent-container']: !inline },
            {
              ['spotlight__inline-spotlight']: inline,
            },
            {
              'spotlight__non-runner': nonRunner,
            },
          )}
        >
          <CustomText
            text={inline ? 'Spotlight:' : `${item.horse_name} `}
            size="raceboard"
            inline={true}
            fontBold={true}
          />
          <CustomText
            text={parsedSpotlightText}
            fontTertiary={true}
            size="raceboard"
            inline={true}
          />
        </div>
      </div>
    )
  }
  return null
}

export default Spotlight
