import React, { FunctionComponent } from 'react'
import dayjs from 'dayjs'
import map from 'lodash/map'
import orderBy from 'lodash/orderBy'
import isEmpty from 'lodash/isEmpty'
import compact from 'lodash/compact'
import Head from './Table/Head'
import Container from '@horses/Container/Container'
import { ITopDraw } from './ITopDraw'
import './TopDraw.scss'

const TopDraw: FunctionComponent<ITopDraw> = ({
  data,
  innerRef,
  courseName,
  colour,
}) => {
  if (data && !isEmpty(compact(data))) {
    const racesByTime = orderBy(compact(data), 'race_datetime', ['desc'])
    return (
      <Container
        colour={colour}
        title={`${courseName} TOPDRAW`}
        innerRef={ref =>
          innerRef(ref, { data, innerRef, courseName, colour }, TopDraw)
        }
      >
        <div>
          <table className="top-draw__section">
            <tbody>
              <Head />
              {map(racesByTime, race => (
                <tr key={race.race_instance_uid}>
                  <td>
                    <span className="table--bold">
                      {dayjs(race.race_datetime).format('h.mm')}
                    </span>
                  </td>
                  <td className="table--center">{race.declared_runners}</td>
                  <td className="table--center">{race.distance}</td>
                  <td className="table--center">{race.stalls}</td>
                  <td className="table--center">{race.low_wins}</td>
                  <td className="table--center">{race.mid_wins}</td>
                  <td className="table--center">{race.high_wins}</td>
                  <td className="table--center">{race.races}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </Container>
    )
  }
  return null
}

export default TopDraw
