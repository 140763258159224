import Container from '@horses/Container/Container'
import React, { FunctionComponent } from 'react'
import { ITopTrainers } from './ITopTrainers'
import Head from './Table/Head'
import Body from './Table/Body'

import './TopTrainers.scss'

const TopTrainers: FunctionComponent<ITopTrainers> = ({
  dataForYearsSince,
  meetingType,
  courseName,
  innerRef,
  colour,
  topTrainers,
}) => {
  if (topTrainers && topTrainers.length) {
    return (
      <Container
        colour={colour}
        title={`${courseName} TOP TRAINERS`}
        innerRef={ref =>
          innerRef(
            ref,
            {
              innerRef,
              colour,
              courseName,
              meetingType,
              dataForYearsSince,
              topTrainers,
            },
            TopTrainers,
          )
        }
      >
        <table>
          <Head
            dataForYearsSince={dataForYearsSince || 0}
            courseType={meetingType}
          />
          <Body topTrainers={topTrainers} />
        </table>
      </Container>
    )
  }
  return null
}

export default TopTrainers
