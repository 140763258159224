import React, { Fragment, FunctionComponent } from 'react'
import './RaceCardStyles/RaceCardStyles.scss'
import { IRaceCard } from './RaceCardInterfaces/ICompleteRaceCard'
import RunnerGroup from './RaceCardComponents/RunnerGroup'
import NoRunners from '@horses/NoRunners/NoRunners'
import RaceBoard from './RaceBoard'
import { observer } from 'mobx-react'
import GreyoutRP from '@utils/GreyoutRP'

const RaceCard: FunctionComponent<IRaceCard> = ({
  race,
  realRender,
  innerRef,
  raceIndex,
  showSpotlights,
  showDiomedSpotlights,
  showSelectionBoxes,
  showTips,
  showVerdict,
  meetingAbandoned,
  showInlineSpotlights,
  verdictAtBottom,
  meetingColour,
  showInlineTip,
}) => {
  const runners = race.getRunnerPairs()
  const raceDateTime = race.getRaceTime()
  const raceDiffusionName = race.getDiffusionName()
  const raceAbandoned = race.isRaceAbandoned()
  const shouldRenderRunnerGroup =
    !!runners.length && !meetingAbandoned && !raceAbandoned

  return (
    <Fragment>
      {shouldRenderRunnerGroup &&
        runners.map((runnerGroup, runnerGroupIndex) => (
          <RunnerGroup
            key={
              runnerGroup[0]
                ? runnerGroup[0].getPriceId()
                : raceDateTime + raceDiffusionName
            }
            raceDateTime={raceDateTime}
            raceDiffusionName={raceDiffusionName}
            runnerGroup={runnerGroup}
            runnerGroupIndex={runnerGroupIndex}
            isLastRunnerGroup={runnerGroupIndex === runners.length - 1}
            race={race}
            innerRef={innerRef}
            raceIndex={raceIndex}
            realRender={realRender}
            showSpotlights={showSpotlights}
            showDiomedSpotlights={showDiomedSpotlights}
            showSelectionBoxes={showSelectionBoxes}
            showTips={showTips}
            showVerdict={showVerdict}
            meetingAbandoned={meetingAbandoned}
            showInlineSpotlights={showInlineSpotlights}
            verdictAtBottom={verdictAtBottom}
            showInlineTip={showInlineTip}
            meetingColour={meetingColour}
          />
        ))}
      {!shouldRenderRunnerGroup && (
        <div
          ref={ref =>
            innerRef(
              ref,
              { race, innerRef, raceIndex, meetingAbandoned, meetingColour },
              observer(RaceCard),
            )
          }
        >
          <GreyoutRP greyoutCondition={true}>
            <RaceBoard
              realRender={realRender}
              race={race}
              raceIndex={raceIndex}
            />
            <NoRunners
              message={
                raceAbandoned
                  ? 'RACE ABANDONED'
                  : 'NO RUNNERS - MEETING POSSIBLY ABANDONED'
              }
            />
          </GreyoutRP>
        </div>
      )}
    </Fragment>
  )
}

export default observer(RaceCard)
