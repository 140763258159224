import React, { FunctionComponent } from 'react'
import CustomText from '@horses/CustomText/CustomText'
import { formattedJockeyWeight } from '@utils/utils'
import dayjs from 'dayjs'
import { IRaceCardFooter } from './RaceCardInterfaces/IRaceCardFooter'

import './RaceCardStyles/RaceCardFooter.scss'

const RaceCardFooter: FunctionComponent<IRaceCardFooter> = ({ data }) =>
  data && (
    <div className="race-card__footer">
      <div className="race-card__footer--flex">
        {data.race_datetime && (
          <CustomText
            text={dayjs(data.race_datetime).format('YYYY')}
            fontTertiary={true}
            inline={true}
            size="normal"
            bold={true}
          />
        )}
        {data.horse_style_name && (
          <CustomText
            text={data.horse_style_name}
            fontTertiary={true}
            inline={true}
            size="normal"
            bold={true}
          />
        )}
        {
          <CustomText
            text={`${data.trainer_style_name} ${data.horse_age}
            ${
              data.weight_carried_lbs
                ? formattedJockeyWeight(data.weight_carried_lbs)
                : ''
            }
            ${data.odds_desc}
            `}
            fontTertiary={true}
            size="normal"
          />
        }
      </div>
      <div className="race-card__footer--flex">
        {data.jockey_style_name && (
          <CustomText
            text={data.jockey_style_name}
            fontTertiary={true}
            inline={true}
            size="normal"
          />
        )}
        {data.rp_postmark && (
          <CustomText
            text={`RPR${data.rp_postmark}`}
            fontTertiary={true}
            inline={true}
            size="normal"
          />
        )}
      </div>
    </div>
  )

export default RaceCardFooter
