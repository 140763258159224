import { infoandheaderheight } from './styles/vars.scss'
export const MAX_COLS_PER_SCREEN = 8
export const DEFAULT_SCREEN_AMOUNT = 4
export const LOWEST_AD_SIZE = 250
export const MAX_WIDTH = window.innerWidth / MAX_COLS_PER_SCREEN
export const MAX_WIDTH_PERCENTAGE = (100 * MAX_WIDTH) / window.innerWidth
export const EIGHTH_OF_SCREEN = 12.5
export const THREE_QUARTERS_OF_SCREEN = 75
// 65 is to fix the measurement issues, think it's to do with elements with marginTop/bottom
export const MAX_SCREEN_SIZE =
  window.innerHeight - Number(infoandheaderheight) - 65

export const KON_CODE = [
  'ArrowUp',
  'ArrowUp',
  'ArrowDown',
  'ArrowDown',
  'ArrowLeft',
  'ArrowRight',
  'ArrowLeft',
  'ArrowRight',
  'KeyB',
  'KeyA',
  'Enter',
]

const protocol = location.protocol

const getUrl = () => {
  if (typeof process.env.RACINGPOST_BACKEND_PUBLIC_URL === 'string') {
    return `${protocol}//${process.env.RACINGPOST_BACKEND_PUBLIC_URL}`
  }

  switch (process.env.NODE_ENV) {
    case 'development':
      return 'http://localhost:8181'
    case 'test':
      return `${protocol}//not-used`
    case 'production':
      return `${protocol}//bsd.racingpost.com`
    default:
      return `${protocol}//bsd.staging-racingpost.com`
  }
}

export const HOST_URL = getUrl()
export const ANALYTICS_URL = `${HOST_URL}/api/analytics`
export const API_URL = `${HOST_URL}/api/bsd/horses`
export const API_GH_URL = `${HOST_URL}/api/bsd/greyhounds`
export const RESULTS_URL = `${HOST_URL}/api/results`
export const NON_RUNNER_URL = `${HOST_URL}/api/non-runners`
export const GH_RESULTS_URL = `${RESULTS_URL}/greyhounds`
export const HORSE_RESULTS_URL = `${RESULTS_URL}/horses`
export const STATUS_URL = `${HOST_URL}/api/raceStatuses`
export const SLIM_TIPSTER_URL = `${protocol}//tipster.racingpost.com/slim/`
const getDetailedFormUrl = () => {
  switch (process.env.NODE_ENV) {
    // TODO TEMP WHILE STAGING IS BROKE
    // case 'development':
    //   return `${protocol}//tipster.staging-racingpost.com/detailedform`
    // case 'test':
    //   return `${protocol}//tipster.staging-racingpost.com/detailedform`
    // case 'production':
    //   return `${protocol}//tipster.racingpost.com/detailedform`
    default:
      return `${protocol}//tipster.racingpost.com/detailedform`
  }
}

export const DETAILED_FORM_URL = getDetailedFormUrl()
export const HORSE_ODDS_TABLE_TARGET = 'Diffusion_Horses'
export const HORSE_STATUS_TABLE_TARGET = 'Diffusion_Horses_Status'
export const GREYHOUNDS_ODDS_TABLE_TARGET = 'Diffusion_Greyhounds'
export const GREYHOUNDS_STATUS_TABLE_TARGET = 'Diffusion_Greyhounds_Status'
export const DEFAULT_BOOKMAKER_ID = '#BESTODDS'

export const HEIGHT_4K = 2160
export const WIDTH_4K = 3840

export const SENTRY_DSN =
  'https://3bc8af3e66ab4ae98bc2d28d25883e00@sentry.korelogic.co.uk/4'
