import React, { Component } from 'react'
import TimeoutLoop from '@classes/TimeoutLoop'
import {
  ClockContainer,
  Clock,
  Hours,
  Minutes,
  Pin,
  ClockColon,
  CurrentTime,
} from './Time.css'

interface ITimeState {
  date: Date
}

class Time extends Component<{}, ITimeState> {
  private timeoutLoop: TimeoutLoop
  constructor(props: {}) {
    super(props)
    this.timeoutLoop = new TimeoutLoop(this.setDate, 10000, false)
    this.state = {
      date: new Date(),
    }
  }

  public componentDidMount() {
    this.timeoutLoop.init()
  }

  public render() {
    const { date } = this.state
    const hour = date.getHours()
    const hours = hour > 12 ? hour - 12 : hour
    const minutes = date.getMinutes()

    const minutesZeroPad = `${minutes <= 9 ? `0${minutes}` : minutes}`
    return (
      <ClockContainer>
        <Clock>
          <Hours rotate={hours * 30 + minutes * 0.5} />
          <Minutes rotate={minutes * 6} />
          <Pin />
        </Clock>
        <CurrentTime>
          {hours}
          <ClockColon>{':'}</ClockColon>
          {minutesZeroPad}
        </CurrentTime>
      </ClockContainer>
    )
  }

  private setDate = () => {
    const date = new Date()
    this.setState({
      date,
    })
  }
}

export default Time
