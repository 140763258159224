import Meeting from './Meeting'
import filter from 'lodash/filter'
import map from 'lodash/map'
import compact from 'lodash/compact'
import SingleSet from './Set'

class Sets {
  private sets: SingleSet[] = []
  private activeSetIndex: number = 0
  private removalIndex: number

  constructor(sets: Meeting[][]) {
    const allSets = map(sets, set => new SingleSet(set))
    this.sets = allSets
    this.removalIndex = this.sets.length - 1
  }

  public incrementActiveSetIndex = () => {
    this.activeSetIndex += 1
  }

  public decrementRemovalIndex = () => {
    this.removalIndex = this.removalIndex - 1
  }

  public reset = () => {
    this.activeSetIndex = 0
    this.sets.forEach(set => {
      set.reset()
    })
  }

  public resetRemovalIndex = () => {
    this.removalIndex = this.sets.length - 1
    this.sets.forEach(set => {
      set.resetRemovalIndex()
    })
  }

  public getActiveSet = () =>
    this.sets && this.sets.length
      ? this.sets[this.activeSetIndex] || null
      : null

  public getRemovalSet = () =>
    this.sets && this.sets.length ? this.sets[this.removalIndex] || null : null

  public getLastSet = () =>
    this.sets && this.sets.length ? this.sets[this.sets.length - 1] : null

  public getFirstSet = () =>
    this.sets && this.sets.length ? this.sets[0] : null

  public removeStats = () => {
    const set = this.getRemovalSet()
    if (set && set.allStatsAreRemoved()) {
      this.decrementRemovalIndex()
      this.removeStats()
    } else if (set) {
      set.removeStatFromMeeting()
    }

    if (this.haveAllStatsHaveBeenRemoved()) {
      this.resetRemovalIndex()
    }
  }

  public removeSecondCourseMap = () => {
    const set = this.getRemovalSet()
    if (set) {
      set.removeSecondCourseMap()
    }
  }

  public diomeSpotlights = () => {
    const set = this.getRemovalSet()
    if (set) {
      set.setAllMeetingsDiomed()
      this.decrementRemovalIndex()
    }
  }

  public removeLastSetSpotlights = () => {
    const set = this.getLastSet()
    if (set) {
      set.removeSpotlights()
    }

    this.resetRemovalIndex()
  }

  public removeLastSetSelectionBoxes = () => {
    const set = this.getLastSet()
    if (set) {
      set.removeSelectionBoxes()
    }
  }

  public removeLastSetVerdicts = () => {
    const set = this.getLastSet()
    if (set) {
      set.removeVerdicts()
    }

    this.resetRemovalIndex()
  }

  public hasLastSetSpotlightsRemoved = () => {
    const set = this.getLastSet()
    return set ? set.spotlightsRemoved : false
  }

  public hasFirstSetSpotlightsRemoved = () => {
    const set = this.getFirstSet()
    if (set) {
      return set.hasAllSpotlightsRemovedPerMeeting()
    }
    return false
  }

  public hasLastSetSelectionBoxesRemoved = () => {
    const set = this.getLastSet()
    return set ? set.selectionBoxesRemoved : false
  }

  public hasLastSetVerdictsRemoved = () => {
    const set = this.getLastSet()
    return set ? set.verdictsRemoved : false
  }

  public hasLastSetHadAllRacesRemoved = () => {
    const set = this.getLastSet()
    return set ? set.haveAllRacesBeenRemoved() : false
  }

  public hasFirstSetHadAllRacesRemoved = () => {
    const set = this.getFirstSet()
    return set ? set.haveAllRacesBeenRemoved() : false
  }

  public haveAllStatsHaveBeenRemoved = () => {
    const stats = filter(this.sets, set => !set.allStatsAreRemoved())
    return stats.length === 0
  }

  public allSetsHaveBeenDiomed = () => {
    const sets = filter(this.sets, set => !set.diomed)
    return sets.length === 0
  }

  public removeFirstSetSpotlights = () => {
    const firstSet = this.getFirstSet()

    if (firstSet) {
      firstSet.removeSpotlightsFromMeeting()
    }

    if (this.hasFirstSetSpotlightsRemoved() && firstSet) {
      firstSet.resetRemovalIndex()
    }
  }

  public removeTipsFromLastSet = () => {
    const lastSet = this.getLastSet()

    if (lastSet) {
      lastSet.removeTips()
    }
  }

  public hasLastSetTipsBeenRemoved = () => {
    const lastSet = this.getLastSet()

    return lastSet ? lastSet.tipsRemoved : false
  }

  public removeRaceFromLastSet = () => {
    const lastSet = this.getLastSet()
    if (lastSet) {
      lastSet.removeRaceFromMeeting()
    }
  }

  public removeRaceFromFirstSet = () => {
    const firstSet = this.getFirstSet()
    if (firstSet) {
      firstSet.removeRaceFromMeeting()
    }
  }

  public isLastSet = () => {
    if (this.sets && this.sets.length) {
      const lastSetIndex = this.sets.length - 1
      return this.activeSetIndex === lastSetIndex
    }
    return true
  }

  public getAllRemovedRaces = () => {
    const allRemovedRaces = map(this.sets, set =>
      compact(set.getRemovedRaces()),
    )
    return compact(allRemovedRaces)
  }
}

export default Sets
