import React, { FunctionComponent, SyntheticEvent } from 'react'
import fallbackTipsterImg from '@assets/tipper-noPhoto.png'
import { ITip } from './ITipping'

interface ITipProps {
  tip: ITip
}

const Tips: FunctionComponent<ITipProps> = ({ tip }) => {
  const verdict = tip.verdict.replace(/\\n/g, '')
  return (
    <div>
      <div className="tipping__title">
        <p className="tipping__title-text">{`${tip.tipster_name.toUpperCase()} TIP`}</p>
      </div>
      <div className="tip__info">
        <img
          src={`https://images.racingpost.com/tipsters/${tip.tipster_uid}.png`}
          onError={(event: SyntheticEvent) => {
            const target = event.target as HTMLImageElement
            target.src = fallbackTipsterImg
          }}
          className="tip__img"
        />

        <div className="tip__text">
          <p className="tip__horse-name">{tip.horse_name.toUpperCase()}</p>
          {verdict && <p className="tip__verdict">{verdict}</p>}
        </div>
      </div>
    </div>
  )
}

export default Tips
