import React, { FunctionComponent } from 'react'
import { observer } from 'mobx-react'
import { IOffCountDownProps } from './IOffCountDown'
import './OffCountDown.scss'
import cx from 'classnames'
import AbandonedRaceStore from '@stores/AbandonedRaceStore'

const OffCountDown: FunctionComponent<IOffCountDownProps> = ({ race }) => {
  const countdown = race.getRaceCountdown()
  const hasResults = race.raceHasResults()
  const status = race.getRaceStatus()
  const lateAbandoned = AbandonedRaceStore.isRaceAbandoned(
    race.getRaceInstanceUid(),
  )
  const abandonedInAdvance = race.isRaceAbandoned()
  if (
    (countdown && countdown.countdownMin > 60 && !lateAbandoned) ||
    abandonedInAdvance
  ) {
    return null
  }
  const setCountdownTime = () => {
    if (lateAbandoned) {
      return 'LATE ABANDONED'
    }
    if (status && !hasResults) {
      return status === 'FINISHED' ? '' : status
    }
    if (countdown) {
      const { countdownMin, countdownSec } = countdown
      if (countdownMin < 1 && countdownSec > 0) {
        return `OFF IN ${countdownSec} SECOND${countdownSec > 1 ? 'S' : ''}`
      } else if (countdownMin >= 1) {
        return `OFF IN ${countdownMin} MIN${countdownMin > 1 ? 'S' : ''}`
      }
    }
    return hasResults ? '' : status
  }

  const countdownSentence = setCountdownTime()
  const isRaceOff = countdownSentence === 'OFF'

  return (
    <div
      className={cx('off-countdown--container', {
        'off-countdown--animation': !isRaceOff && !lateAbandoned,
      })}
    >
      {countdownSentence}
    </div>
  )
}
export default observer(OffCountDown)
