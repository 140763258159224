import { observable, action } from 'mobx'
import { Diffusion, Sports, Status, Odd } from 'rp-diffusion'

export interface IStatusConnection {
  on: (status: Status) => void
}

export interface IOddsConnection {
  on: (odd: Odd) => void
  bookmaker: string
}

class DiffusionSocket {
  @observable public networkConnectionActive: boolean = navigator.onLine
  @observable public socketConnectionActive: boolean = false
  private diffusion: Diffusion
  private connection: Promise<void>

  constructor() {
    this.diffusion = new Diffusion()

    const onDisconnect = () => this.setConnectionStatus(false)
    const onReconnect = () => this.setConnectionStatus(true)

    this.connection = this.diffusion
      .connect({ onDisconnect, onReconnect })
      .then(() => {
        this.setConnectionStatus(true)
      })

    window.addEventListener('online', this.onConnectionChange)
    window.addEventListener('offline', this.onConnectionChange)
  }

  public addStatusConnection = async ({ on }: IStatusConnection) => {
    await this.connection

    this.diffusion.addStatusSubscription({
      on,
      sport: Sports.Horses,
    })
  }

  public addOddsConnection = async ({ on, bookmaker }: IOddsConnection) => {
    await this.connection

    this.diffusion.addOddsSubscription({
      on,
      bookmaker,
      sport: Sports.Horses,
    })
  }

  @action private setConnectionStatus = (connected: boolean) => {
    this.socketConnectionActive = connected
  }

  @action private onConnectionChange = (event: Event) => {
    const isOnline = event.type === 'online'
    this.networkConnectionActive = isOnline
  }
}

export default new DiffusionSocket()
