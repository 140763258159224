import React, { Fragment, FunctionComponent } from 'react'
import { inject, observer } from 'mobx-react'
import Column from '@horses/Column/Column'
import Header from '@horses/Header/Header'
import { IAllColumns } from './IAllColumns';
import ColumnClass from '@classes/Column';

const AllColumns: FunctionComponent<IAllColumns> = ({ screenStore }) => {
  return (
    <Fragment>
      {(screenStore && screenStore.activeScreen)
        ? screenStore.activeScreen.map((column: ColumnClass, index: number) => (
          <div key={column.uuid}>
            <Header
              column={column}
              columnIndex={index}
            />
            <Column
              column={column} 
              columnIndex={index}/>
          </div>
        ))
        : null}
    </Fragment>
  )
}


export default inject('screenStore')(observer(AllColumns))
