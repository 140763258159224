import get from 'lodash/get'
import keyBy from 'lodash/keyBy'
import { HORSE_RESULTS_URL } from '@config'
import { observable, action } from 'mobx'
import forEach from 'lodash/forEach'
import axios from 'axios'

interface IResultRunner {
  horse_name: string
  jockey_name: string
  odds_desc: string
  race_outcome_position: number
  saddle_cloth_number: number
}
interface IRunners {
  [horseName: string]: IResultRunner
}

interface IResult {
  '2nd_favourite': string
  horses: IRunners
}

interface IResults {
  [raceId: string]: IResult
}

class ResultsStore {
  @observable public results: IResults = {}

  public getResults = async () => {
    const response = await axios.get(HORSE_RESULTS_URL)
    const json = await response.data
    const results: IResults | null = get(json, 'data', null) || null
    if (results) {
      const resultsByRaceInstanceUid = keyBy(results, 'race_instance_uid')
      forEach(resultsByRaceInstanceUid, result => {
        // store in lowercase incase of capitialisation mismatch
        result.horses = keyBy(result.horses, horse =>
          horse.horse_name.toLowerCase(),
        )
        if (result['2nd_favourite']) {
          // 2nd_favourite = 'Horse Name 3/1 2ndF'
          const secondFavHorseName = result['2nd_favourite']
            .split(/ [0-9]*\//)[0]
            .toLowerCase()

          if (result.horses[secondFavHorseName]) {
            result.horses[secondFavHorseName].odds_desc += ' 2F'
          }
        }
      })
      this.setResults(resultsByRaceInstanceUid)
    }
  }

  @action
  private setResults = (results: IResults) => {
    for (const [key, result] of Object.entries(results)) {
      this.results[key] = result
    }
  }
}

export default ResultsStore
