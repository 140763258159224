import React, { Component } from 'react'
import { inject } from 'mobx-react'
import PopupStore from '@stores/PopupStore'
import { FIVE_SECONDS } from '@utils/utils'
import ReactGA from 'react-ga'
import {
  NewspaperTipsWrapperStyles,
  NewspaperTipsWhiteBackgroundWrapper,
} from '../PopupClickWrapperStyles.css'
import Si9n from '@utils/Si9n'

interface IProps {
  realRender?: boolean | undefined
  raceId: number
  horseName: string
  tippedHorse?: boolean | null
  disable: boolean
  showInlineSpotlights?: boolean | undefined
  nonRunner?: boolean | undefined
  showSpotlights?: boolean | undefined
  isLastRunnerGroup?: boolean | undefined
  runnerGroupIndex?: number | undefined
  analyticsLabel: string
  content?: JSX.Element | undefined
  title?: string
  section?: string
  meetingColour: string
  postDataHorse?: string | null
  runnerInfo?: {
    courseName: string
    raceTime: string | null
    silk: string | null
  }
  runnerColumnIndex?: number
  raceStartColumnIndex?: number
}

interface IInjectedProps extends IProps {
  popupStore: PopupStore
}

class PopupClickWrapperTips extends Component<IProps> {
  public state = {
    racecardPositionLeft: 0,
    racecardPositionTop: 0,
    racecardPositionBottom: 0,
    open: false,
  }

  public clicker = async () => {
    const {
      disable,
      raceId,
      horseName,
      title,
      content,
      section,
      meetingColour,
      runnerColumnIndex,
      raceStartColumnIndex,
    } = this.props
    if (disable || !content) {
      return
    }

    const id = raceId + horseName

    const popupDidOpen = this.popupStore.openPopup({
      id,
      raceId,
      left: this.state.racecardPositionLeft,
      top: this.state.racecardPositionTop,
      bottom: this.state.racecardPositionBottom,
      runnerColumnIndex,
      raceStartColumnIndex,
      props: {
        title: title ? title : horseName,
        meetingColour,
        content: content,
        onClose: () => {
          this.setState({ open: false })
        },
      },
      closeAfter: FIVE_SECONDS,
    })

    if (!popupDidOpen) {
      this.popupStore.closePopup(id)
    } else if (process.env.NODE_ENV !== 'development') {
      ReactGA.event({
        category: `User: ${Si9n.getScreenLocation() ||
          'No screen data available'}`,
        action: `Open ${section} Popup`,
        label: this.props.analyticsLabel,
      })
    }
    this.setState({ open: popupDidOpen })
  }

  public componentWillUnmount() {
    const { raceId, horseName } = this.props

    this.popupStore.closePopup(raceId + horseName)
  }

  public render() {
    return (
      <NewspaperTipsWrapperStyles
        ref={ref => {
          if (
            ref &&
            this.props.realRender &&
            !this.state.racecardPositionLeft &&
            !this.state.racecardPositionTop &&
            !this.state.racecardPositionBottom
          ) {
            const { top, left, bottom } = ref.getBoundingClientRect()

            this.setState({
              racecardPositionLeft: left,
              racecardPositionTop: top,
              racecardPositionBottom: bottom,
            })
          }
        }}
        onClick={this.clicker}
        open={this.state.open}
        nonRunner={this.props.nonRunner}
        meetingColour={this.props.meetingColour}
        postDataHorse={this.props.postDataHorse}
      >
        <NewspaperTipsWhiteBackgroundWrapper
          open={this.state.open}
          nonRunner={this.props.nonRunner}
          meetingColour={this.props.meetingColour}
          postDataHorse={this.props.postDataHorse}
        >
          {this.props.children}
        </NewspaperTipsWhiteBackgroundWrapper>
      </NewspaperTipsWrapperStyles>
    )
  }

  get popupStore() {
    const { popupStore } = this.props as IInjectedProps
    return popupStore
  }
}

export default inject('popupStore')(PopupClickWrapperTips)
