import App from 'si9n-app'
import * as Sentry from '@sentry/browser'

// with no types available for Si9n or Sentry this would be quite a task to write as ts
class Si9n {
  isDev = process.env.NODE_ENV === 'development'
  constructor() {
    this.app = App.getInstance()

    this.app.on('configured', ({ data }) => {
      this.configData = data
    })
  }

  getScreenLocation = () => {
    if (!this.configData) {
      if (!this.isDev) {
        // send an error to sentry so we can track this on the screens
        Sentry.captureException(
          new Error('Si9n data was requested, no data found'),
        )
      }

      return null
    }

    const addSeparator = (currentPath, newValue) =>
      currentPath.length ? `${currentPath} > ${newValue}` : newValue

    // send all the data to sentry just in case we missed anything
    Sentry.captureMessage(JSON.stringify(this.configData))

    let fullPath = ''

    if (this.configData.place) {
      if (this.configData.place.path && this.configData.place.path.length) {
        fullPath = this.configData.place.path.reduce(
          (fullPath, { name }) => addSeparator(fullPath, name),
          fullPath,
        )
      }

      if (this.configData.place.name) {
        fullPath = addSeparator(fullPath, this.configData.place.name)
      }
    }

    if (fullPath) {
      return fullPath
    }

    Sentry.captureException(
      new Error(
        'Si9n data returned no usable path, did the data structure change?',
      ),
    )

    return null
  }
}

export default new Si9n()
