import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  align-items: center;
`

export const Type = styled.div`
  color: red;
  font-family: "Proxima Nova Extra Bold";
  font-size: 80px;
  margin-left: 40px;
  margin-top: 7px;
`
