import React, { FunctionComponent, SyntheticEvent } from 'react'
import fallbackSilk from '@assets/fallback_silk.png'

interface ISilkProps {
  silkPath: string | null
  nonRunner?: boolean | null
  realRender?: boolean
  shouldShowResult: boolean
  place: number | null
}

const getDescription = (int: number) => {
  const digits = [int % 10, int % 100]
  const ordinals = ['st', 'nd', 'rd', 'th']
  const oPattern = [1, 2, 3, 4]
  const tPattern = [11, 12, 13, 14, 15, 16, 17, 18, 19]
  return oPattern.includes(digits[0]) && !tPattern.includes(digits[1])
    ? int + ordinals[digits[0] - 1]
    : int + ordinals[3]
}
const Silk: FunctionComponent<ISilkProps> = ({
  silkPath,
  place,
  realRender,
  shouldShowResult,
}) => (
  <div className={'race-card__silk'}>
    {shouldShowResult &&
      place &&
      (place < 4 ? (
        <div className={`race-card__${place}_place`}>
          {getDescription(place)}
        </div>
      ) : (
        <div className={`race-card__place`}>{getDescription(place)}</div>
      ))}
    {realRender && (
      <img
        src={
          silkPath
            ? silkPath
            : fallbackSilk
        }
        alt="Jockey Silk"
        onError={(event: SyntheticEvent) => {
          const target = event.target as HTMLImageElement
          target.src = fallbackSilk
        }}
        height={60}
        width={70}
      />
    )}
    {!realRender && <div style={{ height: '60px', width: '70px' }} />}
  </div>
)

export default Silk
