import React, { FunctionComponent } from 'react'
import { IHead } from '../ITopDraw'

const headings = [
  {
    className: 'global-table-title--smallest',
  },
  {
    title: 'DEC',
    className: 'global-table-title--smallest global-table--left',
  },
  {
    title: 'DIST GOING',
    className: 'global-table-title--small global-table--left',
  },
  {
    title: 'STALLS',
    className: 'global-table-title--smallest global-table--left ',
  },
  {
    title: 'LOW (w)',
    className: 'global-table-title--small',
  },
  {
    title: 'MID (w)',
    className: 'global-table-title--small',
  },
  {
    title: 'HIGH (w)',
    className: 'global-table-title--small',
  },
  {
    title: 'RACES',
    className: 'global-table-title--smallest',
  },
]

const Head: FunctionComponent<IHead> = () => (
  <tr>
    {Object.values(headings).map((heading, index) => (
      <th className={`global-table--normal ${heading.className}`} key={index}>
        {heading.title}
      </th>
    ))}
  </tr>
)

export default Head
