import React, { FunctionComponent } from 'react'
import CustomText from '@horses/CustomText/CustomText'

const Head: FunctionComponent = () => (
  <thead className="helpers--table-head course-info--vertical-spacing">
    <tr className="helpers--table-row helpers--flex-between">
      <th>
        <CustomText
          fontTertiary={true}
          uppercase={true}
          text="Distance"
          size="small"
          bold={true}
        />
      </th>
      <th>
        <CustomText
          fontTertiary={true}
          text="Record Holder"
          uppercase={true}
          size="small"
          bold={true}
        />
      </th>
      <th>
        <CustomText
          fontTertiary={true}
          text="Course Record"
          uppercase={true}
          size="small"
          bold={true}
        />
      </th>
      <th>
        <CustomText
          fontTertiary={true}
          text="R.P. Standard"
          uppercase={true}
          size="small"
          bold={true}
        />
      </th>
    </tr>
  </thead>
)

export default Head
