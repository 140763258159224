import { parse } from 'query-string'
import howToGuides, { resetHowToGuides } from '@utils/howToGuides'
import { MAX_COLS_PER_SCREEN, HEIGHT_4K } from '@config'
import createImage from '@utils/createImage'
import Column from '@classes/Column'
import { computed, observable, action } from 'mobx'
import get from 'lodash/get'
import colors from '@styles/colors.scss'
import { IMinimalTipData } from '@components/HorseComponents/Tipping/ITipping'
import { sendAnalyticsData } from '@utils/utils'
import Si9n from '@utils/Si9n'
import theme from '@styles/theme'

interface IScreenOptions {
  bookmakerId: string
}
class ScreenStore {
  @observable public devTools: boolean = false
  public tipsterConfig: string = ''
  public bookmakerColors = {
    WH_RETAIL: colors.williamHillBlue,
    WH_OXI: colors.williamHillBlue,
    TOALS: colors.williamHillBlue,
    CORAL: colors.coralBlue,
  }

  @observable private screens: Column[][] = []
  @observable private activeScreenIndex: number = 0
  public bookmakerId: string
  private tipAnalytics: IMinimalTipData[] = []
  private tipComponentsRendered: boolean[] = []

  constructor(options: IScreenOptions) {
    this.bookmakerId = options.bookmakerId
  }

  public init = (screens: Column[][]) => {
    const { screenIndex, enableDevTools, tipsterConfig = '' } = this.parseUrl(
      screens,
    )
    resetHowToGuides()
    this.setScreens(screens)
    this.addFreeColsGuidesAndAdverts(screenIndex)
    this.setTipsterConfig(tipsterConfig)
    this.setDevTools(enableDevTools)
  }

  public getBookmakerColor = () => {
    return get(this.bookmakerColors, this.bookmakerId, theme.colors.oddBoxRed)
  }

  /**
   * Updates active screen index to previous one
   * @return {void}
   */
  public setPreviousScreen = (addFreeColsGuidesAndAdverts = true) => {
    const previousScreenIndex =
      this.activeScreenIndex > 0 ? this.activeScreenIndex - 1 : 0

    if (addFreeColsGuidesAndAdverts) {
      this.addFreeColsGuidesAndAdverts(previousScreenIndex)
    }
  }

  public logActiveScreenToConsole = () => {
    console.log(this.activeScreen) // tslint:disable-line
  }

  /**
   * Updates active screen index to the next one along
   * @return {void}
   */
  public setNextScreen = (addFreeColsGuidesAndAdverts = true) => {
    const nextScreenIndex =
      this.activeScreenIndex === this.screens.length - 1
        ? this.activeScreenIndex
        : this.activeScreenIndex + 1

    if (addFreeColsGuidesAndAdverts) {
      this.addFreeColsGuidesAndAdverts(nextScreenIndex)
    }
  }

  @action
  public setScreens = (screens: Column[][]) => {
    this.screens = screens
  }

  public throwSentryError = () => {
    throw Error('Sentry Error Test')
  }

  @action
  public setDevTools = (devTools: boolean) => {
    this.devTools = devTools
  }

  public returnActiveScreenToScreen = () =>
    `${this.activeScreenIndex + 1} / ${this.screens.length}`

  public getPreviousScreen = () => {
    if (this.activeScreenIndex === 0) {
      return null
    }
    return this.screens[this.activeScreenIndex - 1]
  }

  public getNextScreen = () => {
    if (this.activeScreenIndex === this.screens.length - 1) {
      return null
    }
    return this.screens[this.activeScreenIndex + 1]
  }

  public pushTipsAnalytics = (tip: IMinimalTipData) => {
    this.tipAnalytics.push(tip)

    return this.tipComponentsRendered.push(false) - 1
  }

  public setTipComponentRendered = (index: number) => {
    this.tipComponentsRendered[index] = true

    if (this.tipComponentsRendered.every(val => val)) {
      const screen = Si9n.getScreenLocation()
      const environment = process.env.NODE_ENV
      const screenNumber = `${this.activeScreenIndex + 1} of ${
        this.screens.length
      }`

      sendAnalyticsData(
        this.tipAnalytics.map(tip => ({
          ...tip,
          screen,
          environment,
          screenNumber,
        })),
      )

      this.tipComponentsRendered = []
      this.tipAnalytics = []
    }
  }

  /**
   * Returns how many column ahead of colIndex are same race.
   * @param  {number} colIndex
   * @return {number}
   */
  public amountOfColsAheadAreSame = (colIndex = 0) => {
    const previousColumn = this.getPreviousColumn(colIndex)
    const activeColumn = this.activeScreen[colIndex]
    if (previousColumn && previousColumn.course === activeColumn.course) {
      return 0
    }

    let count = 0
    for (let x = colIndex; x < MAX_COLS_PER_SCREEN; x++) {
      if (
        this.activeScreen[x] &&
        activeColumn &&
        activeColumn.course === this.activeScreen[x].course
      ) {
        count++
      }
    }

    return count
  }

  /**
   * Sets the current active screen index.
   * If it's greater than the current length of screens.
   * It will set it to the last.
   * @param  {number}
   * @return {void}
   */
  private setActiveIndex = (activeScreenIndex: number) => {
    const screensLength = this.screens ? this.screens.length - 1 : 0
    if (activeScreenIndex > screensLength) {
      this.activeScreenIndex = screensLength
    }
    this.activeScreenIndex = activeScreenIndex
  }

  private setTipsterConfig = (config: string) => {
    this.tipsterConfig = config
  }

  @computed
  get activeScreen() {
    return this.screens[this.activeScreenIndex] || []
  }

  private getPreviousColumn = (colIndex = 0) => {
    if (colIndex === 0) {
      return false
    }
    return this.activeScreen[--colIndex]
  }

  /**
   * Gets the screen, devTools and tipsterConfig from url.
   * @param  {array} screens
   * @return {object}
   */
  private parseUrl = (screens: Column[][]) => {
    const { screen = 0, devTools, tipsterConfig = '' } = parse(
      window.location.search,
    )

    const screenAsNumber = Number(screen)

    const enableDevTools = devTools === 'true'
    const screensLength = screens ? screens.length - 1 : 0

    // Set the screen index to what ever user passed minus 1
    let screenIndex = screenAsNumber > 0 ? screenAsNumber - 1 : screenAsNumber

    // If the passed screen is too big, set it to last one
    if (screenAsNumber > screensLength) {
      screenIndex = screensLength
    }

    let stringifiedConfig = ''

    if (tipsterConfig) {
      stringifiedConfig = tipsterConfig.toString()
    }

    return {
      tipsterConfig: stringifiedConfig,
      enableDevTools,
      screenIndex,
    }
  }

  private addHowToGuidestoIndex = (index: number) => {
    this.screens[index].forEach(column => {
      const { freeSpace } = column
      if (column.canAddHowToGuideOrAdvertOrSelectionBox(freeSpace)) {
        const adImage = howToGuides(freeSpace, this.bookmakerId, index)
        if (!adImage) {
          return
        }
        const { imageSrc, px } = adImage
        if (imageSrc && px) {
          this.addImage(imageSrc, px, column)
        }
      }
    })
  }

  private addImage = (imgSrc: string, px: number, column: Column) => {
    const image = createImage(imgSrc, 'promo')
    const element = {
      element: image,
      props: null,
      height: px,
    }
    column.addHowToGuide(element)
  }

  private addColsToIndex = (index: number) => {
    if (
      this.screens[index] &&
      this.screens[index].length < MAX_COLS_PER_SCREEN
    ) {
      this.screens[index].push(
        new Column({
          columnWidth: 1,
          freeSpace: HEIGHT_4K,
          columnHeight: 0,
          course: 'none',
          meeting: null,
          noHeader: true,
        }),
      )
    }
  }

  /**
   * Adds adverts and free columns to specific index.
   * Can pass bool to update app, only used from devTools.
   */
  private addFreeColsGuidesAndAdverts = (index: number) => {
    this.addColsToIndex(index)
    this.addHowToGuidestoIndex(index)
    this.setActiveIndex(index)
  }
}

export default ScreenStore
