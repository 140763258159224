import { observable, action } from 'mobx';
class LoadingStore {
  @observable public colsToHit: number = 0;
  @observable public amountOfCols: number = 0;
  @observable public currentStep: string = ''

  @action public setColsToHit = (cols: number) => {
    this.colsToHit = cols
  }

  @action public setAmountOfCols = (cols: number) => {
    this.amountOfCols = cols
  }

  @action public setCurrentStep = (step: string) => {
    this.currentStep = step
  }
}

export default LoadingStore
