import React, { useState } from 'react'

interface IImageProps {
  src: string
  alt: string
  className: string
  style: {}
  FallBackComponent?: React.ComponentType
}

const ImgWithFallback: React.FunctionComponent<IImageProps> = ({
  src,
  alt,
  className,
  FallBackComponent,
}) => {
  const [didImageError, updateDidImageError] = useState(false)

  const onError = () => {
    updateDidImageError(true)
  }

  if (didImageError) {
    return FallBackComponent ? <FallBackComponent /> : null
  }

  return <img src={src} alt={alt} className={className} onError={onError} />
}

export default React.memo(ImgWithFallback, () => true)
