import React, { FunctionComponent } from 'react'
import cx from 'classnames'
import CustomText from '@horses/CustomText/CustomText'
import Weather from './Weather'
import './Header.scss'
import { ISubHeaderProps } from './HeaderProps'
import get from 'lodash/get'
import { observer } from 'mobx-react'
import capitalize from 'lodash/capitalize'

const ONE_COLUMN = 12.5

const MainHeader: FunctionComponent<ISubHeaderProps> = ({
  viewportWidth,
  isFree,
  columnIndex,
  titleOverride,
  meeting,
  nextScreenFirstColCourse,
  prevScreenLastColCourse,
}) => {
  const races = get(meeting, 'meeting.races', null)

  let courseName = meeting ? meeting.getCourseName() : null

  if (titleOverride) {
    courseName = titleOverride
  }
  const title = meeting ? meeting.getRaceTitle() : null
  const colour = meeting ? meeting.getMeetingColour() : 'green'
  const type = meeting ? meeting.getMeetingType() : null
  const capitalizedType = type ? capitalize(type) : null
  const goingStick = meeting ? meeting.getPreGoingDesc() : null
  const predictedWeather = meeting ? meeting.getPreWeatherDesc() : null
  const liveWeather = meeting ? meeting.getLiveWeather() : null
  const shouldShowRaceTime = !isFree
  const IS_LESS_THAN_TWO_COLUMNS = viewportWidth <= ONE_COLUMN * 2
  const IS_GREATER_THAN_TWO_COLUMNS = viewportWidth >= ONE_COLUMN * 3
  const IS_GREATER_THAN_THREE_COLUMNS = viewportWidth >= ONE_COLUMN * 4
  const headerWidth = viewportWidth
  const AVAILABLE_CHARACTERS_PER_COLUMN = 31
  const MEETING_AND_WEATHER_COLUMN_SPACE = 2.5

  const contentInBracketsRegex = /\(.*?\)/g
  const contentWithoutClosingBracketRegex = /\(.*/g

  const goingStickExtraContentRemoved =
    goingStick &&
    goingStick
      .replace(contentInBracketsRegex, '')
      .replace(contentWithoutClosingBracketRegex, '')

  const normalisedGoingCondition = goingStickExtraContentRemoved
    ? goingStickExtraContentRemoved
        .split(' ')
        .map(word => (word === 'NH' ? word : capitalize(word)))
        .join(' ')
    : null

  const goingConditionCharactersCount =
    normalisedGoingCondition !== null ? normalisedGoingCondition.length : 0

  const availableCharacters =
    (viewportWidth / ONE_COLUMN - MEETING_AND_WEATHER_COLUMN_SPACE) *
    AVAILABLE_CHARACTERS_PER_COLUMN

  const goingConditionFitsAvailableSpace =
    availableCharacters > goingConditionCharactersCount

  return (
    <div
      className={cx('header__container', {
        'header--border': columnIndex > 0,
        'header-continuing-next-screen':
          nextScreenFirstColCourse === courseName,
        'header-continued-prev-screen': prevScreenLastColCourse === courseName,
      })}
      style={{
        width: `calc(${headerWidth}vw - 14px)`,
        margin: '0 7px 0 7px',
        backgroundColor: colour,
        fontSize: IS_LESS_THAN_TWO_COLUMNS ? '35px' : '',
        paddingLeft: IS_LESS_THAN_TWO_COLUMNS ? '10px' : '20px',
      }}
    >
      <div className="header__meeting">
        <CustomText
          text={courseName}
          colour="white"
          fontBold={true}
          size={IS_LESS_THAN_TWO_COLUMNS ? 'large' : 'header'}
        />
        <div className="header__meeting-info">
          <div className="header__race-info">
            {shouldShowRaceTime && !IS_LESS_THAN_TWO_COLUMNS && (
              <CustomText
                text={title}
                colour="white"
                size="description"
                lowercase={true}
                proximaBlack={true}
                spacingLarge={true}
              />
            )}
            {races && !IS_LESS_THAN_TWO_COLUMNS && (
              <CustomText
                text={`${races.length} Races ${capitalizedType}`}
                colour="white"
                size="description"
                proximaBlack={true}
                spacingLarge={true}
              />
            )}
          </div>
        </div>
      </div>
      {IS_GREATER_THAN_TWO_COLUMNS && (
        <div className="header__weather">
          {goingStick && IS_GREATER_THAN_THREE_COLUMNS && (
            <span className="header__going">
              <CustomText
                text="Going:"
                inline={true}
                proximaBlack={true}
                colour="white"
                size={
                  goingConditionFitsAvailableSpace ? 'description' : 'medium'
                }
                spacingLarge={true}
              />

              <CustomText
                text={normalisedGoingCondition}
                inline={true}
                colour="white"
                size={
                  goingConditionFitsAvailableSpace ? 'description' : 'medium'
                }
                proximaBlack={true}
                spacingLarge={true}
              />
            </span>
          )}
          <div className="header__temperature">
            {liveWeather && liveWeather.temperature && (
              <CustomText
                text={Math.round(liveWeather.temperature)}
                colour="white"
                inline={true}
                fontPrimary={true}
                size="header"
                subScript={'°C'}
                subScriptSize="big"
                subScriptDegreeAlignment={true}
                fontBold={true}
              />
            )}
          </div>
          {liveWeather && (
            <Weather weather={liveWeather.icon || predictedWeather} />
          )}
        </div>
      )}
    </div>
  )
}

export default observer(MainHeader)
