import React, { FunctionComponent } from 'react'
import styled from 'styled-components'

export interface IPopupMostTippedProps {
  papers: string[]
}

const Columns = styled.div`
  display: grid;
  grid-template-columns: 50% 50%;
  p:nth-child(4n + 1),
  p:nth-child(4n) {
    background: #eeeeee;
  }
  > p {
    padding: 10px;
    font-size: 20px;
  }
`

const PopupMostTippedContent: FunctionComponent<IPopupMostTippedProps> = ({
  papers,
}) => (
  <Columns>
    {papers.map((paper, key) => (
      <p key={paper + key}>{paper}</p>
    ))}
    {papers.length % 2 !== 0 && <p>{''}</p>}
  </Columns>
)

export default PopupMostTippedContent
