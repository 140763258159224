import React from 'react'
import { observer } from 'mobx-react'
import DiffusionSocket from "@classes/Socket"
import './SocketConnection.scss'

const SocketConnection = () => {
  const noSocketText = "SOCKET DISCONNECTED - PRICES / RACE STATUS MAY NOT BE CURRENT. "
  return DiffusionSocket.socketConnectionActive ? null : (
    <div className="socket-connection">{noSocketText}</div>
  )
}

export default observer(SocketConnection)
