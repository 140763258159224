import React, { FunctionComponent } from 'react'
import { IContainer } from './IContainer'
import Title from '@horses/Title/Title'

import { SectionContainer } from './ContainerStyles.css'

const Container: FunctionComponent<IContainer> = ({
  children,
  innerRef,
  title,
  colour,
}) => (
  <div ref={innerRef}>
    <Title text={title || ''} colour={colour || ''} />

    <SectionContainer>{children}</SectionContainer>
  </div>
)

export default Container
