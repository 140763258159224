import styled from 'styled-components'

interface ITitleStyleProps {
  colour: string
}

const TitleStyles = styled.div<ITitleStyleProps>`
  text-transform: uppercase;
  font-family: ${({ theme }) => theme.fonts.proximaExtraBold};
  padding: 11px 10px 10px 9px;
  font-size: 20px;
  color: white;
  background-color: ${({ colour }) => colour};
  margin-left: -7px;
  margin-right: -7px;
`

export { TitleStyles }
