function chunk<T>(arr: T[], n: number) {
  const arrayCopy = [...arr]
  const chunkLength = Math.max(arrayCopy.length / n, 1);
  const chunks = [];
  for (let i = 0; i < n; i++) {
    if (chunkLength * (i + 1) <= arrayCopy.length) {
      chunks.push(arrayCopy.slice(chunkLength * i, chunkLength * (i + 1)));
    }
  }
  return chunks;
}

export default chunk
