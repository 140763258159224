import styled from 'styled-components'

export const StatsWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  position: relative;
  flex-direction: column;
  flex-wrap: wrap;
  max-height: 24px;
  width: auto;
  margin-top: -2px;
`
