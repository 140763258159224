import React, { FunctionComponent } from 'react'
import { observer } from 'mobx-react'
import { IRunnerStats } from '../RaceCardInterfaces/IWeightRating'
import CustomText from '@horses/CustomText/CustomText'
import { StatsWrapper } from '../RaceCardStyles/RaceCardStatsStyles.css'

const RunnerStats: FunctionComponent<IRunnerStats> = ({ topRow }) => {
  const {
    plus10,
    distanceWins,
    courseWins,
    courseDistanceWins,
    slowGroundJumpsWins,
    slowGroundFlatWins,
    daysLastRunFlat,
    fastGroundWins,
    daysLastRun,
    daysLastRunJumps,
    daysLastRunPTP,
    beatenFav,
  } = topRow
  const dataAvailable =
    plus10 ||
    daysLastRun ||
    beatenFav ||
    distanceWins ||
    slowGroundJumpsWins ||
    slowGroundFlatWins ||
    fastGroundWins ||
    courseWins ||
    courseDistanceWins ||
    daysLastRunFlat ||
    daysLastRunJumps ||
    daysLastRunPTP

  return (
    <StatsWrapper>
      {dataAvailable && plus10 && (
        <div className="race-card__plus-10-container">
          <CustomText
            proximaCondSemiBold={true}
            colour="white"
            size="smallest"
            noSpace={true}
            text="10"
          />
        </div>
      )}
      {daysLastRun && daysLastRun > 0 && (
        <CustomText
          text={daysLastRun}
          proximaCondSemiBold={true}
          size="smallest"
        />
      )}
      {beatenFav && (
        <CustomText
          proximaCondSemiBold={true}
          size="smallest"
          bold={true}
          text="BF"
        />
      )}
      {distanceWins > 0 && (
        <CustomText
          text={`D${distanceWins}`}
          proximaCondSemiBold={true}
          size="smallest"
          bold={true}
        />
      )}
      {slowGroundJumpsWins > 0 && (
        <CustomText
          text={`S${slowGroundJumpsWins}`}
          proximaCondSemiBold={true}
          size="smallest"
          bold={true}
        />
      )}
      {slowGroundFlatWins > 0 && (
        <CustomText
          text={`S${slowGroundFlatWins}`}
          proximaCondSemiBold={true}
          size="smallest"
          bold={true}
        />
      )}
      {fastGroundWins > 0 && (
        <CustomText
          text={`F${fastGroundWins}`}
          proximaCondSemiBold={true}
          size="smallest"
          bold={true}
        />
      )}
      {courseWins > 0 && (
        <CustomText
          text={`C${courseWins}`}
          proximaCondSemiBold={true}
          size="smallest"
          bold={true}
        />
      )}
      {courseDistanceWins > 0 && (
        <CustomText
          text={`CD${courseDistanceWins}`}
          proximaCondSemiBold={true}
          size="smallest"
          bold={true}
        />
      )}
      {daysLastRunFlat && (
        <CustomText
          text={`${daysLastRunFlat}F`}
          proximaCondSemiBold={true}
          brackets={true}
          size="smallest"
        />
      )}
      {daysLastRunPTP && (
        <CustomText
          text={`${daysLastRunPTP}P`}
          proximaCondSemiBold={true}
          brackets={true}
          size="smallest"
        />
      )}
    </StatsWrapper>
  )
}

export default observer(RunnerStats)
