import Container from '@horses/Container/Container'
import React, { FunctionComponent } from 'react'
import CustomText from '@horses/CustomText/CustomText'
import { ITopJockeys } from './ITopJockeys'
import capitalize from 'lodash/capitalize'
import Body from './Table/Body'
import Head from './Table/Head'
import './TopJockeys.scss'

const TopJockeys: FunctionComponent<ITopJockeys> = ({
  dataForYearsSince,
  meetingType,
  topJockeys,
  courseName,
  innerRef,
  colour,
}) => {
  const setCorrectYear = (year: string | number): string =>
    year > 0 ? `${year}+` : 'Previous Years'
  if (topJockeys && topJockeys.length) {
    return (
      <Container
        colour={colour}
        title={`${courseName} JOCKEYS`}
        innerRef={ref =>
          innerRef(
            ref,
            {
              colour,
              courseName,
              dataForYearsSince,
              innerRef,
              meetingType,
              topJockeys,
            },
            TopJockeys,
          )
        }
      >
        {dataForYearsSince && (
          <CustomText
            text={`${capitalize(meetingType || 'Stats for ')} ${setCorrectYear(
              dataForYearsSince,
            )}`}
            size="medium"
            fontTertiary={true}
          />
        )}
        <table>
          <Head />
          <Body topJockeys={topJockeys} />
        </table>
      </Container>
    )
  }
  return null
}
export default TopJockeys
