import TimeoutLoop from "./TimeoutLoop";
import orderBy from 'lodash/orderBy'
import { differenceInMilliseconds } from 'date-fns'

interface IJob {
  interval: number
  lastExec: Date  // tslint:disable-next-line: no-any
  job: (...args: any) => void;
  passCurrentTimeToJob: boolean
}

interface IJobParams {
  interval: number;  // tslint:disable-next-line: no-any
  job: (...args: any) => void;
  execOnAdd: boolean
  passCurrentTimeToJob: boolean
}

class Jobs {
  private jobs: IJob[] = []
  private timeoutLoop: TimeoutLoop | null = null
  public start = () => {
    this.timeoutLoop = new TimeoutLoop(this.execJobs, 1000, false)
    this.timeoutLoop.init()
  }

  public addMultipleJobs = (jobs: IJobParams[]) => {
    jobs.forEach((job) => this.addJob(job))
  }

  public addJob = (jobParams: IJobParams) => {
    const { interval, job, execOnAdd, passCurrentTimeToJob = false } = jobParams
    const date = new Date()
    const jobObj = {
      interval,
      job,
      lastExec: date,
      passCurrentTimeToJob
    }

    if (execOnAdd) {
      passCurrentTimeToJob ? job(date) : job()
    }

    this.jobs.push(jobObj)
    this.jobs = orderBy(this.jobs, (j) => j.interval, ['asc'])
  }

  private execJobs = () => {
    const now = new Date()
    this.jobs.forEach((job) => {
      const shouldStartJob = differenceInMilliseconds(now, job.lastExec) >= job.interval
      if (shouldStartJob) {
        job.passCurrentTimeToJob ? job.job(now) : job.job()
        job.lastExec = new Date()
      }
    })
  }
}

export default Jobs
