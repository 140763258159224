import React, { FunctionComponent } from 'react'
import signpost from '@assets/signpost.png'
import Race from '@classes/Race'
import { ITip } from './ITipping'

interface ISweetspot {
  race: Race
  tip: ITip
}
const Sweetspot: FunctionComponent<ISweetspot> = ({ tip, race }) => {
  const verdict = tip.verdict.replace(/\\n/g, '')
  return (
    <div key={tip.race_instance_uid + tip.tipster_name}>
      <div className="tipping__title">
        <p className="tipping__title-text">{tip.tipster_type}</p>
      </div>
      <div className="sweetspots__text">
        <div className="sweetspots__tip-info">
          <img src={signpost} className="sweetspots__img" />

          <div className="sweetspots__horse-name">
            <p>{tip.horse_name}</p>
            <p className="sweetspots__race-info">{`${race.getParsedRaceTime()} ${
              tip.course_name
            }`}</p>
          </div>
        </div>

        <p className="sweetspots__verdict">{verdict}</p>
      </div>
    </div>
  )
}

export default Sweetspot
