import CustomText from '@horses/CustomText/CustomText'
import { ITopJockey } from '@models/TopJockey'
import compact from 'lodash/compact'
import map from 'lodash/map'
import React, { FunctionComponent } from 'react'

interface ITopJockeyBodyProps {
  topJockeys: ITopJockey[] | null
}

const Body: FunctionComponent<ITopJockeyBodyProps> = ({ topJockeys }) => (
  <tbody>
    {map(compact(topJockeys), (jockey, index) => (
      <tr
        key={jockey.jockey_uid || jockey.jockey_name || index}
        className="top-jockeys__row"
      >
        <td className="top-jockeys--flex top-jockeys__wins-rides-percent">
          <div className="top-jockeys--align-right top-jockeys__wins">
            <CustomText
              text={jockey.wins || 0}
              size="smallest"
              fontTertiary={true}
              noSpace={true}
              bold={true}
            />
          </div>
          <CustomText
            text="-"
            size="smallest"
            noSpace={true}
            fontTertiary={true}
          />
          <div className="top-jockeys--align-left top-jockeys__wins">
            <CustomText
              text={jockey.rides || 0}
              size="smallest"
              fontTertiary={true}
              noSpace={true}
            />
          </div>
          <CustomText
            text={`${jockey.win_percentage || 0}%`}
            size="smallest"
            fontTertiary={true}
            noSpace={true}
          />
        </td>
        <td className="top-jockeys--flex top-jockeys__jockey-trainer-name">
          <CustomText
            text={jockey.jockey_style_name || ''}
            size="smallest"
            fontTertiary={true}
            dottedBorder={true}
            flexOne={true}
            noSpace={true}
            bold={true}
            flex={true}
          />
          <CustomText
            text={jockey.trainer_name || ''}
            size="smallest"
            fontTertiary={true}
            noSpace={true}
            italic={true}
          />
        </td>
        <td className="top-jockeys__stake top-jockeys--align-right">
          <CustomText
            text={jockey.stake || 0}
            fontTertiary={true}
            size="smallest"
            noSpace={true}
          />
        </td>
      </tr>
    ))}
  </tbody>
)

export default Body
