import React, { FunctionComponent } from 'react'
import { IPriceHistory } from '../RaceCardInterfaces/IPriceHistory'
import math from 'mathjs'
import cx from 'classnames'
import CustomText from '@horses/CustomText/CustomText'

const PriceHistory: FunctionComponent<IPriceHistory> = ({ prices }) => {
  const currentOddGreater = (currentPrice: string, previousPrice: string) => {
    if (/\//.test(currentPrice) && /\//.test(previousPrice)) {
      const currentPriceDecimal = math.eval(currentPrice) + 1
      const previousPriceDecimal = math.eval(previousPrice) + 1

      const oddsComparison = math.compare(
        currentPriceDecimal,
        previousPriceDecimal,
      )

      // mathjs returns currentPrice > previousPrice === 1
      // currentPrice < previousPrice === -1

      return oddsComparison === 1 ? 'higher' : 'lower'
    }
    return 'neutral'
  }

  return (
    <div className="race-card__price-history-container">
      {prices.map((price, i) => {
        return (
          <div key={`${price}${i}`} className="race-card__price-history">
            {i > 0 && (
              <div
                className={cx(
                  'race-card__price-history-arrow',
                  `race-card__${currentOddGreater(price, prices[i - 1])}-arrow`,
                )}
              />
            )}
            <CustomText
              size="smallest"
              fontTertiary={true}
              text={price === '1/1' ? 'Evs' : price}
              spacingLarge={true}
              noSpace={i === prices.length - 1}
            />
          </div>
        )
      })}
    </div>
  )
}

export default PriceHistory
