import React, { Component } from 'react'
import { inject } from 'mobx-react'
import PopupStore from '@stores/PopupStore'
import { fetchHorsePopup, THIRTY_SECS } from '@utils/utils'
import PopupRunnerContent from './PopupRunnerContent'
import ReactGA from 'react-ga'
import {
  RaceCardWrapperStyles,
  RaceCardWhiteBackgroundWrapper,
} from '../PopupClickWrapperStyles.css'
import Si9n from '@utils/Si9n'

interface IProps {
  realRender?: boolean | undefined
  raceId: number
  horseName: string
  tippedHorse?: boolean | null
  disable: boolean
  showInlineSpotlights?: boolean | undefined
  nonRunner?: boolean | undefined
  showSpotlights?: boolean | undefined
  isLastRunnerGroup?: boolean | undefined
  runnerGroupIndex?: number | undefined
  analyticsLabel: string
  content?: JSX.Element | undefined
  title?: string
  section?: string
  meetingColour: string
  postDataHorse?: string | null
  runnerInfo?: {
    courseName: string
    raceTime: string | null
    silk: string | null
  }
  runnerColumnIndex?: number
  raceStartColumnIndex?: number
}

interface IInjectedProps extends IProps {
  popupStore: PopupStore
}

class PopupClickWrapperRunner extends Component<IProps> {
  public state = {
    racecardPositionLeft: 0,
    racecardPositionTop: 0,
    racecardPositionBottom: 0,
    open: false,
  }

  public clicker = async () => {
    const {
      disable,
      raceId,
      horseName,
      title,
      content,
      section,
      meetingColour,
      runnerColumnIndex,
      raceStartColumnIndex,
    } = this.props

    if (disable) {
      return
    }

    const html = await fetchHorsePopup(raceId, horseName)
    const id = raceId + horseName

    const popupDidOpen = this.popupStore.openPopup({
      id,
      raceId,
      left: this.state.racecardPositionLeft,
      top: this.state.racecardPositionTop,
      bottom: this.state.racecardPositionBottom,
      runnerColumnIndex,
      raceStartColumnIndex,
      props: {
        runnerInfo: this.props.runnerInfo,
        title: title ? title : horseName,
        meetingColour,
        content: content ? (
          content
        ) : (
          <PopupRunnerContent {...{ html, meetingColour }} />
        ),
        onClose: () => {
          this.setState({ open: false })
        },
      },
      closeAfter: THIRTY_SECS,
    })

    if (!popupDidOpen) {
      this.popupStore.closePopup(id)
    } else if (process.env.NODE_ENV !== 'development') {
      ReactGA.event({
        category: `User: ${Si9n.getScreenLocation() ||
          'No screen data available'}`,
        action: `Open ${section} Popup`,
        label: this.props.analyticsLabel,
      })
    }
    this.setState({ open: popupDidOpen })
  }

  public componentWillUnmount() {
    const { raceId, horseName } = this.props

    this.popupStore.closePopup(raceId + horseName)
  }

  public render() {
    return (
      <RaceCardWrapperStyles
        meetingColour={this.props.meetingColour}
        ref={ref => {
          if (
            ref &&
            this.props.realRender &&
            !this.state.racecardPositionLeft &&
            !this.state.racecardPositionTop &&
            !this.state.racecardPositionBottom
          ) {
            const { top, left, bottom } = ref.getBoundingClientRect()

            this.setState({
              racecardPositionLeft: left - 9,
              racecardPositionTop: top - 2,
              racecardPositionBottom: bottom,
            })
          }
        }}
        onClick={this.clicker}
        open={this.state.open}
        tippedHorse={this.props.tippedHorse}
        showInlineSpotlights={this.props.showInlineSpotlights}
        nonRunner={this.props.nonRunner}
        showSpotlights={this.props.showSpotlights}
        isLastRunnerGroup={this.props.isLastRunnerGroup}
        runnerGroupIndex={this.props.runnerGroupIndex}
      >
        <RaceCardWhiteBackgroundWrapper
          open={this.state.open}
          tippedHorse={this.props.tippedHorse}
          showInlineSpotlights={this.props.showInlineSpotlights}
          nonRunner={this.props.nonRunner}
          showSpotlights={this.props.showSpotlights}
          isLastRunnerGroup={this.props.isLastRunnerGroup}
          runnerGroupIndex={this.props.runnerGroupIndex}
          meetingColour={this.props.meetingColour}
        >
          {this.props.children}
        </RaceCardWhiteBackgroundWrapper>
      </RaceCardWrapperStyles>
    )
  }

  get popupStore() {
    const { popupStore } = this.props as IInjectedProps
    return popupStore
  }
}

export default inject('popupStore')(PopupClickWrapperRunner)
