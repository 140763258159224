import MeetingQueue from '@classes/MeetingQueue'
import { IHorseRace, IRaceCard } from '@models/HorseMeeting'
import { getTime, showWinnings } from '@utils/raceBoardHelpers'
import { yardsToFurlongs } from 'korelogic-rp-utils'
import pair from '@utils/pair'
import get from 'lodash/get'
import map from 'lodash/map'
import values from 'lodash/values'
import find from 'lodash/find'
import reduce from 'lodash/reduce'
import filter from 'lodash/filter'
import orderBy from 'lodash/orderBy'
import toUpper from 'lodash/toUpper'
import dayjs from 'dayjs'
import Runner from './Runner'
import { ITip } from '@components/HorseComponents/Tipping/ITipping'
import {
  normalisePrice,
  fractionalAsDecimalForComparisonOnly,
} from '@utils/utils'

class Race {
  public runners: Runner[] = []
  public meetingQueue: MeetingQueue
  public courseName: string | null
  public isGlobal: boolean
  public raceStartColumn: number
  private isSubscribedToChanges = false
  private race: IHorseRace
  private raceDateTime: Date
  constructor(
    race: IHorseRace,
    meetingQueue: MeetingQueue,
    courseName: string | null,
    isGlobal: boolean,
  ) {
    this.race = race
    this.meetingQueue = meetingQueue
    this.courseName = courseName
    this.isGlobal = isGlobal
    this.setRunners(race)
    this.raceDateTime = new Date(race.race_datetime)
    this.raceStartColumn = 0
  }

  public setRaceStartColumn = (columnIndex: number) => {
    this.raceStartColumn = columnIndex
  }

  public getRaceStatus = (key: string = 'sisMessage') => {
    const statusId = this.getStatusId()
    if (statusId) {
      const status = this.meetingQueue.statusStore.getStatusForRace(statusId)
      return toUpper(get(status, key, ''))
    }
    return null
  }

  public subscribeRaceToChanges = () => {
    if (!this.isSubscribedToChanges) {
      this.isSubscribedToChanges = true
      this.meetingQueue.statusStore.registerRace(this)
      this.meetingQueue.countdownStore.registerRace(this)
      this.meetingQueue.priceStore.registerRace(this)
    }
  }

  public getRunnerByDiffusionId = (id: string) =>
    find(this.runners, r => r.getPriceId() === id)

  public getFavoriteCount = () => {
    const runnerOdds = this.meetingQueue.priceStore.getOddsForRace(
      this.getStatusId(),
    )
    if (runnerOdds) {
      return reduce(
        runnerOdds,
        (acc, runner) => {
          if (runner.favourite) {
            acc += 1
          }
          return acc
        },
        0,
      )
    }
    return 0
  }

  public getRaceCountdown = () =>
    this.meetingQueue.countdownStore.getRaceCountdown(
      this.getRaceInstanceUid(),
    ) || null

  public hasRaceFinished = () => {
    const status = this.getRaceStatus('progressCode')
    const PMSG = this.getRaceStatus('PMSG')

    const hasFinished = status === 'O' || PMSG === 'FINAL RESULT'

    return hasFinished
  }

  public isRaceAbandoned = () => {
    return get(this.race, 'race_status_code', '') === 'A'
  }

  public getCourse = () => {
    return get(this.race, 'verdict.course_style_name', null)
  }

  public getStatusId = () => {
    const raceTime = this.getRaceTime()
    const courseName = this.getDiffusionName()
    if (raceTime && courseName) {
      return `${dayjs(raceTime).format('YYYY-MM-DD')}/${toUpper(
        courseName,
      )}/${dayjs(raceTime).format('HH:mm')}`
    }

    return ''
  }

  public getRaceInstanceUid = () => {
    return get(this.race, 'race_instance_uid')
  }

  public getCourseId = () => {
    return get(this.race, 'course_uid')
  }

  public getDiffusionName = () => {
    const courseName = get(this.race, 'verdict.diffusion_course_name', null)
    if (courseName === 'Newmarket <O>July<C>') {
      return 'NEWMARKET'
    }
    return courseName || this.courseName
  }

  public getSpotlights = () => {
    return get(this.race, 'spotlights', null)
  }

  public getBettingForecast = () => {
    const forecast = get(this.race, 'betting_forecast', null)
    if (forecast) {
      forecast.forEach(forecastItem => {
        const decimalOdds = fractionalAsDecimalForComparisonOnly(
          normalisePrice(forecastItem.forecast_odds_desc),
        )
        forecastItem.simplifiedOdds = decimalOdds
      })
      return orderBy(forecast, 'simplifiedOdds')
    }
    return null
  }

  public getPastWinners = () => {
    return get(this.race, 'past_winners', null)
  }

  public getVerdict = () => {
    return get(this.race, 'verdict.rp_verdict', null)
  }

  public getVerdictHorseName = () => {
    // horse name is within in text as bold and all caps
    return get(
      get(this.race, 'verdict.rp_verdict', '').match(/\\b([A-Z ]*)\\p/),
      1,
    )
  }

  public getTipping = (): ITip | null => {
    let allTips = get(this.race, 'tipping', null)
    if (!allTips) {
      return null
    }

    allTips = allTips.filter(
      ({ tipster_name }: ITip) => tipster_name !== 'anon',
    )

    const filteredTipster = find(
      allTips,
      tip => tip.tipster_type !== 'SIGNPOSTS SWEETSPOTS',
    )

    return filteredTipster || allTips[0]
  }

  public getRunners = (removeNonRunners: boolean = false): Runner[] => {
    let runners = this.runners

    if (removeNonRunners) {
      runners = filter(this.runners, runner => !runner.getNonRunner())
    }

    const orderedRunners = orderBy(
      runners,
      runner => runner.getHorseStartNumber() || runner.getSaddleClothNumber(),
    )
    return orderedRunners
  }

  public getRunnerPairs = () => {
    const runners = this.getRunners()
    return pair(runners)
  }

  public getPostDataHorse = (): string | null => {
    return get(this.race, 'post_data_horse.horse_name', null)
  }

  public getRPRHorse = (): string | null => {
    return get(this.race, 'rpr_horse.horse_name', null)
  }

  public getRaceTime = (): string => {
    return get(this.race, 'race_card.race_datetime', '')
  }

  public getRaceDateObject = () => {
    return this.raceDateTime
  }

  public getTvText = () => {
    return get(this.race, 'bet_prompts.rp_tv_text', null)
  }

  public getParsedRaceTime = (): string | null => {
    const raceTime = this.getRaceTime()
    if (raceTime) {
      return getTime(raceTime)
    }
    return null
  }

  public getRaceCard = (): IRaceCard | null => {
    return get(this.race, 'race_card', null)
  }

  public getRaceInstanceTitle = (): string | null => {
    return get(this.race, 'race_card.race_instance_title', null)
  }

  public getRaceClass = (): string | null => {
    return get(this.race, 'race_card.race_class', null)
  }

  public getCountryCode = (): string | null => this.race.country_code || null

  public getWinnings = (): string | null => {
    const currencySymbol = get(this.race, 'country_code', '') || ''
    const prize = Math.round(
      Number(get(this.race, 'race_card.prizes[0].prize_sterling', 0)),
    )
    return showWinnings(prize, currencySymbol) || null
  }

  public getSelections = () => get(this.race, 'selections')

  public getRaceDistanceAsFurlongs = (): string | null => {
    const yards =
      get(this.race, 'bet_prompts.distance_yard', 0) || this.race.distance_yard

    const distance = yardsToFurlongs(yards) || null

    const miles = distance
      ? distance.substring(0, distance.indexOf('m') + 1)
      : null
    const furlong = distance
      ? distance.substring(distance.indexOf('m') + 1)
      : null
    if (furlong && furlong[0] === '0') {
      return miles + furlong.substring(1)
    }
    return distance
  }

  public getRaceDistanceYards = () => {
    const yards = get(this.race, 'bet_prompts.distance_yard', 0)
    return yards || this.race.distance_yard
  }

  public isOnITV = () => {
    const tv = get(this.race, 'bet_prompts.rp_tv_text', null)
    return ['ITV', 'ITV4'].includes(tv)
  }

  public raceHasResults = () => {
    const raceId = this.getRaceInstanceUid()
    if (raceId) {
      const results = this.meetingQueue.resultsStore.results[raceId]
      if (results) {
        const runners = get(results, 'horses', [])
        const runnersList = values(runners)
        return !!runnersList.length
      }
    }
    return false
  }

  public getRunnersToPlaceCount = () => {
    //   show 3 places as default
    //   if less than 8 runners, show two places
    //   if more than 15 runners and race is a handicap, show 4 places

    let count = 3
    const raceGroupCode = get(this.race, 'race_card.race_group_code', '')
    const runnerCount = this.getRunners(true).length
    const MAX_HANDICAP_RUNNER_COUNT = 15
    if (raceGroupCode === 'H' && runnerCount > MAX_HANDICAP_RUNNER_COUNT) {
      count = 4
    } else if (runnerCount < 8) {
      count = 2
    }
    return count
  }

  public getRunnerResult = (horseName: string) => {
    const courseId = this.getCourseId()
    const raceId = this.getRaceInstanceUid()

    if (raceId && courseId) {
      const results = this.meetingQueue.resultsStore.results[raceId]
      if (results && results.horses) {
        const resultForRunner = get(
          results,
          `horses[${horseName.toLowerCase()}]`,
          {
            race_outcome_position: null,
            race_outcome_desc: null,
            odds_desc: null,
          },
        )
        return resultForRunner
      }
    }
    return null
  }

  public canGreyOut = () => {
    if (
      this.getRaceStatus() === 'OFF' ||
      this.raceHasResults() ||
      this.hasRaceFinished() ||
      this.isRaceAbandoned()
    ) {
      return true
    }
    return false
  }

  private setRunners = (race: IHorseRace) => {
    this.runners = map(
      race.runners,
      runner =>
        new Runner(
          {
            runner,
            course: this.getDiffusionName(),
            raceTime: this.getRaceTime(),
          },
          this,
        ),
    )
  }
}

export default Race
