import React, { FunctionComponent } from 'react'

interface IProps {
  greyoutCondition: boolean
  realRender?: boolean
}

const GreyoutRP: FunctionComponent<IProps> = ({
  children,
  greyoutCondition,
}) => (
  <div
    style={{
      opacity: greyoutCondition ? 0.4 : 1,
      overflow: 'visible',
    }}
  >
    {children}
  </div>
)

export default GreyoutRP
