import React, { FunctionComponent, Fragment } from 'react'
import styled from 'styled-components'
import { IRaceSelection } from '@models/HorseMeeting'
import { map } from 'lodash'
import Race from '@classes/Race'
import PopupMostTippedContent from '@components/Popup/MostTippedPopup/PopupMostTippedContent'
import PopupClickWrapperTips from '@components/Popup/MostTippedPopup/PopupClickWrapperTips'
import AbandonedRaceStore from '@stores/AbandonedRaceStore'

interface IProps {
  selections: IRaceSelection[] | null
  raceTime: string | null
  race: Race
  meetingColour: string
  postDataHorse: string | null
}

const SelectionHeader = styled.div`
  background: ${({ theme }) => theme.colors.mostTippedHeaderHighlight};
  color: black;
  display: flex;
  justify-content: space-between;
  font-family: ${({ theme }) => theme.fonts.proximaExtraBold};
  font-size: 20px;
  font-weight: bold;
  padding: 13px;
  margin-left: -7px;
  margin-right: -7px;
  border-top: 1px solid black;
  h1 {
    font-weight: bold;
  }
`

const Table = styled.div<{ meetingColour: string }>`
  table-layout: fixed;
  width: 103.1%;
  margin-left: -7px;
  margin-right: -7px;

  .name-col {
    font-weight: bold;
    font-family: ${({ theme }) => theme.fonts.proximaCondBold};
    width: 49%;
    background: white;
    padding-top: 7px;
    font-size: 20px;
    text-transform: uppercase;
    vertical-align: middle;
    p {
      overflow: hidden;
      text-overflow: wrap;
    }
  }

  .count-col {
    height: 90%;
    width: 20%;
    border-radius: 7px;
    padding: 5px;
    padding-top: 6px;
    margin-right: 0;
    text-align: center;
    vertical-align: middle;
    font-size: 20px;
    font-family: ${({ theme }) => theme.fonts.proximaBold};
  }

  .wrapper {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    padding: 5px;
    padding-left: 13px;
    vertical-align: middle;
  }

  .chevron-right {
    display: inline-block;
    width: 10px;
    height: 10px;
    margin-bottom: 1px;
    transform: rotate(-45deg);
  }
`

const SelectionsAndData: FunctionComponent<IProps> = ({
  selections,
  raceTime,
  race,
  meetingColour,
  postDataHorse,
}) => {
  const nonRunners = race.runners.reduce((acc, runner) => {
    if (runner.getNonRunner()) {
      const nonRunnerHorseName = runner && runner.getHorseName()
      acc.push(nonRunnerHorseName)
    }
    return acc
  }, Array())
  return (
    <Fragment>
      <SelectionHeader>
        <h1>{raceTime} Most Newspaper Tips</h1>
      </SelectionHeader>
      <Table meetingColour={meetingColour}>
        <div>
          {map(selections, selection => {
            const addNonRunnerAfterHorsesName =
              nonRunners && nonRunners.includes(selection.horse_name)
            const papersWithUnbreakableSpace =
              selection.papers &&
              selection.papers.map(paper => {
                // \u00a0 is a non breakable space
                return paper.replace(/ /g, '\u00a0')
              })
            return (
              <PopupClickWrapperTips
                key={selection.horse_name}
                title={'Selections'}
                realRender={true}
                raceId={race.getRaceInstanceUid()}
                horseName={selection.horse_name}
                disable={
                  race.hasRaceFinished() ||
                  AbandonedRaceStore.isRaceAbandoned(
                    race.getRaceInstanceUid(),
                  ) ||
                  addNonRunnerAfterHorsesName
                }
                nonRunner={false}
                content={
                  <PopupMostTippedContent papers={papersWithUnbreakableSpace} />
                }
                analyticsLabel={`Most Tipped: ${race.getDiffusionName()} > ${race.getRaceTime()} > ${
                  selection.horse_name
                }`}
                section={'NewspaperTips'}
                meetingColour={meetingColour}
                postDataHorse={postDataHorse}
              >
                <div className="wrapper">
                  <div className="name-col">
                    {selection.horse_name}
                    {addNonRunnerAfterHorsesName ? (
                      <span>{' (NR)'}</span>
                    ) : null}
                  </div>
                  <div className="count-col">
                    {`${papersWithUnbreakableSpace.length} TIPS`}
                    <div className="chevron-right">{''}</div>
                  </div>
                </div>
              </PopupClickWrapperTips>
            )
          })}
        </div>
      </Table>
    </Fragment>
  )
}

export default SelectionsAndData
