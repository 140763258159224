import Meeting from './Meeting'
import filter from 'lodash/filter'
import map from 'lodash/map'
import orderBy from 'lodash/orderBy'

class Set {
  public diomed: boolean = false
  public spotlightsRemoved: boolean = false
  public tipsRemoved: boolean = false
  public verdictsRemoved: boolean = false
  public selectionBoxesRemoved: boolean = false
  public secondMapRemoved: boolean = false
  private set: Meeting[] = []
  private activeMeetingIndex: number = 0
  private removalIndex: number
  constructor(set: Meeting[]) {
    // SET 1: UK/IRE "Afternoon" Meetings: within this set sort by the following
    // By number of ITV/ITV4 races (most to least)
    // By Time of Race 1 if meeting (earliest first)
    // SET 2: UK/IRE "Evening" Meetings
    // By number of ITV/ITV4 races (most to least)
    // By Time of Race 1 if meeting (earliest first)

    this.set = orderBy(
      set,
      meeting => [
        meeting.priorityOrdering,
        meeting.getITVRaceCount(),
        meeting.getFirstRaceTime(),
      ],
      ['asc', 'desc', 'asc'],
    )
    this.removalIndex = set.length - 1
  }
  public incrementActiveMeetingIndex = () => {
    this.activeMeetingIndex += 1
  }

  public reset = () => {
    this.activeMeetingIndex = 0
  }

  public decrementRemovalIndex = () => {
    this.removalIndex = this.removalIndex - 1
  }

  public resetRemovalIndex = () => {
    this.removalIndex = this.set.length - 1
  }

  public getActiveMeeting = () =>
    this.set && this.set.length
      ? this.set[this.activeMeetingIndex] || null
      : null

  public getRemovalMeeting = () =>
    this.set && this.set.length ? this.set[this.removalIndex] || null : null

  public getLastMeeting = () =>
    this.set && this.set.length ? this.set[this.set.length - 1] : null

  public removeStatFromMeeting = () => {
    const meeting = this.set[this.removalIndex]
    if (meeting) {
      meeting.removeStats()
      this.decrementRemovalIndex()
    }
  }

  public removeRaceFromMeeting = () => {
    const removalMeeting = this.getRemovalMeeting()
    if (removalMeeting && removalMeeting.hasRaces()) {
      removalMeeting.removeLastRace()
      if (!removalMeeting.hasRaces()) {
        this.decrementRemovalIndex()
      }
    }
  }

  public getRemovedRaces = () => {
    const allRemovedRaces = map(this.set, meeting => meeting.getRemovedRaces())
    return allRemovedRaces && allRemovedRaces.length ? allRemovedRaces : null
  }

  public allStatsAreRemoved = () => {
    const stats = filter(this.set, meeting => !meeting.hasStatsRemoved())
    return stats.length === 0
  }

  public haveAllRacesBeenRemoved = () => {
    const set = filter(this.set, meeting => meeting.hasRaces())
    return set && set.length === 0
  }

  public setAllMeetingsDiomed = () => {
    this.diomed = true
    this.set.forEach(meeting => {
      meeting.setDiomedSpotlights()
    })
  }

  public removeSecondCourseMap = () => {
    this.secondMapRemoved = true
    this.set.forEach(meeting => {
      meeting.removeSecondCourseMap()
    })
  }

  public removeTips = () => {
    this.tipsRemoved = true

    this.set.forEach(meeting => {
      meeting.removeTips()
    })
  }

  public removeSpotlights = () => {
    this.spotlightsRemoved = true
    this.set.forEach(meeting => {
      meeting.removeSpotlight()
    })
  }

  public removeSpotlightsFromMeeting = () => {
    const removalMeeting = this.getRemovalMeeting()
    if (removalMeeting) {
      removalMeeting.removeSpotlight()
      this.decrementRemovalIndex()
    }
  }

  public hasAllSpotlightsRemovedPerMeeting = () => {
    const sets = filter(this.set, meeting => !meeting.spotlightsRemoved)
    return sets.length === 0
  }

  public removeSelectionBoxes = () => {
    this.selectionBoxesRemoved = true
    this.set.forEach(meeting => {
      meeting.removeSelectionBoxes()
    })
  }

  public removeVerdicts = () => {
    this.verdictsRemoved = true
    this.set.forEach(meeting => {
      meeting.removeVerdict()
    })
  }

  public isLastMeeting = () => {
    if (this.set && this.set.length) {
      const lastMeetingIndex = this.set.length - 1
      return this.activeMeetingIndex === lastMeetingIndex
    }
    return true
  }
}

export default Set
