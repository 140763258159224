import React, { FunctionComponent } from 'react'
import CustomText from '@horses/CustomText/CustomText'
import { ITableBodyProps } from '../ITopTrainers'
import random from 'lodash/random'

const Body: FunctionComponent<ITableBodyProps> = ({ topTrainers }) => (
  <tbody>
    {topTrainers &&
      !!topTrainers.length &&
      topTrainers.map(trainer => (
        <tr key={trainer.trainer_uid} className="top-trainers__row">
          <td className="top-jockeys--flex top-trainers__trainer-name">
            <CustomText
              text={trainer.trainer_style_name}
              fontTertiary={true}
              dottedBorder={true}
              size="smallest"
              flexOne={true}
              noSpace={true}
              bold={true}
              flex={true}
            />
          </td>
          <td className="top-trainers--flex top-trainers__wins-rides-percent">
            <div className="top-trainers--align-right top-trainers__wins">
              <CustomText
                fontTertiary={true}
                text={trainer.wins || 0}
                size="smallest"
                noSpace={true}
                bold={true}
              />
            </div>
            <CustomText
              fontTertiary={true}
              size="smallest"
              text="-"
              noSpace={true}
            />
            <div className="top-trainers--align-left top-trainers__wins">
              <CustomText
                fontTertiary={true}
                text={trainer.runs || 0}
                size="smallest"
                noSpace={true}
              />
            </div>
            <div className="top-trainers__percent">
              <CustomText
                fontTertiary={true}
                text={`${trainer.win_percentage || 0}%`}
                size="smallest"
                noSpace={true}
              />
            </div>
          </td>
          <td className="top-trainers__stake">
            <CustomText
              fontTertiary={true}
              text={random(0, 200)}
              size="smallest"
              noSpace={true}
            />
          </td>
          <td className="top-trainers--flex top-trainers__wins-rides-percent top-trainers--border-left">
            <div className="top-trainers--align-right top-trainers__wins">
              <CustomText
                fontTertiary={true}
                text={trainer.two_year_old_wins || 0}
                size="smallest"
                noSpace={true}
                bold={true}
              />
            </div>
            <CustomText
              fontTertiary={true}
              size="smallest"
              text="-"
              noSpace={true}
            />
            <div className="top-trainers--align-left top-trainers__wins">
              <CustomText
                fontTertiary={true}
                text={trainer.two_year_old_runs || 0}
                size="smallest"
                noSpace={true}
              />
            </div>
            <div className="top-trainers__percent">
              <CustomText
                fontTertiary={true}
                text={`${trainer.two_year_old_win_percentage || 0}%`}
                size="smallest"
                noSpace={true}
              />
            </div>
          </td>
          <td className="top-trainers--flex top-trainers__wins-rides-percent top-trainers--border-left">
            <div className="top-trainers--align-right top-trainers__wins">
              <CustomText
                fontTertiary={true}
                text={trainer.three_year_old_plus_wins || 0}
                size="smallest"
                noSpace={true}
                bold={true}
              />
            </div>
            <CustomText
              fontTertiary={true}
              size="smallest"
              text="-"
              noSpace={true}
            />
            <div className="top-trainers--align-left top-trainers__wins">
              <CustomText
                fontTertiary={true}
                text={trainer.three_year_old_plus_runs || 0}
                size="smallest"
                noSpace={true}
              />
            </div>
            <div className="top-trainers__percent">
              <CustomText
                fontTertiary={true}
                text={`${trainer.three_year_old_plus_win_percentage || 0}%`}
                size="smallest"
                noSpace={true}
              />
            </div>
          </td>
          <td className="top-trainers--border-left top-trainers__rides-since">
            <CustomText
              fontTertiary={true}
              text={`${random(0, 50)}`}
              size="smallest"
              noSpace={true}
            />
          </td>
        </tr>
      ))}
  </tbody>
)

export default Body
