import React, { FunctionComponent } from 'react'
import CustomText from '@horses/CustomText/CustomText'
import { IHorseBettingForecast, IPastWinners } from '@models/HorseMeeting'

import './RaceCardStyles/BettingForecast.scss'

interface IBettingForecastProps {
  data: IPastWinners | null
  forecast: IHorseBettingForecast[] | null
}

const showHorse = (odds: string, name: string, isLast: boolean) => {
  // \u00a0 is a non breakable space
  const oddsWithName = `${odds.replace(/-/g, '‑')} ${name}`.replace(
    / /g,
    '\u00a0',
  )
  if (isLast) {
    return ` ${oddsWithName}.`
  } else {
    return ` ${oddsWithName}`
  }
}

const BettingForecast: FunctionComponent<IBettingForecastProps> = ({
  data,
  forecast,
}) => {
  return forecast && !!forecast.length ? (
    <div
      style={{
        borderTop: data === null ? '1px solid black' : '',
      }}
      className="betting-forecast"
    >
      <CustomText
        inline={true}
        size="forecast"
        proximaMedium={true}
        text={`BETTING FORECAST: `}
      />
      <CustomText
        inline={true}
        size="forecast"
        proximaCondMedium={true}
        text={`${forecast.map(({ horse_name, forecast_odds_desc }, index) =>
          showHorse(
            forecast_odds_desc.replace('/', '-'),
            horse_name,
            index === forecast.length - 1,
          ),
        )}`}
      />
    </div>
  ) : null
}

export default BettingForecast
