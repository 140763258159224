import values from 'lodash/values'

import { IHorseMeeting } from '@models/HorseMeeting'
import Meeting from './Meeting'
import Jobs from './Jobs'
import groupBy from 'lodash/groupBy'
import map from 'lodash/map'
import head from 'lodash/head'
import findIndex from 'lodash/findIndex'
import Sets from './Sets'
import orderBy from 'lodash/orderBy'
import reduce from 'lodash/reduce'
import chunk from '@utils/chunk'
import HorseStatusStore from '@stores/HorseStatusStore'
import HorsePriceStore from '@stores/HorsePriceStore'
import ResultsStore from '@stores/ResultsStore'
import CountDownStore from '@stores/CountDownStore'
import mean from 'lodash/mean'
import { FIVE_MIN, ONE_MIN, FIVE_SECONDS } from '@utils/utils'
import NonRunnersStore from '@stores/NonRunnersStore'

interface IMeetingQueueOptions {
  meetings: IHorseMeeting[]
  target: string
  bookmakerId: string
  meetingOverride: string | null
  priorityMeeting: string | null
  inlineSpotlights: boolean
  diomedSpotlights: boolean
  verdictAtBottom: boolean
  showPriceHistory: boolean
  showPrices: boolean
  jobs: Jobs
  showSpotlights: boolean
  showMostTipped: boolean
  inlineTip: boolean
  secondCourseMap: boolean
}

class MeetingQueue {
  public bookmakerId: string
  public meetingOverride: string | null = null
  public priorityMeeting: string | null = null
  public inlineSpotlights: boolean = false
  public diomedSpotlights: boolean = false
  public showSpotlights: boolean = true
  public showPriceHistory: boolean = false
  public showPrices: boolean = false
  public jobs: Jobs
  public resultsStore = new ResultsStore()
  public countdownStore = new CountDownStore()
  public priceStore: HorsePriceStore
  public statusStore = new HorseStatusStore()
  public verdictAtBottom: boolean = false
  public showMostTipped: boolean = true
  public inlineTip: boolean = true
  public secondCourseMap: boolean = true
  private meetings: IHorseMeeting[]
  private sets: Sets | null = null
  constructor(options: IMeetingQueueOptions) {
    const {
      meetings,
      target,
      meetingOverride,
      bookmakerId,
      inlineSpotlights,
      diomedSpotlights,
      priorityMeeting,
      verdictAtBottom,
      showPriceHistory,
      showSpotlights,
      showPrices,
      jobs,
      showMostTipped,
      inlineTip,
      secondCourseMap,
    } = options
    this.meetings = meetings
    this.bookmakerId = bookmakerId
    this.meetingOverride = meetingOverride
    this.inlineSpotlights = inlineSpotlights
    this.diomedSpotlights = diomedSpotlights
    this.priorityMeeting = priorityMeeting
    this.verdictAtBottom = verdictAtBottom
    this.showPriceHistory = showPriceHistory
    this.showSpotlights = showSpotlights
    this.showPrices = showPrices
    this.jobs = jobs
    this.showMostTipped = showMostTipped
    this.inlineTip = inlineTip
    this.secondCourseMap = secondCourseMap
    this.priceStore = new HorsePriceStore({
      target,
      bookmakerId,
      showPrices,
    })

    this.jobs.addMultipleJobs([
      {
        interval: FIVE_MIN,
        job: this.resultsStore.getResults,
        execOnAdd: true,
        passCurrentTimeToJob: false,
      },
      {
        interval: FIVE_MIN,
        job: NonRunnersStore.getNonRunners,
        execOnAdd: true,
        passCurrentTimeToJob: false,
      },
      {
        interval: ONE_MIN,
        job: this.statusStore.checkTimes,
        execOnAdd: true,
        passCurrentTimeToJob: false,
      },
      {
        interval: FIVE_SECONDS,
        job: this.priceStore.checkCachedOdds,
        execOnAdd: true,
        passCurrentTimeToJob: false,
      },
      {
        interval: 1000,
        job: this.countdownStore.checkTimes,
        execOnAdd: true,
        passCurrentTimeToJob: true,
      },
    ])
  }

  public orderMeetingsIntoSets = async () => {
    const meetings = [...this.meetings]
    const completeMeetings = map(
      meetings,
      meeting => new Meeting(meeting, this),
    )
    if (this.meetingOverride && this.meetingOverride.length) {
      this.setMeetingOverrides(completeMeetings)
    } else if (this.priorityMeeting) {
      let priorityMeetings: Meeting[] = []

      const priorityList = this.priorityMeeting.split(',')
      priorityMeetings = priorityList.reduce((acc: Meeting[], priority, i) => {
        const meetingIndex = findIndex(
          completeMeetings,
          meeting => meeting.getDiffusionCourseName() === priority,
        )
        if (meetingIndex > -1) {
          const completePriorityMeeting = head(
            completeMeetings.splice(meetingIndex, 1),
          )
          if (completePriorityMeeting) {
            completePriorityMeeting.priorityOrdering = i + 1
            acc.push(completePriorityMeeting)
          }
        }
        return acc
      }, [])
      const groupByPriority = groupBy(priorityMeetings, meeting => {
        return priorityMeetings.indexOf(meeting) === 0
      })

      const asValues = values(groupByPriority)
      this.sets = new Sets(asValues)
    } else {
      const orderedMeetings = orderBy(
        completeMeetings,
        meeting => {
          if (meeting.isAbandoned()) {
            return 0
          }
          if (meeting.races && meeting.races.length) {
            return mean(
              meeting.races.map(race => {
                const raceCard = race.getRaceCard()

                if (raceCard && raceCard.prizes && raceCard.prizes.length) {
                  return raceCard.prizes[0].prize_sterling
                }
              }),
            )
          }
        },
        'desc',
      )

      for (const [index, meeting] of orderedMeetings.entries()) {
        meeting.priorityOrdering = index + 1
      }

      const sets = chunk(orderedMeetings, 2)

      this.sets = new Sets(sets)
    }
    await this.statusStore.getInitialStatuses()
  }

  public setMeetings = (meetings: IHorseMeeting[]) => {
    this.meetings = meetings
  }

  public getSets = () => this.sets || null

  private setMeetingOverrides = (meetings: Meeting[]) => {
    const priorityAdded = reduce(
      meetings,
      (acc: Meeting[], meeting, i) => {
        meeting.priorityOrdering = i
        acc.push(meeting)
        return acc
      },
      [],
    )
    const chunkedMeetings = chunk(priorityAdded, 2)
    this.sets = new Sets(chunkedMeetings)
  }
}

export default MeetingQueue
