import React, { FunctionComponent } from 'react'
import CustomText from '@horses/CustomText/CustomText'

import styled from 'styled-components'

interface IRPRProps {
  postDataHorse: string | null
  rprHorse: string | null
}

const RacingPostRatingContainer = styled.div`
  display: flex;
  margin: 0px -7px 0 -7px;
  border-top: 1px solid black;

  > div {
    display: flex;
    width: 50%;
    padding: 0px;
    background-color: ${({ theme }) => theme.colors.mostTippedHeaderHighlight};

    div {
      padding: 10px;
    }

    .title {
      display: flex;
      align-items: center;
      padding-left: 13px;
      padding-right: 13px;
      font-family: ${({ theme }) => theme.fonts.proximaCondExtraBold};
    }

    .rp_rating_title {
      display: flex;
      align-items: center;
      min-width: 93px;
      padding-left: 13px;
      padding-right: 13px;
      border-left: 1px solid black;
      font-family: ${({ theme }) => theme.fonts.proximaCondExtraBold};
    }

    .rating {
      display: flex;
      align-items: center;
      width: 100%;
      padding-bottom: 12px;
      padding-left: 13px;
      padding-right: 13px;
      border-left: 1px solid black;
      background-color: white;
    }
  }
`

const RacingPostRating: FunctionComponent<IRPRProps> = ({
  postDataHorse,
  rprHorse,
}) =>
  postDataHorse || rprHorse ? (
    <RacingPostRatingContainer>
      <div className="postdata">
        <div className="title">
          <CustomText
            inline={true}
            proximaBlack={true}
            text="Postdata"
            size="rating"
          />
        </div>
        <div className="rating">
          <CustomText
            inline={true}
            fontTertiary={true}
            bold={true}
            text={postDataHorse ? postDataHorse : '-'}
            size="rating"
          />
        </div>
      </div>
      <div className="rprating">
        <div className="rp_rating_title">
          <CustomText inline={true} text="RP Rating" size="rating" />
        </div>
        <div className="rating">
          <CustomText
            inline={true}
            fontTertiary={true}
            bold={true}
            text={rprHorse ? rprHorse : '-'}
            size="rating"
          />
        </div>
      </div>
    </RacingPostRatingContainer>
  ) : null

export default RacingPostRating
