import React, { FunctionComponent, Fragment } from 'react'
import CustomText from '@horses/CustomText/CustomText'
import Container from '@horses/Container/Container'
import { ICourseInfo } from './ICourseInfo'
import capitalize from 'lodash/capitalize'
import Head from './Table/Head'
import Body from './Table/Body'
import get from 'lodash/get'
import './CourseInfo.scss'
import ImgWithFallback from '../../../components/Image'
import cx from 'classnames'

const CourseInfo: FunctionComponent<ICourseInfo> = ({
  courseMaps,
  courseName,
  innerRef,
  records,
  realRender,
  colour,
  info,
  secondMapRemoved,
}) => {
  if (courseMaps && courseMaps.length) {
    const descriptions = courseMaps.map(courseMap =>
      get(courseMap, 'course_comment'),
    )
    const road = get(info, 'course_directions')
      ? info.course_directions.find(
          direction => direction.direction_type_code === 'R',
        )
      : null
    const rail = get(info, 'course_directions')
      ? info.course_directions.find(
          direction => direction.direction_type_code === 'T',
        )
      : null
    const courseAddress = get(info, 'course_address')
    const courseTel = get(info, 'course_tel')
    const rpAdmissionPrices = get(info, 'rp_admission_prices')
    const rpParking = get(info, 'rp_parking')
    const rpChildren = get(info, 'rp_children')
    const rpDisabled = get(info, 'rp_disabled')
    const roadDirection = get(road, 'direction')
    const railDirection = get(rail, 'direction')
    const courseStarters = get(info, 'course_starters')
    const courseStewardsSecs = get(info, 'course_stewards_secs')
    const courseScalesClerk = get(info, 'course_scales_clerk')
    return (
      <Container
        colour={colour}
        title={`${courseName} COURSE DETAILS`}
        innerRef={ref =>
          innerRef(
            ref,
            {
              courseMaps,
              info,
              innerRef,
              colour,
              courseName,
              records,
              secondMapRemoved,
            },
            CourseInfo,
          )
        }
      >
        {courseMaps.map((courseMap, index) => {
          const largeMapImagePath = get(courseMap, 'large_map_image_path')
          const mapHeight = get(courseMap, 'dimensions.height')
          const description = get(courseMap, 'course_comment')
          return realRender && largeMapImagePath ? (
            <div key={index}>
              {secondMapRemoved && index === 0 ? null : (
                <ImgWithFallback
                  className="course-info__image"
                  alt="Course Info"
                  src={`https:${courseMap.large_map_image_path}`}
                  style={{
                    height: mapHeight,
                    width: '100%',
                  }}
                />
              )}
              {descriptions[index + 1] !== description && (
                <div className="helpers--border-bottom helpers--padding-top-medium helpers--padding-bottom-medium">
                  <CustomText
                    text={capitalize(description)}
                    proximaCondMedium={true}
                    size="small"
                  />
                </div>
              )}
            </div>
          ) : secondMapRemoved && index === 0 ? null : (
            <div key={index} style={{ height: mapHeight }} />
          )
        })}

        <div className="helpers--border-bottom helpers--padding-top-medium helpers--padding-bottom-medium">
          {courseAddress && /\S/.test(courseAddress) && (
            <Fragment>
              <CustomText
                fontTertiary={true}
                uppercase={true}
                text="Address:"
                inline={true}
                size="small"
                bold={true}
              />
              <CustomText
                text={courseAddress}
                proximaCondMedium={true}
                inline={true}
                size="small"
              />
            </Fragment>
          )}

          {courseTel && /\S/.test(courseTel) && (
            <Fragment>
              <CustomText
                text="Going & Doubtful Weather:"
                fontTertiary={true}
                inline={true}
                size="small"
                bold={true}
              />
              <CustomText
                text={courseTel}
                proximaCondMedium={true}
                inline={true}
                size="small"
              />
            </Fragment>
          )}
          {rpAdmissionPrices && /\S/.test(rpAdmissionPrices) && (
            <Fragment>
              <CustomText
                fontTertiary={true}
                text="Admission:"
                inline={true}
                size="small"
                bold={true}
              />
              <CustomText
                text={rpAdmissionPrices + ' '}
                proximaCondMedium={true}
                inline={true}
                size="small"
              />
            </Fragment>
          )}
          {rpParking && /\S/.test(rpParking) && (
            <Fragment>
              <CustomText
                fontTertiary={true}
                text="Parking:"
                inline={true}
                size="small"
                bold={true}
              />
              <CustomText
                text={rpParking}
                proximaCondMedium={true}
                inline={true}
                size="small"
              />
            </Fragment>
          )}
          {rpChildren && /\S/.test(rpChildren) && (
            <Fragment>
              <CustomText
                fontTertiary={true}
                text="Children:"
                inline={true}
                size="small"
                bold={true}
              />
              <CustomText
                text={rpChildren}
                proximaCondMedium={true}
                inline={true}
                size="small"
              />
            </Fragment>
          )}
          {rpDisabled && /\S/.test(rpDisabled) && (
            <Fragment>
              <CustomText
                fontTertiary={true}
                text="Disabled:"
                inline={true}
                size="small"
                bold={true}
              />
              <CustomText
                text={rpDisabled}
                proximaCondMedium={true}
                inline={true}
                size="small"
              />
            </Fragment>
          )}
        </div>

        <div className="helpers--border-bottom helpers--padding-top-medium helpers--padding-bottom-medium">
          <CustomText
            text="HOW TO GET THERE:"
            fontTertiary={true}
            inline={true}
            size="small"
            bold={true}
          />
          {roadDirection && /\S/.test(roadDirection) && (
            <Fragment>
              <CustomText
                text="Road:"
                fontTertiary={true}
                inline={true}
                size="small"
                bold={true}
              />
              <CustomText
                text={roadDirection}
                proximaCondMedium={true}
                inline={true}
                size="small"
              />
            </Fragment>
          )}

          {railDirection && /\S/.test(railDirection) && (
            <Fragment>
              <CustomText
                fontTertiary={true}
                inline={true}
                text="Rail:"
                bold={true}
                size="small"
              />
              <CustomText
                text={railDirection}
                proximaCondMedium={true}
                inline={true}
                size="small"
              />
            </Fragment>
          )}
        </div>
        {(courseStarters || courseStewardsSecs || courseScalesClerk) && (
          <div
            className={cx('helpers--padding-top-medium', {
              'helpers--padding-bottom-medium': records && !!records.length,
            })}
          >
            <CustomText
              text="OFFICIALS:"
              fontTertiary={true}
              inline={true}
              size="small"
              bold={true}
            />
            {courseStarters && (
              <Fragment>
                <CustomText
                  text="Stewards"
                  fontTertiary={true}
                  inline={true}
                  size="small"
                  bold={true}
                />
                <CustomText
                  text={courseStarters || ''}
                  proximaCondMedium={true}
                  inline={true}
                  size="small"
                />
              </Fragment>
            )}
            {courseStewardsSecs && (
              <Fragment>
                <CustomText
                  text="Stewards Secretaries:"
                  fontTertiary={true}
                  inline={true}
                  size="small"
                  bold={true}
                />
                <CustomText
                  text={courseStewardsSecs || ''}
                  proximaCondMedium={true}
                  inline={true}
                  size="small"
                  bold={true}
                />
              </Fragment>
            )}
            {courseScalesClerk && (
              <Fragment>
                <CustomText
                  text="Clerk of the Scales:"
                  fontTertiary={true}
                  inline={true}
                  size="small"
                  bold={true}
                />
                <CustomText
                  text={courseScalesClerk || ''}
                  proximaCondMedium={true}
                  inline={true}
                  size="small"
                  bold={true}
                />
              </Fragment>
            )}
          </div>
        )}
        {records && !!records.length && (
          <table className="helpers--border-top helpers--header-padding helpers--body-padding">
            <Head />
            <Body records={records} />
          </table>
        )}
      </Container>
    )
  }
  return null
}

export default CourseInfo
