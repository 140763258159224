import React from 'react'
import { observer } from 'mobx-react'
import Socket from '@classes/Socket'
import './NetworkConnection.scss'

const NetworkConnection = () => {
  const noNetworkText = 'NETWORK OUTAGE DETECTED - PRICES / RACE STATUS AND INFORMATION MAY NOT BE CURRENT.'
  return (!Socket.networkConnectionActive) ? (
    <div className="network-connection">
      {noNetworkText}
    </div>
  ) : null
}

export default observer(NetworkConnection)
