import MeetingQueue from '@classes/MeetingQueue'
import { IHorseMeeting, IHorseRace, IFavourites } from '@models/HorseMeeting'
import get from 'lodash/get'
import filter from 'lodash/filter'
import map from 'lodash/map'
import head from 'lodash/head'
import take from 'lodash/take'
import last from 'lodash/last'
import orderBy from 'lodash/orderBy'
import compact from 'lodash/compact'
import { getObjectValues, courseTypes } from '@utils/utils'
import raceComponentPriority from '@horses/Horses/raceComponentPriority'
import meetingInfoPriority from '@horses/Horses/meetingInfoPriority'
import Race from './Race'
import Priority, { IPriority } from './Priority'
import dayjs from 'dayjs'
import { IRecord } from '@components/HorseComponents/CourseInfo/ICourseInfo'
import { ITopJockey } from '@models/TopJockey'
import { ITopSpeedData } from '@components/HorseComponents/TopSpeed/ITopSpeed'
import { ITravellersCheck } from '@components/HorseComponents/TravellersCheck/ITravellersCheck'
import WeatherStore from '@stores/WeatherStore'

interface IDistance {
  [key: number]: boolean
}

interface IRecords {
  distances: IDistance
  records: IRecord[]
}

interface IInitialPriority {
  showInlineSpotlights: boolean
  verdictAtBottom: boolean
  showDiomedSpotlights?: boolean
  showSelectionBoxes?: boolean
  showSpotlights?: boolean
  showTips?: boolean
  showVerdict?: boolean
  showMostTipped: boolean
  showInlineTip: boolean
  secondCourseMap?: boolean
}

class Meeting {
  public priorityOrdering: number | null = null
  public races: Race[] | null = null
  public spotlightsRemoved: boolean = false
  public secondMapRemoved: boolean = false
  public removedRaces: Race[] = []
  private meeting: IHorseMeeting
  private raceComponentQueue: Priority
  private meetingPriorityQueue: Priority | null
  private meetingQueue: MeetingQueue
  private priority: IInitialPriority
  constructor(meeting: IHorseMeeting, meetingQueue: MeetingQueue) {
    this.meeting = meeting
    this.meetingQueue = meetingQueue

    this.priority = {
      showInlineSpotlights: meetingQueue.inlineSpotlights,
      verdictAtBottom: meetingQueue.verdictAtBottom,
      showDiomedSpotlights: meetingQueue.diomedSpotlights,
      showMostTipped: meetingQueue.showMostTipped,
      showSpotlights: meetingQueue.showSpotlights,
      showInlineTip: meetingQueue.inlineTip,
      secondCourseMap: meetingQueue.secondCourseMap,
    }

    this.raceComponentQueue = new Priority({
      priorityList: raceComponentPriority({ ...this.priority }) as IPriority[],
      reverse: true,
    })
    
    this.meetingPriorityQueue = new Priority({
      priorityList: meetingInfoPriority(),
      reverse: true,
    })
    this.setRaces(meeting)
  }

  public getCourseName = (): string | null => {
    return get(this.meeting, 'course_style_name', null)
  }

  public getDiffusionCourseName = (): string | null => {
    return get(this.meeting, 'diffusion_course_name', null)
  }

  public getRaces = (): Race[] | null => {
    return get(this, 'races', null)
  }

  public getFavourites = (): IFavourites | null => {
    return get(this.meeting, 'favourites', null)
  }

  public getTenYear = () => {
    return get(this.meeting, 'ten_year_trends', null)
  }

  public getTopDraw = () => {
    return get(this.meeting, 'top_draw', null)
  }

  public getTopSpeed = (): ITopSpeedData[] | null => {
    const topSpeedData = compact<ITopSpeedData>(
      get(this.meeting, 'top_speed', null),
    )

    return topSpeedData as ITopSpeedData[]
  }

  public getCourseType = () => {
    return get(this.meeting, 'course_type_code', null)
  }

  public getTopJockeys = () => {
    const topJockeys: ITopJockey[] | null = get(
      this.meeting,
      'top_jockeys',
      null,
    )
    if (topJockeys) {
      return take(topJockeys, 15)
    }
    return null
  }

  public getTopTrainers = () => {
    const topTrainers = get(this.meeting, 'top_trainers', null)
    if (topTrainers) {
      const ordered = orderBy(topTrainers, trainer => trainer.wins, ['desc'])
      return take(ordered, 15)
    }
    return null
  }

  public getPreWeatherDesc = () => {
    return get(this.meeting, 'pre_weather_desc', null)
  }

  public getLiveWeather = () => {
    return this.meeting.id && WeatherStore.getWeatherForMeeting(this.meeting.id)
  }

  public getGoingDesc = () => {
    return get(this.meeting, 'going_desc', null)
  }

  public getPreGoingDesc = () => {
    return get(this.meeting, 'pre_going_desc', null)
  }

  public getCourseMap = () => {
    return get(this.meeting, 'course_map', null)
  }

  public getMeetingInfo = () => {
    return get(this.meeting, 'meeting_info', null)
  }

  public getFullCourseType = () => {
    return get(courseTypes, `${this.getCourseType()}`, null)
  }

  public getMeetingType = () => {
    return get(this.meeting, 'meeting_type', null)
  }

  public getDataForYearsSince = () => {
    return get(this.meeting, 'data_for_years_since', null)
  }

  public getRaceDistanceRecords = () => {
    const meetingType = get(this.meeting, 'meeting_type')
    const races = this.getRaces()
    const allMeetingRecords: IRecord[] = getObjectValues(
      this.meeting,
      `standard_times[${meetingType}]`,
      [],
    )

    if (races) {
      const { records } = races.reduce(
        (recordsAcc: IRecords, race) => {
          const raceYardsDistance = race.getRaceDistanceYards()
          const recordIndex = allMeetingRecords.findIndex(
            r => r.distance_yards === raceYardsDistance,
          )
          const record =
            recordIndex > -1 ? allMeetingRecords[recordIndex] : null
          if (record && !recordsAcc.distances[raceYardsDistance]) {
            recordsAcc.distances[raceYardsDistance] = true
            recordsAcc.records.push(record)
          }
          return recordsAcc
        },
        { distances: {}, records: [] },
      )
      return records
    }
    return []
  }

  public getFirstRaceTimeHour = () => {
    const races = this.getRaces()
    const firstRace = head(races)
    let dateTime = dayjs()
    if (firstRace) {
      const raceTime = firstRace.getRaceTime()
      if (raceTime) {
        dateTime = dayjs(raceTime)
      }
    }
    return Number(dateTime.format('HH'))
  }

  public getFirstRaceTime = () => {
    const races = this.getRaces()
    const firstRace = head(races)
    return firstRace ? firstRace.getRaceTime() : null
  }

  public getLastRaceTime = () => {
    const races = this.getRaces()
    const lastRace = last(races)
    return lastRace ? lastRace.getRaceTime() : null
  }

  public getTravellersCheck = (): ITravellersCheck[] => {
    const travellersCheck = getObjectValues(this.meeting, 'travellers_check')
    const compactNullVals = compact<ITravellersCheck>(travellersCheck)
    return compactNullVals || null
  }

  public isAbandoned = () => {
    return get(this.meeting, 'abandoned', 0) === 1
  }

  public getMeetingColour = (): string => {
    return get(this.meeting, 'colour') || 'green'
  }

  public getRaceTitle = () => {
    const firstRaceTime = this.getFirstRaceTime() || ''
    const lastRaceTime = this.getLastRaceTime() || ''

    const firstRaceDateTime = dayjs(firstRaceTime).format('h.mm')
    const lastRaceDateTime = dayjs(lastRaceTime).format('h.mm')

    return `${firstRaceDateTime} - ${lastRaceDateTime}`
  }

  public getITVRaceCount = () => {
    const races = this.getRaces()
    if (races && races.length) {
      const allITVRaces = filter(races, race => race.isOnITV())
      return allITVRaces.length
    }
    return 0
  }

  public hasStatsRemoved = () => this.meetingPriorityQueue === null

  public hasRaces = () => {
    const races = this.getRaces()
    return races && races.length
  }

  public removeLastRace = () => {
    const races = this.getRaces()
    if (races && races.length) {
      const lastRaceIndex = races.length - 1
      const removedRace = races.splice(lastRaceIndex, 1)[0]
      this.removedRaces.push(removedRace)
      this.races = races
    }
  }

  public setDiomedSpotlights = () => {
    this.priority.showDiomedSpotlights = true
    const raceComponents = raceComponentPriority({
      ...this.priority,
    }) as IPriority[]
    this.raceComponentQueue.setPriorityList(raceComponents)
  }

  public removeSpotlight = () => {
    this.spotlightsRemoved = true
    this.priority.showSpotlights = false
    const raceComponents = raceComponentPriority({
      ...this.priority,
    }) as IPriority[]
    this.raceComponentQueue.setPriorityList(raceComponents)
  }

  public removeSecondCourseMap = () => {
    this.secondMapRemoved = true
    this.priority.secondCourseMap = false
    const raceComponents = raceComponentPriority({
      ...this.priority,
    }) as IPriority[]
    this.raceComponentQueue.setPriorityList(raceComponents)
  }

  public removeSelectionBoxes = () => {
    this.priority.showSelectionBoxes = false
    const raceComponents = raceComponentPriority({
      ...this.priority,
    }) as IPriority[]
    this.raceComponentQueue.setPriorityList(raceComponents)
  }

  public removeVerdict = () => {
    this.priority.showVerdict = false
    const raceComponents = raceComponentPriority({
      ...this.priority,
    }) as IPriority[]
    this.raceComponentQueue.setPriorityList(raceComponents)
  }

  public removeTips = () => {
    this.priority.showTips = false

    const raceComponents = raceComponentPriority({
      ...this.priority,
    }) as IPriority[]

    this.raceComponentQueue.setPriorityList(raceComponents)
  }

  public removeStats = () => {
    this.meetingPriorityQueue = null
  }

  public getRemovedRaces = () => {
    return this.removedRaces && this.removedRaces.length
      ? this.removedRaces
      : null
  }

  public getRaceComponentQueue = () => {
    return this.raceComponentQueue.getPriorityQueue(queue =>
      orderBy(queue, ['position'], ['asc']),
    )
  }

  public getMeetingComponentQueue = () => {
    if (this.meetingPriorityQueue) {
      return this.meetingPriorityQueue.getPriorityQueue(queue =>
        orderBy(queue, ['position'], ['asc']),
      )
    }

    return null
  }

  private setRaces = (meeting: IHorseMeeting) => {
    const meetingRaces = get(meeting, 'races', [])
    this.races = map(
      meetingRaces,
      (race: IHorseRace) =>
        new Race(
          race,
          this.meetingQueue,
          meeting.course_name || meeting.course_style_name,
          meeting.isGlobal,
        ),
    )
  }
}

export default Meeting
