import React from 'react'

import './ProgressBar.scss'

const ProgressBar = () => (
  <div className="content__progress">
    <span className="content__line" />
  </div>
)

export default ProgressBar
