import React, { Fragment, FunctionComponent } from 'react'
import { IHorseInfo } from '../RaceCardInterfaces/IHorseInfo'
import LeftColumn from './LeftColumn'
import MiddleColumn from './MiddleColumn'

const HorseInfo: FunctionComponent<IHorseInfo> = ({
  middleColumn,
  leftColumn,
  nonRunner,
}) => (
  <Fragment>
    <LeftColumn nonRunner={nonRunner} {...leftColumn} />
    <MiddleColumn nonRunner={nonRunner} {...middleColumn} />
  </Fragment>
)

export default HorseInfo
